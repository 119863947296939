import React, { useEffect, useState } from 'react'
import MissCard from './missCard'
import {  Typography } from '@mui/material'
import AwardVoteCard from './AwardVoteCard';

function VoteAward({allCandidate, awardCategorie, voteawards }) {
    const [webcomedien, setwebcomedien] = useState([]);
    const [webComedientitle, setWebComedientitle] = useState({})
    const [blogvlog, setblogvlog] = useState([]);
    const [blogvlogtitle, setblogvlogtitle] = useState({});
    const [artistemusicien, setartistemusicien] = useState([]);
    const [artistemusicientitle, setartistemusicientitle] = useState({});
    const [webemission, setwebemission] = useState([]);
    const [webemissiontitle, setwebemissiontitle] = useState({});
    const [youtubeur, setYoutubeur] = useState([]);
    const [youtubeurtitle, setYoutubeurtitle] = useState({})

    useEffect(() => {
        //console.log(awardCategorie);
        setwebcomedien(allCandidate.filter(candidat => candidat.categorie == "webcomedien"));
        setWebComedientitle(awardCategorie.find(categorie => categorie.id == "webcomedien"));
        setblogvlog(allCandidate.filter(candidat => candidat.categorie == "blogvlog"));
        setblogvlogtitle(awardCategorie.find(categorie => categorie.id == "blogvlog"));
        setartistemusicien(allCandidate.filter(candidat => candidat.categorie == "artistemusicien"));
        setartistemusicientitle(awardCategorie.find(categorie => categorie.id == "artistemusicien"));
        setwebemission(allCandidate.filter(candidat => candidat.categorie == "webemission"));
        setwebemissiontitle(awardCategorie.find(categorie => categorie.id == "webemission"));
        setYoutubeur(allCandidate.filter(candidat => candidat.categorie == "youtubeur"));
        setYoutubeurtitle(awardCategorie.find(categorie => categorie.id == "youtubeur"));
        return () => {
        }
    }, [])

    return (
        <div>
            <div className=' container'>
            <div className='row'>
            <div className='col-12'>
                <Typography color='primary' variant='h4'> {artistemusicientitle?.title}</Typography> <br/>
            </div>
                {artistemusicien?.map(miss => (
                    <div className='col-md-6 col-lg-4 mb--50' key={miss.missId}>
                        <AwardVoteCard miss={miss} votes={voteawards} categorie={artistemusicientitle} />
                    </div>
                ))}
            </div>
            <div className='row'>
            <div className='col-12'>
                <Typography color='primary' variant='h4'> {youtubeurtitle?.title}</Typography> <br/>
            </div>
                {youtubeur?.map(miss => (
                    <div className='col-md-6 col-lg-4 mb--50' key={miss.missId}>
                        <AwardVoteCard miss={miss} votes={voteawards} categorie={youtubeurtitle} />
                    </div>
                ))}
            </div>
            <div className='row'>
            <div className='col-12'>
                <Typography color='primary' variant='h4'> {webemissiontitle?.title}</Typography> <br/>
            </div>
                {webemission?.map(miss => (
                    <div className='col-md-6 col-lg-4 mb--50' key={miss.missId}>
                        <AwardVoteCard miss={miss} votes={voteawards} categorie={webemissiontitle} />
                    </div>
                ))}
            </div>
            <div className='row'>
            <div className='col-12'>
                <Typography color='primary' variant='h4'> {webComedientitle?.title}</Typography> <br/>
            </div>
                {webcomedien?.map(miss => (
                    <div className='col-md-6 col-lg-4 mb--50' key={miss.missId}>
                        <AwardVoteCard miss={miss} miss={miss} votes={voteawards} categorie={webComedientitle} />
                    </div>
                ))}
            </div>
            <div className='row'>
            <div className='col-12'>
                <Typography color='primary' variant='h4'> {blogvlogtitle?.title}</Typography> <br/>
            </div>
                {blogvlog?.map(miss => (
                    <div className='col-md-6 col-lg-4 mb--50' key={miss.missId}>
                        <AwardVoteCard miss={miss} votes={voteawards} categorie={blogvlogtitle} />
                    </div>
                ))}
            </div>
            
            
        </div>
        </div>
    )
}

export default VoteAward
