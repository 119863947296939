import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux';
import { connectUser } from '../../actions/userAction';
import { compose } from 'redux';
import { firebaseConnect } from 'react-redux-firebase';
import { StyledFirebaseAuth } from 'react-firebaseui'
import { Link } from '@mui/material';
import { FiExternalLink } from 'react-icons/fi';
import { FcGoogle } from 'react-icons/fc';

import axios from "axios"



const emails = ['username@gmail.com', 'user02@gmail.com'];

const LoginPopup = (props) => {
  const [country, setCountry] = React.useState('CM')
  
  React.useEffect(() => {
    if(!!localStorage.getItem("country")) {
      setCountry(localStorage.getItem("country"))
      console.log(localStorage.getItem("country"));
    } else {
      axios.get('https://ipinfo.io/json?token=6ac716e5339265')
      .then((data) => {
        if(data?.data?.country) {
          localStorage.setItem("country", data?.data?.country);
          setCountry(data?.data?.country);
        }
      })
    }
    
  }, [])
  
  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    //
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    //signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
         provider: props.firebase.auth.PhoneAuthProvider.PROVIDER_ID,
         defaultCountry: country,
         recaptchaParameters: {
          size: 'invisible'
        },
       // whitelistedCountries: ['BE', 'BJ', 'CM', 'CA', 'TD','CN','CD', 'CG','CI', 'DK', 'DJ', 'FR', 'DE', 'GH', 'GN', 'GW', 'IT', 'KE', 'LU', 'ML', 'NG', 'SN', 'ES', 'TG','UA', 'GB', 'US', 'NL', 'DZ', ZW]
      }
    ],
  };
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  //console.log("by login", props);

  const handleListItemClick = (value) => {
    if (value) {
      //console.log("call connect");
      props.connectUser("");
    }
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Connect With MDC</DialogTitle>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={props.firebase.auth()} />
      <Typography align='center'>Secure by <Link href="https://kapygenius.com" underline='hover'> KapyGenius<FiExternalLink/> </Link> and  <FcGoogle/>oogle</Typography>
    </Dialog>
  );
}

LoginPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authReducer: state.firebaseReducer
  }
}

export default compose(
  connect(mapStateToProps, { connectUser }),
  firebaseConnect()
)(LoginPopup);