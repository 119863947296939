import {categorie, awardcandidats} from "../data/award"

export const awardReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_AWARD':
            return [...action.payload];
        default:
            return state;

    }
} 

export const categorieAwardReducer = (state = categorie, action) => {
    return state;
} 