import React, { Component, Fragment } from "react";
import BlogContent from "../../data/BlogContent";
import {gql, useQuery} from '@apollo/client';
import { Link } from "react-router-dom";

const BLOG = gql`
    query GetArticles {
        articles(sort:"published_at:desc", where: {domaine_articles: {nom: "mdc_central"}}) {
            id,
            titre,
            image {
                url
            }
        }
    }  
`

const BLogList = () => {
    const {loading, error, data} = useQuery(BLOG);
    if(loading) return "";
    if(error) return "";


    const PostList = data.articles.map(article => ({image: article.image && article.image.url,title: article.titre, category: "Diamond price", link:`/actualites/${article.id}`}));
    return (
        <Fragment>
            <div className="row">
                {PostList.map((value, i) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                        <div className="blog blog-style--1">
                            <div className="thumbnail">
                                <Link to={value.link}>
                                    <div className='img_imgcontainer img_imgw100'>
                                        <img className="w-100" src={value.image || "/assets/images/logo/250.jpeg"} alt="Blog Images" />
                                    </div>
                                </Link>
                            </div>
                            <div className="content">
                                <p className="blogtype">{value.category}</p>
                                <h4 className="title"><Link to={value.link}>{value.title}</Link></h4>
                                <div className="blog-btn">
                                    <Link className="rn-btn text-white" to={`/actualites/${value.link}`}>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    );
}
export default BLogList;