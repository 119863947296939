import React, { useCallback, useRef, useState } from 'react'
import PageHelmet from '../../components/common/Helmet';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import { useParams } from 'react-router-dom';

import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import GalleryView from '../../components/gallery/GalleryView';
import { gql, useQuery } from '@apollo/client';

const PARTICIPANTES = gql`
    query getParticipantes($year:String) {
        participantes(where:{annee:{annee:$year}}) {
            Nom,
            id,
            profil {
                url
            }
        }
    }
`

export default function Gallery() {
  const { year } = useParams();
  const { loading, error, data } = useQuery(PARTICIPANTES, {
    variables: { year: year }
  });
  
  return (
    <>
      <PageHelmet pageTitle="Gallery" />
      <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--120 bg_color--5">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30">
                    <h2 className="title mt-5">Gallery MDC {year}</h2>
                  </div>
                </div>
              </div>
              <div>
                  {data?.participantes && <GalleryView items = {data.participantes.map(part => ({id:part.id, src:part?.profil[0]?.url, thumb:part?.profil[0]?.url}))} />}
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </>
  )
}
