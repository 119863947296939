import React, { Component , Fragment, useState } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { gql, useQuery } from "@apollo/client";
import ReactMarkdown from "react-markdown";

const PALMARES = gql`
    query GetApropos {
        homepage {
            palmares,
            palnumber1,
            palnumber2,
            palnumber3,
            paldesc1,
            paldesc2,
            paldesc3,
        }
    }
`

const CounterOne = () => {

    const [didViewCountUp, setDidViewCountUp] = useState(false);
    const { loading, error, data} = useQuery(PALMARES);
    if(loading) return "";
    if(error) return "";

    const onVisibilityChange = isVisible => {
        if (isVisible) {
            setDidViewCountUp(true);
        }
    }
        let Data = [
            {
                countNum : parseInt(data.homepage?.palnumber1),
                countTitle: data.homepage?.paldesc1,
            },
            {
                countNum : parseInt(data.homepage?.palnumber2),
                countTitle: data.homepage?.paldesc2,
            },
            {
                countNum : parseInt(data.homepage?.palnumber3),
                countTitle: data.homepage?.paldesc3,
            },
        ];

        return(
            <Fragment>
                <div className="row">
                    {Data.map( (value, index) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp end={didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.countTitle}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
}
export default CounterOne;