import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { FaInstagram, FaPaypal } from 'react-icons/fa';
import { connect } from 'react-redux';
import { addAwardChoice } from '../../actions/voteAction';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import Login from '../login';
import PayChoice from './pay/PayChoice';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@mui/material';
import { GoVerified } from "react-icons/go";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AwardVoteCard = (props) => {

  const [vote, setVote] = React.useState(0);
  const [userChoice, setUserchoice] = React.useState(0);

  const [expanded, setExpanded] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { miss, votes, authReducer, categorie } = props;


  React.useEffect(() => {

    if (votes) {
        let missVote = 0;
        let chosenMiss = 0;
    switch (categorie.id) {
        case "webcomedien":
            missVote = votes.filter(vote => vote.webcomedienId === miss.missId).length;
            if (!authReducer.auth.isEmpty) {
                 chosenMiss = votes.find(vote => vote.id === authReducer.auth.uid)?.webcomedienId || 0;
                setUserchoice(chosenMiss)
              }
            break;
        case "blogvlog":
            missVote = votes.filter(vote => vote.blogvlogId === miss.missId).length;
            if (!authReducer.auth.isEmpty) {
                chosenMiss = votes.find(vote => vote.id === authReducer.auth.uid)?.blogvlogId || 0;
                setUserchoice(chosenMiss)
              }
            break;
        case "artistemusicien":
            missVote = votes.filter(vote => vote.artistemusicienId === miss.missId).length;
            if (!authReducer.auth.isEmpty) {
                chosenMiss = votes.find(vote => vote.id === authReducer.auth.uid)?.artistemusicienId || 0;
                setUserchoice(chosenMiss)
              }
            break;
        case "webemission":
            missVote = votes.filter(vote => vote.webemissionId === miss.missId).length;
            if (!authReducer.auth.isEmpty) {
                chosenMiss = votes.find(vote => vote.id === authReducer.auth.uid)?.webemissionId || 0;
                setUserchoice(chosenMiss)
              }
            break;
        case "youtubeur":
            missVote = votes.filter(vote => vote.youtubeurId === miss.missId).length;
            if (!authReducer.auth.isEmpty) {
                chosenMiss = votes.find(vote => vote.id === authReducer.auth.uid)?.youtubeurId || 0;
                setUserchoice(chosenMiss)
            }
            break;
        default:
            break;
    }
      

      //let missVote = props.voteReducer.find(vote => vote.missId == miss.missId).vote;
      setVote(missVote);
      ////console.log(props?.firebase);
    }
    return () => { }
  })

  return (
    <Card sx={{ maxWidth: 400 }}>
      <CardHeader
        avatar={
          <Avatar src={miss.img.url} sx={{ bgcolor: red[500] }} aria-label="recipe">
            {miss.name.slice(0, 2).toUpperCase()}
          </Avatar>
        }
        action={
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
          <MenuItem onClick={handleClose}>
             <Link target="_blank" href={miss.instagramname}><FaInstagram/> Instagram Post</Link> 
          </MenuItem>
          <MenuItem onClick={handleClose}>
             <Link target="_blank" href={miss.facebooklink}><FaInstagram/> Facebook Post</Link>
          </MenuItem>
      </Menu>
    </div>
        }
        title={
          <span>
            {miss.name} <GoVerified color='#0095f6' />
          </span>
        }
        subheader={categorie?.title}
      />
      <CardMedia
        component="img"
        height="350"
        image={miss.img.url}
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="body1" color="text.secondary">
          {vote} Votes
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {
          authReducer.auth.isEmpty ?
            <React.Fragment><Typography variant="subtitle1" color="secondary" onClick={()=>{window.scrollTo(0,0)}}><Login /> To Vote </Typography></React.Fragment> :
            <IconButton color='secondary' onClick={() => props.addAwardChoice({ missId: miss.missId, categorie: categorie?.id })}>
              vote {userChoice == miss.missId ? <AiFillHeart /> : <AiOutlineHeart />}
            </IconButton>
        }


        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{miss.name}:</Typography>
          <Typography paragraph>
            {miss.description}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

const mapStateToProps = (state) => {
  ////console.log(state);
  return {
    authReducer: state.firebaseReducer
  }
}

export default compose(
  connect(mapStateToProps, { addAwardChoice })
)(AwardVoteCard)
