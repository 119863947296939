import React from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../../components/common/Helmet";
import Breadcrumb from "../../components/common/Breadcrumb";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import BLogList from "../../components/blog/BlogList";
import { FaHome, FaPaypal } from "react-icons/fa";
import InfoDonate from "../../components/about/InfoDonate";
import LottieAnimation from "../../components/common/Lottie";
import LottieThanks from "../../components/common/lottieJson/LottieThanks.json";
import { Link } from "react-router-dom";

const Privacy = () => {
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Thank you' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* Start Blog Area */}
            <div className="rn-blog-area pt--50 bg_color--1">


            </div>
            {/* End Blog Area */}

            
            {/* Start Blog Area */}
            <div className="rn-blog-area ptb--120 bg_color--1">
                <div className="container">

                <h1>Politique de confidentialit&eacute;</h1>
<p>Derni&egrave;re mise &agrave; jour : 2021</p>
<p><br/></p>
<p>Cette politique de confidentialit&eacute; d&eacute;crit nos politiques et proc&eacute;dures sur la collecte, l&apos;utilisation et la divulgation de vos informations lorsque vous utilisez le service et vous informe de vos droits &agrave; la confidentialit&eacute; et de la mani&egrave;re dont la loi vous prot&egrave;ge.</p>
<p><br/></p>
<p>Nous utilisons vos donn&eacute;es personnelles pour fournir et am&eacute;liorer le service. En utilisant le Service, vous acceptez la collecte et l&apos;utilisation des informations conform&eacute;ment &agrave; la pr&eacute;sente politique de confidentialit&eacute;. Cette politique de confidentialit&eacute; a &eacute;t&eacute; cr&eacute;&eacute;e &agrave; l&apos;aide du g&eacute;n&eacute;rateur de politique de confidentialit&eacute;.</p>
<p><br/></p>
<p>Interpr&eacute;tation et d&eacute;finitions</p>
<p>Interpr&eacute;tation</p>
<p>Les mots dont la lettre initiale est en majuscule ont des significations d&eacute;finies dans les conditions suivantes. Les d&eacute;finitions suivantes ont la m&ecirc;me signification, qu&apos;elles apparaissent au singulier ou au pluriel.</p>
<p><br/></p>
<p>D&eacute;finitions</p>
<p>Aux fins de la pr&eacute;sente politique de confidentialit&eacute; :</p>
<p><br/></p>
<p>Compte d&eacute;signe un compte unique cr&eacute;&eacute; pour vous permettre d&apos;acc&eacute;der &agrave; notre service ou &agrave; des parties de notre service.</p>
<p><br/></p>
<p>La Soci&eacute;t&eacute; (appel&eacute;e soit &laquo; la Soci&eacute;t&eacute; &raquo;, &laquo; Nous &raquo;, &laquo; Notre &raquo; ou &laquo; Notre &raquo; dans le pr&eacute;sent Contrat) fait r&eacute;f&eacute;rence &agrave; Miss Diamond Cameroun, Allemagne.</p>
<p><br/></p>
<p>Les cookies sont de petits fichiers qui sont plac&eacute;s sur votre ordinateur, appareil mobile ou tout autre appareil par un site Web, contenant les d&eacute;tails de votre historique de navigation sur ce site Web parmi ses nombreuses utilisations.</p>
<p><br/></p>
<p>Le pays fait r&eacute;f&eacute;rence &agrave; : Nordrhein-Westfalen, Allemagne</p>
<p><br/></p>
<p>Appareil d&eacute;signe tout appareil pouvant acc&eacute;der au Service tel qu&apos;un ordinateur, un t&eacute;l&eacute;phone portable ou une tablette num&eacute;rique.</p>
<p><br/></p>
<p>Les donn&eacute;es personnelles sont toute information qui se rapporte &agrave; une personne identifi&eacute;e ou identifiable.</p>
<p><br/></p>
<p>Le service fait r&eacute;f&eacute;rence au site Web.</p>
<p><br/></p>
<p>Prestataire de services d&eacute;signe toute personne physique ou morale qui traite les donn&eacute;es pour le compte de la Soci&eacute;t&eacute;. Il fait r&eacute;f&eacute;rence &agrave; des soci&eacute;t&eacute;s tierces ou &agrave; des personnes employ&eacute;es par la Soci&eacute;t&eacute; pour faciliter le Service, fournir le Service au nom de la Soci&eacute;t&eacute;, ex&eacute;cuter des services li&eacute;s au Service ou aider la Soci&eacute;t&eacute; &agrave; analyser la mani&egrave;re dont le Service est utilis&eacute;.</p>
<p><br/></p>
<p>Les donn&eacute;es d&apos;utilisation font r&eacute;f&eacute;rence aux donn&eacute;es collect&eacute;es automatiquement, soit g&eacute;n&eacute;r&eacute;es par l&apos;utilisation du service, soit &agrave; partir de l&apos;infrastructure du service elle-m&ecirc;me (par exemple, la dur&eacute;e d&apos;une visite de page).</p>
<p><br/></p>
<p>Le site Web fait r&eacute;f&eacute;rence &agrave; Miss Diamond Cameroun, accessible depuis https://missdiamondcameroon.com/</p>
<p><br/></p>
<p>Vous d&eacute;signe la personne qui acc&egrave;de ou utilise le Service, ou la soci&eacute;t&eacute; ou toute autre entit&eacute; juridique au nom de laquelle cette personne acc&egrave;de ou utilise le Service, selon le cas.</p>
<p><br/></p>
<p>Collecte et utilisation de vos donn&eacute;es personnelles</p>
<p>Types de donn&eacute;es collect&eacute;es</p>
<p>Donn&eacute;es personnelles</p>
<p>Lors de l&apos;utilisation de notre service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent &ecirc;tre utilis&eacute;es pour vous contacter ou vous identifier. Les informations personnellement identifiables peuvent inclure, sans s&apos;y limiter :</p>
<p><br/></p>
<p>Adresse e-mail</p>
<p><br/></p>
<p>Pr&eacute;nom et nom</p>
<p><br/></p>
<p>Num&eacute;ro de t&eacute;l&eacute;phone</p>
<p><br/></p>
<p>Des donn&eacute;es d&apos;utilisation</p>
<p><br/></p>
<p>Des donn&eacute;es d&apos;utilisation</p>
<p>Les Donn&eacute;es d&apos;utilisation sont collect&eacute;es automatiquement lors de l&apos;utilisation du Service.</p>
<p><br/></p>
<p>Les donn&eacute;es d&apos;utilisation peuvent inclure des informations telles que l&apos;adresse de protocole Internet de votre appareil (par exemple, l&apos;adresse IP), le type de navigateur, la version du navigateur, les pages de notre service que vous visitez, l&apos;heure et la date de votre visite, le temps pass&eacute; sur ces pages, l&apos;appareil unique identifiants et autres donn&eacute;es de diagnostic.</p>
<p><br/></p>
<p>Lorsque vous acc&eacute;dez au service par ou via un appareil mobile, nous pouvons collecter automatiquement certaines informations, y compris, mais sans s&apos;y limiter, le type d&apos;appareil mobile que vous utilisez, l&apos;identifiant unique de votre appareil mobile, l&apos;adresse IP de votre appareil mobile, votre syst&egrave;me d&apos;exploitation, le type de navigateur Internet mobile que vous utilisez, les identifiants uniques de l&apos;appareil et d&apos;autres donn&eacute;es de diagnostic.</p>
<p><br/></p>
<p>Nous pouvons &eacute;galement collecter des informations que votre navigateur envoie chaque fois que vous visitez notre service ou lorsque vous acc&eacute;dez au service par ou via un appareil mobile.</p>
<p><br/></p>
<p>Technologies de suivi et cookies</p>
<p>Nous utilisons des cookies et des technologies de suivi similaires pour suivre l&apos;activit&eacute; sur notre service et stocker certaines informations. Les technologies de suivi utilis&eacute;es sont des balises, des balises et des scripts pour collecter et suivre les informations et pour am&eacute;liorer et analyser Notre Service. Les technologies que nous utilisons peuvent inclure :</p>
<p><br/></p>
<p>Cookies ou cookies de navigateur. Un cookie est un petit fichier plac&eacute; sur votre appareil. Vous pouvez demander &agrave; Votre navigateur de refuser tous les Cookies ou d&apos;indiquer lorsqu&apos;un Cookie est envoy&eacute;. Cependant, si vous n&apos;acceptez pas les cookies, vous ne pourrez peut-&ecirc;tre pas utiliser certaines parties de notre service. &Agrave; moins que vous n&apos;ayez ajust&eacute; les param&egrave;tres de votre navigateur pour qu&apos;il refuse les cookies, notre service peut utiliser des cookies.</p>
<p>Cookies Flash. Certaines fonctionnalit&eacute;s de notre Service peuvent utiliser des objets stock&eacute;s localement (ou Flash Cookies) pour collecter et stocker des informations sur Vos pr&eacute;f&eacute;rences ou Votre activit&eacute; sur notre Service. Les cookies Flash ne sont pas g&eacute;r&eacute;s par les m&ecirc;mes param&egrave;tres de navigateur que ceux utilis&eacute;s pour les cookies de navigateur. Pour plus d&apos;informations sur la fa&ccedil;on dont vous pouvez supprimer les cookies Flash, veuillez lire &laquo; O&ugrave; puis-je modifier les param&egrave;tres de d&eacute;sactivation ou de suppression des objets partag&eacute;s locaux ? &raquo; disponible sur https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</p>
<p>Balises Web. Certaines sections de notre Service et de nos e-mails peuvent contenir de petits fichiers &eacute;lectroniques appel&eacute;s balises Web (&eacute;galement appel&eacute;s gifs clairs, pixels invisibles et gifs &agrave; un seul pixel) qui permettent &agrave; la Soci&eacute;t&eacute;, par exemple, de compter les utilisateurs qui ont visit&eacute; ces pages. ou ouvert un e-mail et pour d&apos;autres statistiques de site Web connexes (par exemple, enregistrer la popularit&eacute; d&apos;une certaine section et v&eacute;rifier l&apos;int&eacute;grit&eacute; du syst&egrave;me et du serveur).</p>
<p>Les cookies peuvent &ecirc;tre des cookies &laquo; persistants &raquo; ou &laquo; de session &raquo;. Les cookies persistants restent sur votre ordinateur personnel ou appareil mobile lorsque vous vous d&eacute;connectez, tandis que les cookies de session sont supprim&eacute;s d&egrave;s que vous fermez votre navigateur Web. En savoir plus sur les cookies : Cookies by PrivacyPolicies Generator.</p>
<p><br/></p>
<p>Nous utilisons &agrave; la fois des cookies de session et des cookies persistants aux fins d&eacute;crites ci-dessous :</p>
<p><br/></p>
<p>Cookies n&eacute;cessaires / essentiels</p>
<p><br/></p>
<p>Type : Cookies de session</p>
<p><br/></p>
<p>Administr&eacute; par : Nous</p>
<p><br/></p>
<p>Finalit&eacute; : Ces Cookies sont essentiels pour Vous fournir les services disponibles sur le Site Internet et pour Vous permettre d&apos;utiliser certaines de ses fonctionnalit&eacute;s. Ils aident &agrave; authentifier les utilisateurs et &agrave; emp&ecirc;cher l&apos;utilisation frauduleuse des comptes d&apos;utilisateurs. Sans ces cookies, les services que vous avez demand&eacute;s ne peuvent pas &ecirc;tre fournis, et nous n&apos;utilisons ces cookies que pour vous fournir ces services.</p>
<p><br/></p>
<p>Cookies Politique / Avis Acceptation Cookies</p>
<p><br/></p>
<p>Type : Cookies persistants</p>
<p><br/></p>
<p>Administr&eacute; par : Nous</p>
<p><br/></p>
<p>Finalit&eacute; : Ces cookies identifient si les utilisateurs ont accept&eacute; l&apos;utilisation de cookies sur le site Web.</p>
<p><br/></p>
<p>Cookies de fonctionnalit&eacute;</p>
<p><br/></p>
<p>Type : Cookies persistants</p>
<p><br/></p>
<p>Administr&eacute; par : Nous</p>
<p><br/></p>
<p>Objectif : ces cookies nous permettent de m&eacute;moriser les choix que vous faites lorsque vous utilisez le site Web, tels que la m&eacute;morisation de vos informations de connexion ou de votre pr&eacute;f&eacute;rence linguistique. Le but de ces Cookies est de Vous offrir une exp&eacute;rience plus personnelle et de Vous &eacute;viter d&apos;avoir &agrave; ressaisir vos pr&eacute;f&eacute;rences &agrave; chaque fois que Vous utilisez le Site.</p>
<p><br/></p>
<p>Pour plus d&apos;informations sur les cookies que nous utilisons et vos choix concernant les cookies, veuillez visiter notre Politique de cookies ou la section Cookies de notre Politique de confidentialit&eacute;.</p>
<p>Utilisation de vos donn&eacute;es personnelles</p>
<p>La Soci&eacute;t&eacute; peut utiliser les Donn&eacute;es Personnelles aux fins suivantes :</p>
<p><br/></p>
<p>Pour fournir et maintenir notre Service, y compris pour surveiller l&apos;utilisation de notre Service.</p>
<p><br/></p>
<p>Pour g&eacute;rer Votre Compte : pour g&eacute;rer Votre inscription en tant qu&apos;utilisateur du Service. Les Donn&eacute;es Personnelles que Vous fournissez peuvent Vous donner acc&egrave;s &agrave; diff&eacute;rentes fonctionnalit&eacute;s du Service qui Vous sont accessibles en tant qu&apos;utilisateur enregistr&eacute;.</p>
<p><br/></p>
<p>Pour l&apos;ex&eacute;cution d&apos;un contrat : l&apos;&eacute;laboration, la conformit&eacute; et l&apos;ex&eacute;cution du contrat d&apos;achat des produits, articles ou services que Vous avez achet&eacute;s ou de tout autre contrat avec Nous via le Service.</p>
<p><br/></p>
<p>Pour Vous contacter : Pour Vous contacter par e-mail, appels t&eacute;l&eacute;phoniques, SMS ou autres formes &eacute;quivalentes de communication &eacute;lectronique, telles que les notifications push d&apos;une application mobile concernant les mises &agrave; jour ou les communications informatives relatives aux fonctionnalit&eacute;s, produits ou services contractuels, y compris les mises &agrave; jour de s&eacute;curit&eacute;, lorsque cela est n&eacute;cessaire ou raisonnable pour leur mise en &oelig;uvre.</p>
<p><br/></p>
<p>Pour vous fournir des nouvelles, des offres sp&eacute;ciales et des informations g&eacute;n&eacute;rales sur d&apos;autres biens, services et &eacute;v&eacute;nements que nous proposons et similaires &agrave; ceux que vous avez d&eacute;j&agrave; achet&eacute;s ou demand&eacute;s, &agrave; moins que vous n&apos;ayez choisi de ne pas recevoir ces informations.</p>
<p><br/></p>
<p>Pour g&eacute;rer Vos demandes : Pour assister et g&eacute;rer Vos demandes &agrave; Nous.</p>
<p><br/></p>
<p>Pour les transferts d&apos;entreprise : nous pouvons utiliser vos informations pour &eacute;valuer ou mener une fusion, une cession, une restructuration, une r&eacute;organisation, une dissolution ou toute autre vente ou transfert de tout ou partie de nos actifs, que ce soit dans le cadre d&apos;une entreprise en activit&eacute; ou dans le cadre d&apos;une faillite, liquidation, ou une proc&eacute;dure similaire, dans laquelle les donn&eacute;es personnelles que nous d&eacute;tenons sur les utilisateurs de nos services font partie des actifs transf&eacute;r&eacute;s.</p>
<p><br/></p>
<p>&Agrave; d&apos;autres fins : nous pouvons utiliser vos informations &agrave; d&apos;autres fins, telles que l&apos;analyse de donn&eacute;es, l&apos;identification des tendances d&apos;utilisation, la d&eacute;termination de l&apos;efficacit&eacute; de nos campagnes promotionnelles et pour &eacute;valuer et am&eacute;liorer notre service, nos produits, nos services, notre marketing et votre exp&eacute;rience.</p>
<p>Nous pouvons partager vos informations personnelles dans les situations suivantes :</p>
<p><br/></p>
<p>Avec les fournisseurs de services : nous pouvons partager vos informations personnelles avec des fournisseurs de services pour surveiller et analyser l&apos;utilisation de notre service, pour vous contacter.</p>
<p>Pour les transferts d&apos;entreprise : nous pouvons partager ou transf&eacute;rer vos informations personnelles dans le cadre de, ou pendant les n&eacute;gociations de, toute fusion, vente d&apos;actifs de la soci&eacute;t&eacute;, financement ou acquisition de tout ou partie de notre entreprise &agrave; une autre soci&eacute;t&eacute;.</p>
<p>Avec les affili&eacute;s : Nous pouvons partager vos informations avec nos affili&eacute;s, auquel cas nous exigerons de ces affili&eacute;s qu&apos;ils respectent cette politique de confidentialit&eacute;. Les soci&eacute;t&eacute;s affili&eacute;es comprennent Notre soci&eacute;t&eacute; m&egrave;re et toute autre filiale, coentreprise ou autre soci&eacute;t&eacute; que Nous contr&ocirc;lons ou qui est sous contr&ocirc;le commun avec Nous.</p>
<p>Avec des partenaires commerciaux : nous pouvons partager vos informations avec nos partenaires commerciaux pour vous offrir certains produits, services ou promotions.</p>
<p>Avec d&apos;autres utilisateurs : lorsque vous partagez des informations personnelles ou interagissez d&apos;une autre mani&egrave;re dans les espaces publics avec d&apos;autres utilisateurs, ces informations peuvent &ecirc;tre consult&eacute;es par tous les utilisateurs et peuvent &ecirc;tre diffus&eacute;es publiquement &agrave; l&apos;ext&eacute;rieur.</p>
<p>Avec Votre consentement : Nous pouvons divulguer Vos informations personnelles &agrave; toute autre fin avec Votre consentement.</p>
<p>Conservation de vos donn&eacute;es personnelles</p>
<p>La Soci&eacute;t&eacute; ne conservera vos donn&eacute;es personnelles que le temps n&eacute;cessaire aux fins &eacute;nonc&eacute;es dans la pr&eacute;sente politique de confidentialit&eacute;. Nous conserverons et utiliserons vos donn&eacute;es personnelles dans la mesure n&eacute;cessaire pour nous conformer &agrave; nos obligations l&eacute;gales (par exemple, si nous sommes tenus de conserver vos donn&eacute;es pour nous conformer aux lois applicables), r&eacute;soudre les litiges et appliquer nos accords et politiques juridiques.</p>
<p><br/></p>
<p>La Soci&eacute;t&eacute; conservera &eacute;galement les Donn&eacute;es d&apos;utilisation &agrave; des fins d&apos;analyse interne. Les donn&eacute;es d&apos;utilisation sont g&eacute;n&eacute;ralement conserv&eacute;es pendant une p&eacute;riode plus courte, sauf lorsque ces donn&eacute;es sont utilis&eacute;es pour renforcer la s&eacute;curit&eacute; ou pour am&eacute;liorer la fonctionnalit&eacute; de notre service, ou lorsque nous sommes l&eacute;galement tenus de conserver ces donn&eacute;es pendant des p&eacute;riodes plus longues.</p>
<p><br/></p>
<p>Transfert de vos donn&eacute;es personnelles</p>
<p>Vos informations, y compris les donn&eacute;es personnelles, sont trait&eacute;es dans les bureaux d&apos;exploitation de la soci&eacute;t&eacute; et dans tout autre endroit o&ugrave; se trouvent les parties impliqu&eacute;es dans le traitement. Cela signifie que ces informations peuvent &ecirc;tre transf&eacute;r&eacute;es et conserv&eacute;es sur des ordinateurs situ&eacute;s en dehors de Votre &eacute;tat, province, pays ou autre juridiction gouvernementale o&ugrave; les lois sur la protection des donn&eacute;es peuvent diff&eacute;rer de celles de Votre juridiction.</p>
<p><br/></p>
<p>Votre consentement &agrave; cette politique de confidentialit&eacute; suivi de votre soumission de ces informations repr&eacute;sente votre accord &agrave; ce transfert.</p>
<p><br/></p>
<p>La Soci&eacute;t&eacute; prendra toutes les mesures raisonnablement n&eacute;cessaires pour s&apos;assurer que vos donn&eacute;es sont trait&eacute;es en toute s&eacute;curit&eacute; et conform&eacute;ment &agrave; la pr&eacute;sente politique de confidentialit&eacute; et aucun transfert de vos donn&eacute;es personnelles n&apos;aura lieu vers une organisation ou un pays &agrave; moins que des contr&ocirc;les ad&eacute;quats soient en place, y compris la s&eacute;curit&eacute; de Vos donn&eacute;es et autres informations personnelles.</p>
<p>Divulgation de vos donn&eacute;es personnelles</p>
<p>Op&eacute;rations commerciales</p>
<p>Si la Soci&eacute;t&eacute; est impliqu&eacute;e dans une fusion, une acquisition ou une vente d&apos;actifs, Vos Donn&eacute;es Personnelles peuvent &ecirc;tre transf&eacute;r&eacute;es. Nous vous informerons avant que vos donn&eacute;es personnelles ne soient transf&eacute;r&eacute;es et soient soumises &agrave; une politique de confidentialit&eacute; diff&eacute;rente.</p>
<p><br/></p>
<p>Forces de l&apos;ordre</p>
<p>Dans certaines circonstances, la Soci&eacute;t&eacute; peut &ecirc;tre tenue de divulguer vos donn&eacute;es personnelles si la loi l&apos;exige ou en r&eacute;ponse &agrave; des demandes valables des autorit&eacute;s publiques (par exemple, un tribunal ou un organisme gouvernemental).</p>
<p><br/></p>
<p>Autres exigences l&eacute;gales</p>
<p>La Soci&eacute;t&eacute; peut divulguer vos donn&eacute;es personnelles en croyant de bonne foi qu&apos;une telle action est n&eacute;cessaire pour :</p>
<p><br/></p>
<p>Se conformer &agrave; une obligation l&eacute;gale</p>
<p>Prot&eacute;ger et d&eacute;fendre les droits ou la propri&eacute;t&eacute; de la Soci&eacute;t&eacute;</p>
<p>Pr&eacute;venir ou enqu&ecirc;ter sur d&apos;&eacute;ventuels actes r&eacute;pr&eacute;hensibles en rapport avec le Service</p>
<p>Prot&eacute;ger la s&eacute;curit&eacute; personnelle des Utilisateurs du Service ou du public</p>
<p>Prot&eacute;gez-vous contre la responsabilit&eacute; l&eacute;gale</p>
<p>S&eacute;curit&eacute; de vos donn&eacute;es personnelles</p>
<p>La s&eacute;curit&eacute; de vos donn&eacute;es personnelles est importante pour nous, mais rappelez-vous qu&apos;aucune m&eacute;thode de transmission sur Internet ou m&eacute;thode de stockage &eacute;lectronique n&apos;est s&eacute;curis&eacute;e &agrave; 100 %. Bien que nous nous efforcions d&apos;utiliser des moyens commercialement acceptables pour prot&eacute;ger vos donn&eacute;es personnelles, nous ne pouvons garantir leur s&eacute;curit&eacute; absolue.</p>
<p><br/></p>
<p>Informations d&eacute;taill&eacute;es sur le traitement de vos donn&eacute;es personnelles</p>
<p>Les fournisseurs de services que nous utilisons peuvent avoir acc&egrave;s &agrave; vos donn&eacute;es personnelles. Ces fournisseurs tiers collectent, stockent, utilisent, traitent et transf&egrave;rent des informations sur Votre activit&eacute; sur Notre Service conform&eacute;ment &agrave; leurs Politiques de confidentialit&eacute;.</p>
<p><br/></p>
<p>Utilisation, performances et divers</p>
<p>Nous pouvons utiliser des fournisseurs de services tiers pour fournir une meilleure am&eacute;lioration de notre service.</p>
<p><br/></p>
<p>Google Adresses</p>
<p><br/></p>
<p>Google Places est un service qui renvoie des informations sur les lieux &agrave; l&apos;aide de requ&ecirc;tes HTTP. Il est exploit&eacute; par Google</p>
<p><br/></p>
<p>Le service Google Places peut collecter des informations sur vous et sur votre appareil &agrave; des fins de s&eacute;curit&eacute;.</p>
<p><br/></p>
<p>Les informations recueillies par Google Places sont conserv&eacute;es conform&eacute;ment &agrave; la politique de confidentialit&eacute; de Google : https://www.google.com/intl/en/policies/privacy/</p>
<p><br/></p>
<p>Condition de Participation :&nbsp;</p>
<p>La candidate en s&rsquo;inscrivant en ligne accepte toutes les conditions d&rsquo;utilisations et ne peut quitter l&rsquo;aventure qu&rsquo;en cas de maladie, accident, harc&egrave;lement sexuel de la part du public ou d&rsquo;un membre du comit&eacute; ou si elle est &eacute;limin&eacute;e</p>
<p>La candidate autorise les organisateurs du concours Miss Diamond Africa &nbsp;&agrave; r&eacute;aliser,</p>
<p>exploiter, diffuser et reproduire tout support m&eacute;diatique me repr&eacute;sentant</p>
<p>(photographies, films, affiches etc&hellip;.) &agrave; des fins promotionnelles ou m&eacute;diatiques en</p>
<p>relation avec le concours Miss Diamond Africa personnel .</p>
<p><br/></p>
<p><br/></p>
<p>Confidentialit&eacute; des enfants</p>
<p>Notre service ne s&apos;adresse &agrave; aucune personne de moins de 13 ans. Nous ne collectons pas sciemment d&apos;informations personnellement identifiables de toute personne de moins de 13 ans. Si vous &ecirc;tes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des donn&eacute;es personnelles, veuillez Nous contacter. Si nous apprenons que nous avons collect&eacute; des donn&eacute;es personnelles de toute personne de moins de 13 ans sans v&eacute;rification du consentement parental, nous prenons des mesures pour supprimer ces informations de nos serveurs.</p>
<p><br/></p>
<p>Si Nous devons nous fonder sur le consentement comme base l&eacute;gale pour le traitement de Vos informations et que Votre pays requiert le consentement d&apos;un parent, Nous pouvons exiger le consentement de Votre parent avant de collecter et d&apos;utiliser ces informations.</p>
<p><br/></p>
<p>Liens vers d&apos;autres sites Web</p>
<p>Notre service peut contenir des liens vers d&apos;autres sites Web qui ne sont pas exploit&eacute;s par nous. Si vous cliquez sur un lien tiers, vous serez dirig&eacute; vers le site de ce tiers. Nous vous conseillons fortement de consulter la politique de confidentialit&eacute; de chaque site que vous visitez.</p>
<p><br/></p>
<p>Nous n&apos;avons aucun contr&ocirc;le et n&apos;assumons aucune responsabilit&eacute; pour le contenu, les politiques de confidentialit&eacute; ou les pratiques de tout site ou service tiers.</p>
<p><br/></p>
<p>Modifications de cette politique de confidentialit&eacute;</p>
<p>Nous pouvons mettre &agrave; jour notre politique de confidentialit&eacute; de temps &agrave; autre. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialit&eacute; sur cette page.</p>
<p><br/></p>
<p>Nous Vous informerons par e-mail et/ou par un avis bien visible sur Notre Service, avant que le changement ne prenne effet et nous mettrons &agrave; jour la date de &laquo; Derni&egrave;re mise &agrave; jour &raquo; en haut de la pr&eacute;sente Politique de confidentialit&eacute;.</p>
<p><br/></p>
<p>Il vous est conseill&eacute; de consulter p&eacute;riodiquement cette politique de confidentialit&eacute; pour tout changement. Les modifications apport&eacute;es &agrave; cette politique de confidentialit&eacute; entrent en vigueur lorsqu&apos;elles sont publi&eacute;es sur cette page.</p>

                </div>
            </div>
            {/* End Blog Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}
export default Privacy;
