import React from 'react';
import ReactApexChart from 'react-apexcharts'

const sortResult = (v1, v2) => {
    return v1.y < v2.y;
}
const ApexChart = ({ votes, allMisses, paidChoices }) => {

        const allVotes = [];
        if(allMisses && votes && paidChoices) {
            allMisses.map(miss => {
                let missPaidVote = paidChoices.filter(vote => vote.missId === miss.missId).length * 5;
                let missVote = votes.filter(vote => vote.missId === miss.missId).length;
                allVotes.push({x: `Miss#${miss.missId}`, y: missVote + missPaidVote });
            });
        }
        allVotes.sort(sortResult);
        const state = {
    
            series: [{
                data: allVotes
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type:"category"
                }
            },
    
    
        };


    return (
        <div id="chart">
            <ReactApexChart options={state.options} series={state.series} type="bar" height={500} />
        </div>
    );

}

export default ApexChart;