import React, { useState } from 'react'
import { FiCheck, FiChevronUp } from "react-icons/fi";
import ModalVideo from 'react-modal-video';
import { BsBoxArrowInUpRight } from 'react-icons/bs'

function AboutContext() {
    const [isOpen, setIsOpen] = useState(false)
    var namesItemOne = [
        {id:"Origin", res:"Be of African origin"},
        {id:"Age", res:"Be between 17 and 30 years old"},
        {id:"Height", res:"Be at least 1.65m tall"},
        {id:"Residence", res:"Reside in European union and be flexible"},
        {id:"Social", res:"Have a social media (Facebook, Instagram, or TikTok...)"},
    ];
  return (
    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Register and win <strong><i>5000€</i></strong> ! </h2>
                                        <p className="description">Be part of the adventure MDC Africa 2023.</p>
                                    </div>
                                    <div className="mt--30">
                                        <h4>Conditions of participation :</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> <b>{name.id} :</b> {name.res}</li>;
                                            })}
                                        </ul>
                                        <div className="about-button mt--30">
                                        <a className="rn-button-style--2 btn-solid" href="/inscription/candidate">Register Today !</a>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/africa/contact/mdc flyer 2023 enn.jpg" alt="About Images"/>
                                    <ModalVideo channel='youtube' isOpen={isOpen} videoId='ZOoVOfieAF8' onClose={() => setIsOpen(false)} />
                                    {/* <button className="video-popup position-top-center theme-color" onClick={() => setIsOpen(true)}><span className="play-icon"></span></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
  )
}

export default AboutContext