import { IconButton } from '@mui/material';
import React, { Fragment, useState } from 'react'
import { AiOutlineUserAdd } from 'react-icons/ai';
import LoginPopup from "./loginpopup"

function Login() {
    const [open, setopen] = useState(false);
    const setClose = (value) => {
        setopen(false);
    }
    const setOpen = (value) => {
        setopen(true);
    }
    return (
        <>
            <IconButton color="primary" onClick={() => setOpen()}>
            connect <AiOutlineUserAdd />
            </IconButton>
            <LoginPopup
                onClose={(value) => setClose(value)}
                open={open}
                selectedValue={false}
            />
        </>
    )
}

export default Login
