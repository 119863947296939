import React, { useCallback, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';

import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

export default function GalleryView(props) {
    const lightGallery = useRef(null);
    const [container, setContainer] = useState(null);
    const setContainerRef = useCallback((node) => {
        if (node !== null) {
            setContainer(node);
        }
    }, []);

    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance;
            lightGallery.current.openGallery();
        }
    }, []);
    
    const [items, setItems] = useState([
        {
            id: '1',
            src:
                'https://images.unsplash.com/photo-1542103749-8ef59b94f47e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80',
            thumb:
                'https://images.unsplash.com/photo-1542103749-8ef59b94f47e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80',
        },
        {
            id: '2',
            src:
                'https://images.unsplash.com/photo-1473876988266-ca0860a443b8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80',
            thumb:
                'https://images.unsplash.com/photo-1473876988266-ca0860a443b8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80',
        },
        {
            id: '3',
            src:
                'https://images.unsplash.com/photo-1588953936179-d2a4734c5490?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1400&q=80',
            thumb:
                'https://images.unsplash.com/photo-1588953936179-d2a4734c5490?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=240&q=80',
        },
    ]);


    return (
        <>
            <div id="inline-gallery-container" className="inline-gallery-container" ref={setContainerRef}></div>
            {container && <LightGallery
                plugins={[lgZoom, lgThumbnail]}
                elementClassNames="custom-classname"
                dynamic
                dynamicEl={props?.items}
                closable={false}
                showMaximizeIcon
                container={container}
                onInit={onInit}
                className=""
            ></LightGallery>}
        </>
    )
}
