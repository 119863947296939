export const loadMisses = () => {
    return async (dispatch, getstate, { getFirebase, getFirestore }) => {
        //console.log(getFirebase())

        //httpcallable
        const response = await fetch('https://admin-mdc-home-fnneu.ondigitalocean.app/voteparticipantes');
        const missesList = await response.json();

        // order Misses by their missId
        const misses = missesList.sort((a, b) => { return a.missId - b.missId })

        //console.log(misses)

        dispatch({
            type: 'GET_MISSES',
            payload: misses
        })
    }
}