import React, { Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import BrandTwo from "../../components/gala/BrandTwo";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { ImManWoman } from 'react-icons/im'
import PageHelmet from "../../components/common/Helmet";
import About from "../../components/gala/About";
import Fashion from "../../components/gala/Fashion";
import GalleryView from '../../components/gallery/GalleryView';
import useSWR from "swr";
import {axiosFetcher} from "../../utils/utills";
import {FiChevronUp} from "react-icons/fi";

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image_glamour--5',
        category: '',
        title: 'MDC Gala.',
        description: 'MDC Fashion and Glamour Gala Night.',
        buttonText: 'Gala Rerservation',
        buttonLink: '/gala/reservation'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image_glamour--1',
        category: '',
        title: 'Fashion and Glamour.',
        description: ' l’évènement de l’année.',
        buttonText: 'Gala Rerservation',
        buttonLink: '/gala/reservation'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image_glamour--6',
        category: '',
        title: 'Gala Night.',
        description: 'À ne pas rater évènement inoubliable.',
        buttonText: 'Gala Rerservation',
        buttonLink: '/gala/reservation'
    }
]

let images = ['gala-maahlox.jpeg','mdc_gala.jpeg','mdc_fashion_show.jpg','mdc_taptapsend.jpg', 'taptap_send.jpg']
let base = '/assets/images/gala/'



const Business = () =>  {
        const { data, isLoading, isError } = useSWR("/gala-info", axiosFetcher);

        console.log(data)

        return (
            <Fragment>
                <PageHelmet pageTitle="Business" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="8">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title"><ImManWoman style={{ color: "#ffbf36" }} /> {value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--80 bg_color--1">
                    <About data={data} />
                </div>
                {/* End About Area  */}
                {data?.Gallery && <div className="rn-about-area ptb--80 bg_color--1">
                    <GalleryView items={data.Gallery.map(i => ({id: i?.url, src: i?.url, thumb: i?.url}))}/>
                </div>}
                {/* End About Area  */}
                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--5 ">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <BrandTwo data={data} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Fashion Area  */}
                <div className="rn-about-area ptb--60 bg_color--1">
                    <Fashion data={data} />
                </div>
                {/* End Fashion Area  */}

                {/* End About Area  */}
                {data?.Gallery2 && <div className="rn-about-area ptb--80 bg_color--1">
                    <GalleryView items={data.Gallery2.map(i => ({id: i?.url, src: i?.url, thumb: i?.url}))}/>
                </div>}
                {/* End About Area  */}

                {/* Start Portfolio Area */}
                {/* <div className="portfolio-area ptb--40 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 mb--30">
                                        <h2 className="title mt-5">Gallery Photo Gala</h2>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {data?.participantes && <GalleryView items={data.participantes.map(part => ({ id: part.id, src: part?.profil[0]?.url, thumb: part?.profil[0]?.url }))} />}
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Portfolio Area */}



                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )

}
export default Business;