import { Typography } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts'

const sortResult = (v1, v2) => {
    return v1.y < v2.y;
}
const StatisticsAward = ({ votes, allCandidate, categorie }) => {

        const allVotes = [];
        if(allCandidate && votes) {
            let categorieCandidate = allCandidate.filter(cand => cand.categorie === categorie.id);
            switch (categorie.id) {
                case "webcomedien":
                    categorieCandidate.map(candidat => {
                        let missVote = votes.filter(vote => vote.webcomedienId === candidat.missId).length;
                        allVotes.push({x: `${candidat.name.slice(0,11)}`, y: missVote });
                    });
                    break;
                case "blogvlog":
                    categorieCandidate.map(candidat => {
                        let missVote = votes.filter(vote => vote.blogvlogId === candidat.missId).length;
                        allVotes.push({x: `${candidat.name.slice(0,11)}`, y: missVote });
                    });
                    break;
                case "artistemusicien":
                    categorieCandidate.map(candidat => {
                        let missVote = votes.filter(vote => vote.artistemusicienId === candidat.missId).length;
                        allVotes.push({x: `${candidat.name.slice(0,11)}`, y: missVote });
                    });
                    break;
                case "webemission":
                    categorieCandidate.map(candidat => {
                        let missVote = votes.filter(vote => vote.webemissionId === candidat.missId).length;
                        allVotes.push({x: `${candidat.name.slice(0,11)}`, y: missVote });
                    });
                    break;
                case "youtubeur":
                    categorieCandidate.map(candidat => {
                        let missVote = votes.filter(vote => vote.youtubeurId === candidat.missId).length;
                        allVotes.push({x: `${candidat.name.slice(0,11)}`, y: missVote });
                    });
                    break;
                default:
                    break;
                }

        }
        allVotes.sort(sortResult);
        const state = {
    
            series: [{
                data: allVotes
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type:"category"
                }
            },
    
    
        };


    return (
        <div id="chart">
            <Typography color='primary' variant='h4'>{categorie?.title}</Typography>
            <ReactApexChart options={state.options} series={state.series} type="bar" height={220} />
        </div>
    );

}

export default StatisticsAward;