import React, { Component } from "react";
import { gql, useQuery } from "@apollo/client";
import ReactMarkdown from "react-markdown";
import useSWR from "swr";
import { axiosFetcher } from "../../utils/utills";

const APROPOS = gql`
    query GetApropos {
        homepage {
            apropos,
            apropos1,
            apropos2
        }
    }
`

const About = () => {
    const { data, isLoading, isError } = useSWR("/homepage", axiosFetcher);

    if(isLoading) return "";
    if(isError) return "";
    return (
        <React.Fragment>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">

                        <div className="col-lg-5 col-md-12">
                            <div className="thumbnail">
                                <img className="w-100" src={ data?.imgMiss?.url || "/assets/images/home/mdc_2021_home2.png" }alt="About Images" />
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12 mt-sm-5 pt-md-5 ">
                            <div className="about-inner inner">
                                <div className="section-title">
                                <ReactMarkdown children={data?.apropos} />
                                </div>
                                <div className="row mt--30 mt_sm--10">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                        <ReactMarkdown children={data?.apropos1} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                        <ReactMarkdown children={data?.apropos2} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default About;