export const connectUser = credentials => {
    return async (dispacth, getState, { getFirebase, getFirestore }) => {
        //console.log("connect action")
        const firebase = getFirebase();
        try {
            const user = await firebase.auth().signInWithCredential(firebase.auth.GoogleAuthProvider.credential(
                '{"sub": "abc123", "email": "kapygenius@gmail.com", "email_verified": true}'
              ));

            //console.log(user);
            dispacth({
                type: "LOGIN_SUCCESS",
                payload: user
            })
        } catch (error) {
            //console.log(error);
            dispacth({
                type: "LOGIN_ERROR",
                payload: error
            })
        } 
    }
}