import React, { useContext, useEffect } from 'react'

export default function GTranslator() {

    useEffect(() => {
        var addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        addScript.async = true;
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
        return () => {
            document.body.removeChild(addScript);
        }
    }, [])

    const googleTranslateElementInit = () => {

        new window.google.translate.TranslateElement({
            pageLanguage: 'fr',
            includedLanguages : "en,fr,de", // include this for selected languages
            layout: 2,
            autoDisplay: false
        },
        'google_translate_element');

    }

    return (
        <>
            <div id="google_translate_element" > </div>
        </>
    )
}