import React, { Component } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";

const SPONSOR = gql`
    query getSponsors($annee:String) {
        sponsors(where:{annees:{annee:$annee}}) {
            id,
            name,
            logo {
                url
            }
        }
    }
`


const BrandTwo = (props) => {
    const {year} = useParams();
    const { loading, error, data } = useQuery(SPONSOR, {
        variables: {annee: year || "2021"}
    });
    if (loading) return "";
    if (error) return "";
    return (
        <React.Fragment>
            <div className="rn-brand-area brand-separation bg_color--5 pb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>{props?.title || `Sponsors ${year || 2021}`}</h2>
                            <ul className="brand-style-2">
                                {data.sponsors.map((value, key) => (
                                    <li>
                                        <Link to={`/sponsor/${value.id}`}> <img src={value.logo[0].url} alt={`Sponsor Miss Diamond Africa - ${value.name} `} /> </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default BrandTwo;