import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import ApexChart from './statistics2'
import TabPanel from './TabPanel'
import Vote from './Vote'
import ChatRoom from './chat/chat'
import { FiExternalLink } from 'react-icons/fi'
import { GiCutDiamond} from 'react-icons/gi'
import { AiFillHeart, AiOutlineHeart, AiOutlineUser } from 'react-icons/ai'
import {loadMisses} from "../../actions/missAction";


const MainVote = (props) => {
    const [allMisses, setAllMisses] = useState([])
    const [screen, setValue] = React.useState(0);

    const status = props.status || [] ; //vor, during, after, pause

    const handleChange = (event, newScreen) => {
        setValue(newScreen);
    };

    useEffect(() => {
        props.loadMisses();
    }, [])

    useEffect(() => {
        //console.log(props)
        setAllMisses(props.missReducer);
        return () => {
        }
    }, [props.missReducer]);

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            {status[0]?.vor === true ? <Typography className=' pt--50' variant='h4' align='center'>Les Votes commencent bientôt! <br/><br/> Votes are starting soon! <br/><br/></Typography>:''}
            <Typography color='secondary' align='center'>One Person<AiOutlineUser/> equal one Free vote<AiOutlineHeart/>.</Typography>
            <Typography color='primary' align='center'>Do you like the project ? <Link to='https://www.paypal.com/donate?hosted_button_id=QGTARQXYEPYWE'>Donate <FiExternalLink/></Link></Typography>
            <Typography color='primary' align='center'><GiCutDiamond/> The final will take place at the Glamour & Fashion Gala ! <GiCutDiamond/><br/>
                <Link className="rn-button-style--2 btn-solid mr-3 mb-3" to="/gala/reservation">
                    <span>Gala reservation </span>
                </Link>
            </Typography> <br/>

            {status[0]?.during === true || status[0]?.after === true  || status[0]?.pause === true ?
            <>
            <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
            >
                <Grid item >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={screen} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Vote" {...a11yProps(0)} />
                            <Tab label="Statistics" {...a11yProps(1)} />
                            <Tab label="Chat" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                </Grid>
            </Grid>
            <TabPanel value={screen} index={0}>
                {
                    status[0]?.pause === true ?
                    <Typography className=' pt--50' variant='h4' align='center'>
                        Les Votes continuent dans un Instant !  <br/> Votes will continue soon !
                    </Typography> : ''
                }
                {
                    status[0]?.after === true ?
                    <Typography className=' pt--50' variant='h4' align='center'>
                        Fin! Merci pour Votre Participation. <br/> End! Thanks for your participation.
                    </Typography> : ''
                }
                {
                    status[0]?.during === true ?
                    <Vote allMisses={allMisses} paidChoices={props.paidChoices} votes={props.votes} />
                    : ''
                }
                
            </TabPanel>
            <TabPanel value={screen} index={1}>
                {
                    status[0]?.pause === true && status?.block == true ?
                    <Typography className=' pt--50' variant='h4' align='center'>
                        Les Votes continuent dans un Instant !  <br/> Votes will continue soon !
                    </Typography> : ''
                }
                {
                    status[0]?.after === true &&  status?.block == true?
                    <Typography className=' pt--50' variant='h4' align='center'>
                        Fin! Merci pour Votre Participation ! <br/> End! Thanks for your participation!
                    </Typography> : ''
                }
                {
                    status[0]?.block === false ?
                    <ApexChart votes={props.votes} paidChoices={props.paidChoices} allMisses={allMisses} />
                    : ''
                }
            </TabPanel>
            <TabPanel value={screen} index={2}>
                <ChatRoom />
            </TabPanel>
            </> : ''
            }
            <Typography color='primary' align='center'><GiCutDiamond/> The final will take place at the Glamour & Fashion Gala ! <GiCutDiamond/><br/>
                <Link className="rn-button-style--2 btn-solid mr-3 mb-3" to="/gala/reservation">
                    <span>Gala reservation </span>
                </Link>
            </Typography> <br/>
        </>
    )
}

const mapStateToProps = (state) => {

    return {
        votes: state.firestoreReducer.ordered.userchoice,
        status: state.firestoreReducer.ordered.status,
        missReducer: state.missReducer,
        paidChoices: state.firestoreReducer.ordered.paidVote
    };
}

export default compose(
    connect(mapStateToProps, {loadMisses}),
    firestoreConnect([{ collection: 'userchoice' }, { collection: 'paidVote' }, { collection: 'status' } ]),
)(MainVote)
