import { Link, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { AiFillLinkedin, AiOutlineInstagram } from 'react-icons/ai'
import { FiExternalLink } from 'react-icons/fi'
import PageHelmet from '../../components/common/Helmet'
import Footer from '../../components/footer/footer'
import HeaderVote from '../../components/header/headerVote'
import  MainVote  from '../../components/vote'

function Vote() {
    return (
        <Fragment>
            <PageHelmet	 pageTitle="Vote" />
            <HeaderVote headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <div className=" pt--50 pb--120 mt-5">
               <MainVote />
               
               <Typography variant='body2' align='center' className=' pt--60'> 
               <img alt='kapygenius' width="50" src='/assets/images/logo/kapy.png' /> 
               <br /> Made by <Link href='https://kapygenius.com' target='_blank' underline='hover'>KapyGenius<FiExternalLink /></Link> follow me on <Link target='_blank' href='https://www.instagram.com/kapygenius'> <AiOutlineInstagram /></Link>
               <Link target='_blank' href='https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjPs6vGroT0AhXI_7sIHVWzCGIQFnoECAIQAQ&url=https%3A%2F%2Fde.linkedin.com%2Fin%2Fdonald-steve-kapyouo-tchuisseu-b7145b1ab&usg=AOvVaw3HoEdWERgflXHTDjbpmMiY'><AiFillLinkedin/></Link> </Typography>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Vote
