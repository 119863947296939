import React, { Component } from "react";
import { gql, useQuery } from "@apollo/client";
import ReactMarkdown from "react-markdown";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";
import { AiOutlineLineChart} from "react-icons/ai";
import { GiQueenCrown} from "react-icons/gi";
import { FaGlobeAfrica } from "react-icons/fa";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { axiosFetcher } from "../../utils/utills";

const SERVICE = gql`
    query GetApropos {
        homepage {
            serviveintro,
            sercicepublic,
            servicesponsor,
            servicesociete,
            serviceparticipante,
        }
    }
`

const ServiceTwo = () => {
    const { data, isLoading, isError } = useSWR("/homepage", axiosFetcher);
    //const { loading, error, data} = useQuery(SERVICE);
    if(isLoading) return "";
    if(isError) return "";

    const ServiceList = [
        {
            icon: <AiOutlineLineChart />,
            description: data?.servicesponsor,
            to: "/inscription/collaboration"
        },
        {
            icon: <GiQueenCrown />,
            description: data?.serviceparticipante,
            to: "/inscription/candidate"
        },
        {
            icon: <FiUsers />,
            description: data?.sercicepublic,
            to: "/donate"
        },
        {
            icon: <FaGlobeAfrica />,
            description: data?.servicesociete,
            to:"/donate"
        }
    ]
    

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-4 col-12">
                    <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                        <ReactMarkdown children={data && data.serviveintro} />
                        <div className="service-btn">
                            <Link className="btn-transparent rn-btn-dark" to="/inscription/collaboration"><span className="text">Become Sponsor/Partner</span></Link> <br/>
                            <Link className="btn-transparent rn-btn-dark" to="/inscription/candidate"><span className="text">REGISTER TODAY !</span></Link><br/>
                            <Link className="btn-transparent rn-btn-dark" to="/donate"><span className="text">Make Donation</span></Link><br/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-12 mt_md--50">
                    <div className="row service-one-wrapper">
                        {ServiceList.map((val, i) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                <Link to={val.to}>
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ServiceTwo;
