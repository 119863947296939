const initState = [{missId: 1, vote:5}, {missId: 2, vote: 20}, {missId: 3, vote:10}]

export const voteReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_CHOICE':
            const newState = state.map(vote => {
               return vote.missId == action.payload.missId ? {...vote, vote: vote.vote + 1 } : vote;
            })
            //console.log(newState)
            return newState;
            
        case'ADD_PAID_CHOICE':
            const newStatePaid = state.map(vote => {
                return vote.missId == action.payload.missId ? {...vote, vote: vote.vote + 5 } : vote;
            })
            //console.log(newStatePaid)
            return newStatePaid;
        default:
            return state;
    }
}