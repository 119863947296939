import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";
import { GiReceiveMoney } from "react-icons/gi";
import { connect } from "react-redux";
import { connectUser } from "../../actions/userAction";
import { Avatar, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { AiOutlineUser, AiOutlineUserAdd } from "react-icons/ai";

import Login from "../login/index";



const MDC = ({ year }) => (
    <li className="has-droupdown"><a href="#" >MDC {year}</a>
        <ul className="submenu">
            {year !== 2021 && <li><Link to={`/participantes/${year}`}>Participantes</Link></li>}
            {year !== 2021 && <li><Link to={`/miss/${year + 1}`}>Miss</Link></li>}
            <li><Link to={`/sponsors/${year}`}>Sponsors</Link></li>
            {year !== 2021 && <li><Link to={`/jurys/${year}`}>Jurys</Link></li>}
        </ul>
    </li>
);

class HeaderVote extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false, shouldConnect: false };
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            // //console.log('All assets are loaded')
        })
    }

    getUsername(email) {
        return email !== null ? email.split('@')[0] : '';
    }

    getAvatar(email) {
        return email !== null  ? email.slice(0, 2) : null;
    }

    setOpen() {
        this.setState({ open: true });
    }

    setClose(value) {

        this.setState({ open: false, shouldConnect: value });

        this.setState({ shouldConnect: false })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    componentDidMount() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            var elm = document.querySelector('.header--fixed');
            if (!!elm) {
                if (value > 100) {
                    elm.classList.add('sticky')
                } else {
                    elm.classList.remove('sticky')
                }
            }
            
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
    }

    render() {

        const { color = 'default-color' } = this.props;
        let logoUrl = <img src="/assets/images/logo/mdc.png" width="50" height="50" alt="Miss Diamond Africa Logo" />;

        return (
            <header className={`header-area formobile-menu header--transparent header--fixed ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl} <span className="logo-text">MDC</span>
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li className="has-droupdown"><Link to="/">Home</Link>
                                    <ul className="submenu">
                                        <li><Link to="/team">Team</Link></li>
                                        <li><Link to="/vote" >Miss Vote</Link></li>
                                        <li><Link to="/awardvote" >Award Vote</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/gala" >Gala</Link></li>
                                <li className="has-droupdown"><Link to="#" >Vote</Link>
                                    <ul className="submenu">
                                        <li><Link to="/vote" >Miss Vote</Link></li>
                                        <li><Link to="/awardvote" >Award Vote</Link></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown"><a href="#" >MDC</a>
                                    <ul className="submenu">
                                        <MDC year={2021} />
                                        <MDC year={2020} />
                                    </ul>
                                </li>
                                <li className="has-droupdown"><a href="#" >Go to</a>
                                    <ul className="submenu">
                                        <li><Link to="/actualites" >Actualités</Link></li>
                                        <li><Link to="/contact" >Contact</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/donate" >Faire un don <GiReceiveMoney className="lead" /></Link></li>

                            </ul>
                        </nav>
                        <div>
                            
                            {
                                this.props.authReducer.auth.isEmpty ?
                                    <Login />
                                    :
                                    <Button>
                                        <Avatar style={{marginRight:"5px"}} src={this.props.authReducer.auth.photoURL}>
                                            {this.getAvatar(this.props.authReducer.auth.email)}
                                        </Avatar>
                                        {this.getUsername(this.props.authReducer.auth.email)}
                                    </Button>
                            }


                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

const mapStateToProps = (state) => {
    
    return {
        authReducer: state.firebaseReducer
    }
}

export default connect(mapStateToProps, { connectUser })(HeaderVote);