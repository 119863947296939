import React, { useCallback, useRef, useState } from 'react'
import PageHelmet from '../../components/common/Helmet';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import GalleryView from '../../components/gallery/GalleryView';
import { gql, useQuery } from '@apollo/client';
import { AiOutlineDownload } from 'react-icons/ai';

const dataimg = [
    {
        id:1,
        src: '/assets/ebook/images/mdc_brochure_mock.jpg',
        thumb: '/assets/ebook/images/mdc_brochure_mock.jpg'
    },
    {
        id:1,
        src: '/assets/ebook/images/mdc_brochure_mock2.jpg',
        thumb: '/assets/ebook/images/mdc_brochure_mock2.jpg'
    },
    {
        id:1,
        src: '/assets/ebook/images/mdc_brochure_Plan.jpg',
        thumb: '/assets/ebook/images/mdc_brochure_Plan.jpg'
    }
]

const PARTICIPANTES = gql`
    query getParticipantes {
        participantes {
            Nom,
            id,
            profil {
                url
            }
        }
    }
`

export default function Ebook() {
  const { loading, error, data } = useQuery(PARTICIPANTES);
  
  return (
    <>
      <PageHelmet pageTitle="Ebook MDC" />
      <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--120 bg_color--5">
          <div className="portfolio-sacousel-inner">
            <div className="container-fluid">
              <div className='row pt-5'>
                    <div className='col-md-7'>
                        <GalleryView items = {dataimg} />
                    </div>
                    <div className='col-md-5'>
                        <div className="section-title  service-style--3 mb--30">
                            <h2 className="title mt-5">Download Brochure </h2>
                        </div>
                        <p>Découvres plus sur MDC à travers notre brochure disponible gratuitement pour le téléchargement.</p>
                        <p>
                            <a className='rn-button-style--2 btn-solid' href='/assets/ebook/mdc_brochure.pdf'>Télécharger <AiOutlineDownload></AiOutlineDownload></a>
                        </p>
                    </div>
                  
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </>
  )
}
