import React from 'react'
import { gql, useQuery } from "@apollo/client";
import ReactMarkdown from 'react-markdown';
import { AiOutlineSketch } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const TITRE = gql`
    query GetApropos {
        homepage {
            actualite
        }
    }
`

const BLOG = gql`
    query GetArticles {
        articles(limit:6, sort:"published_at:desc", where: {domaine_articles: {nom: "mdc_central"}}) {
            id,
            titre,
            image {
                url
            }
        }
    }  
`

function BlogHome() {
    const { loading, error, data } = useQuery(TITRE);
    const { loading:pLoading, error:pError, data:pData } = useQuery(BLOG);
    if (loading || pLoading) return "";
    if (error || pError) return "";
    return (
        <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="section-title text-left">
                            <ReactMarkdown children={data.homepage.actualite} />   
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                            <Link className="btn-transparent rn-btn-dark" to="/actualites"><span className="text">Voire plus d'actualités</span></Link>
                        </div>
                    </div>
                </div>
                <div className="row mt--60 mt_sm--40">
                    {pData.articles.map((value, i) => (
                        <div className="col-lg-4 col-md-6 col-12" key={i}>
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <Link to={`/actualites/${value.id}`}>
                                        <div className='img_imgcontainer img_imgw100'>
                                            <img className="w-100" src={value.image.url} alt="Blog Images" />
                                        </div>
                                    </Link>
                                </div>
                                <div className="content">
                                    <p className="blogtype">Diamond price <AiOutlineSketch/></p>
                                    <h4 className="title"><Link to={`/actualites/${value.id}`}>{value.titre}</Link></h4>
                                    <div className="blog-btn">
                                        <Link className="rn-btn text-white" to={`/actualites/${value.id}`}>Read more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BlogHome
