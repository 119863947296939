import React, { Component, useState } from "react";
import PageHelmet from "../../components/common/Helmet";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BlogHome from "../../components/blocs/BlogHome";
import { useParams } from "react-router-dom";
import {gql, useQuery} from '@apollo/client';
import ReactMarkdown from "react-markdown";

const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };


const REVIEW = gql`
query GetArticle($id: ID!) {
    article (id: $id) {
        id,
        titre,
        test,
        updatedAt,
        image {
            url
        }
    }
} 
`

const ActualiteDetail = () => {
    const {id} = useParams();
    const {loading, error, data} = useQuery(REVIEW, {
        variables: {id: id}
    });

    return (
        <React.Fragment>
            <PageHelmet pageTitle='Blog Details' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image " style={{backgroundImage:`url("${data?.article?.image?.url}")`}} data-black-overlay="7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-single-page-title text-center pt--100">
                                <h2 className="title theme-gradient">{data?.article?.titre}</h2>
                                <ul className="blog-meta d-flex justify-content-center align-items-center">
                                    <li><FiClock />{(new Date(data?.article?.updatedAt)).toLocaleString("fr-FR", DATE_OPTIONS)}</li>
                                    <li><FiUser />MDC Team</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Blog Details */}
            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    {data?.article?.test && <ReactMarkdown children={data?.article?.test}></ReactMarkdown>}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Start Blog Area */}
                    <BlogHome />
                    {/* End Blog Area */}
                </div>
            </div>
            {/* End Blog Details */}


            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}
export default ActualiteDetail;