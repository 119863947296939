import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { GiQueenCrown } from "react-icons/gi";
import { AiFillStar } from "react-icons/ai";

const PARTICIPANTES = gql`
    query getParticipantes($year:String) {
        participantes(where:{annee:{annee:$year}}) {
            Nom,
            status_participantes{
                status
            },
            profil {
                url
            }
        }
    }
`
const JURYS = gql`
    query getJurys($year:String) {
        jurys(where:{annees:{annee:$year}}) {
            Nom,
            image {
                url
            }
        }
    }
`

const TEAM = gql`
    query getTeam {
        teams {
            nom,
            statusteams {
                status
            }
            image {
                url
            }
        }
    }
`

const getCatTeam = (statusteam) => {
    return (statusteam?.map(s => s.status)).join(", ")
}

const getOrderedParticipantes = (participantes) => {
    //console.log(participantes)
    let Part = [];
    let sPart = participantes.find(p => p.status_participantes[0].status === "Miss");
    if (sPart) {
        Part.push(sPart);
    }
    sPart = participantes.find(p => p.status_participantes[0].status === "Premiere dauphine");
    if (sPart) Part.push(sPart);
    sPart = participantes.find(p => p.status_participantes[0].status === "Deuxieme dauphine");
    if (sPart) Part.push(sPart);
    sPart = participantes.filter(p => p.status_participantes[0].status === "Finaliste");
    if (sPart.length > 0) Part.push(...sPart);
    sPart = participantes.filter(p => p.status_participantes[0].status === "Demi finaliste");
    if (sPart.length > 0) Part.push(...sPart);
    sPart = participantes.filter(p => p.status_participantes[0].status === "Participante premier tour");
    if (sPart.length > 0) Part.push(...sPart);
    return Part;
}

const PortfolioList = (props) => {
    const { check } = props;
    var gqlVar;

    ////console.log(check)

    if (check === "participantes") {
        gqlVar = PARTICIPANTES;
    }
    else if (check === "jurys") {
        gqlVar = JURYS;
    }
    else {
        gqlVar = TEAM;
    }

    const { year } = useParams();

    const { loading, error, data } = useQuery(gqlVar, {
        variables: { year: year }
    });
    if (loading) return "";
    if (error) return "";

    const { column, styevariation } = props;

    var listResult;

    if (check === "participantes") {
        const participantes = getOrderedParticipantes(data.participantes);
        ////console.log(participantes);
        listResult = participantes.map(part => ({ Nom: part.Nom, img: part.profil[0]?.url, category: part.status_participantes[0]?.status, showMore: false }));
    }
    else if (check === "jurys") {
        listResult = data.jurys.map(part => ({ Nom: part.Nom, img: part.image?.url, category: `MDC ${2020}`, showMore: false }));
    }
    else {
        listResult = data.teams.map(part => ({ Nom: part.nom, img: part.image?.url, category: getCatTeam(part.statusteams), showMore: false }));
    }



    return (
        <React.Fragment>
            {listResult.map((value, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`portfolio ${styevariation}`}>
                        <div className="thumbnail-inner">
                            <div className={`thumbnail`} style={{ backgroundImage: `url("${value.img}")` }}></div>
                            <div className={`bg-blr-image`} style={{ backgroundImage: `url("${value.img}")` }}></div>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <p>{value.category} {value.category == "Miss" ? ` Diamond Cameroon ${parseInt(year)}` : ""}</p>

                                <h4>{value.category == "Miss" && <Link to={`/miss/${parseInt(year)}`}>{value.Nom} <GiQueenCrown className="lead" style={{ color: "#ffbf36" }} /></Link>}
                                    {value.category !== "Miss" && <Link>{value.Nom} {(value.category == "Premiere dauphine" || value.category == "Deuxieme dauphine") && <AiFillStar className="lead" style={{ color: "#ffbf36" }} />  }</Link>}
                                </h4>

                                {value.category == "Miss" && <div className="portfolio-button">
                                    <Link className="rn-btn" to={`/miss/${parseInt(year)}`}>Savoir Plus</Link>
                                </div>}
                            </div>
                        </div>
                        {value.category == "Miss" && <Link className="link-overlay" to={`/miss/${parseInt(year)}`}></Link>}
                    </div>
                </div>
            ))}

        </React.Fragment>
    )
}
export default PortfolioList;