export const addChoice = choice => {
    return async (dispatch, getstate, { getFirebase, getFirestore }) => {
        //console.log(getFirebase())

        //httpcallable
        var voteOfUser = getFirebase().functions().httpsCallable('normalVote');
        try {
            voteOfUser({ missId: choice.missId })
                .then((result) => {
                    // Read result of the Cloud Function.
                    //console.log("Ok", result)
                });
        } catch (error) {
            //console.log(error)
        }

        //firestore
        /*const firestore = getFirestore();
        const add = await firestore.collection('userchoice').add({
            choice: choice.missId,
            email: 'kapygenius.com'
        })

        //console.log('added', add);
        */
        dispatch({
            type: 'ADD_CHOICE',
            payload: choice
        })
    }
}

export const addAwardChoice = choice => {
    return async (dispatch, getstate, { getFirebase, getFirestore }) => {

        //httpcallable
        var voteOfUser = getFirebase().functions().httpsCallable('awardChoice');
        try {
            voteOfUser({ missId: choice.missId, categorie: choice.categorie })
                .then((result) => {
                    // Read result of the Cloud Function.
                    //console.log("Ok", result)
                });
        } catch (error) {
            //console.log(error)
        }

        dispatch({
            type: 'ADD_CHOICE',
            payload: choice
        })
    }
}


export const addPaidChoice = paidChoice => {
    return async (dispatch, getstate, { getFirebase, getFirestore }) => {

        //httpcallable
        var paidVote = getFirebase().functions().httpsCallable('paidVote');
        try {
            paidVote({ missId: paidChoice.missId, isPaid: true,  orderId: paidChoice.orderId, votes: paidChoice.votes, amount: paidChoice.amount })
                .then((result) => {
                    // Read result of the Cloud Function.
                    //console.log("Ok", result)
                });
        } catch (error) {
            //console.log(error)
        }
        //firestore
        /*const firestore = getFirestore();
        const add = await firestore.collection('userchoice').doc('1dm2UQSiiCqrjyLrosLq').collection('addedpaidvote').add({
            email: 'kapygenius@gmail.com',
            missId: paidChoice.missId,
            votes: 5
        })

        //console.log('added paid', add);
        */

        dispatch({
            type: 'ADD_PAID_CHOICE',
            payload: paidChoice
        })
    }
}