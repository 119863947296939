import * as EmailValidator from 'email-validator';

export function emailValidator(email) {
    let error;
    if (!email) {
      error = "Required";
    } else if (!EmailValidator.validate(email)) {
      error = "Invalid email address";
    }
    return error;
}

export function nameValidator(name="") {
    let error;
    if (!name) {
      error = "Required";
    } else if (name.length < 4) {
      error = "Invalid name";
    }
    return error;
}

export function telValidator(tel) {
    let error;
    if (!tel) {
      error = "Required";
    } else if (isNaN(parseInt(tel)) ||  parseInt(tel) < 999999) {
      error = "Invalid phone";
    }
    return error;
}

export function messageValidator(message) {
    let error;
    if (!message) {
      error = "Required";
    } else if (message.length < 5) {
      error = "Invalid message";
    }
    return error;
}

export const toastConfig = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};






