import React, { Fragment, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './chat.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, Avatar } from '@chatscope/chat-ui-kit-react';
import { IconButton, Typography } from '@mui/material';
import  {FcGoogle} from  'react-icons/fc'
import {ImYahoo, ImYahoo2} from 'react-icons/im'
import Login from '../../login';
import SignIn from '../../login/signin';



const ChatRoom = props => {
    const [isLogged, setIslogged] = useState(false);

    const messages = props.messages;

    const email = props.firebase.auth()?.currentUser?.email;
    const phoneNumber = props.firebase.auth()?.currentUser?.phoneNumber;
    //console.log(props.firebase.auth()?.currentUser);

    const [formValue, setFormValue] = useState('');

    const getUsername = (email) => {
        return email !== null ? email.split('@')[0] : '';
    }



    const sendMessage = async () => {
        const { uid, photoURL, email } = props.authReducer.auth;
        

        //console.log(formValue, props.firebase.firestore.FieldValue.serverTimestamp(), uid, photoURL)

        await props.firestore.collection('chatroom').add({
            text: formValue,
            createdAt: props.firebase.firestore.FieldValue.serverTimestamp(),
            uid,
            photoURL,
            email
        })

        setFormValue('');
    }

    return (<>
        <main className='container'>



            <div style={{ position: "relative", height: "500px" }}>
                <MainContainer>
                    <ChatContainer>
                        <MessageList>
                            {messages && messages.map(msg => {
                                return (
                                        <Message key={msg.id} model={{
                                            message: msg.text,
                                            direction: msg.uid === props.authReducer.auth.uid ? "outgoing" : "incoming",
                                            sentTime: "just now",
                                            sender: "Joe"
                                        }} >
                                            <Message.Header  sender={getUsername(msg.email)} sentTime={msg.createdAt?.toDate()?.toLocaleTimeString() || 'just now'} />
                                            <Avatar src={msg.photoURL || "https://cdn3.iconfinder.com/data/icons/line-color-mix/66/Mix-21-1024.png"} size="sm" name={"Zoe"} status="available" />
                                        </Message>
                                );
                            })}


                        </MessageList>
                            {
                                (email && phoneNumber) &&
                                <MessageInput attachButton={false} value={formValue} onChange={(msg) => setFormValue(msg)} placeholder="Type message here..." onSend={() => sendMessage() } />
                            }
                        
                    </ChatContainer>
                </MainContainer>
                {
                    (!email && phoneNumber) &&
                    <SignIn firebase={props.firebase} setloggin = {(e) => setIslogged(e)} />
                }
                {
                    props.authReducer.auth.isEmpty &&
                    <Fragment>
                        <Typography align='center'>Please first <Login/> to identify yourself.</Typography>
                    </Fragment>
                    
                }   
            </div>
        </main>
    </>)
}



const mapStateToProps = (state) => {
    //console.log(state);
    return {
        authReducer: state.firebaseReducer,
        messages: state.firestoreReducer.ordered.chatroom?.slice().reverse(),
    };
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        {
            collection:'chatroom',
            orderBy: ['createdAt', 'desc'],
            limit: 20,
            
        }
    ])

)(ChatRoom);

