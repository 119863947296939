import React from 'react'
import MissCard from './missCard'

function Vote({allMisses, paidChoices, votes }) {
    return (
        <div>
            <div className=' container'>
            <div className='row'>
                {allMisses?.map(miss => (
                    <div className='col-md-6 col-lg-4 mb--50' key={miss.missId}> 
                       <MissCard miss={miss} votes={votes} paidChoices={paidChoices} />
                    </div>
                ))}
            </div>
            
        </div>
        </div>
    )
}

export default Vote
