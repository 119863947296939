import React, { Component } from "react";
import PageHelmet from '../../components/common/Helmet'
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { gql, useQuery } from "@apollo/client";
import BrandTwo from "../../components/brand/BrandTwo";

const SPONSOR = gql`
query GetArticle($id: ID!) {
    sponsor (id: $id) {
        id,
        name,
        description
        logo {
            url
        }
    }
} 
`

const BrandDetails = (props) => {
    const {id} = useParams();
    const {loading, error, data} = useQuery(SPONSOR, {
        variables: {id: id}
    });


        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image" style={{backgroundImage: `url("${data?.sponsor?.logo[0]?.url}")`}}  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">SPONSOR - {data?.sponsor?.name}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details pt--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-sm-5 col-12">
                                                <div className="thumb">
                                                    <img className="w-100 rounded" src={data?.sponsor?.logo[0]?.url} alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-7 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                   <h2> À propos de {data?.sponsor?.name} </h2>
                                                   <ReactMarkdown children={data?.sponsor?.description} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/** autres sponsors */}
                <BrandTwo title={"Autres sponsors"} />
                
                <div className="container">
                    <Link className="btn-transparent rn-btn-dark" to="/inscription/sponsor"><span className="text">Devenir sponsor</span></Link> <br/> <br/>
                </div>
                
                {/** autres sponsors */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )

}
export default BrandDetails;