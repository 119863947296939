import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import {BsBoxArrowDownRight, BsBoxArrowInUpRight} from "react-icons/bs"
import {FaAppStoreIos, FaGooglePlay} from "react-icons/fa"
import {FcCamera} from "react-icons/fc"
import {ImCool, ImHappy, ImManWoman} from "react-icons/im"
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const taptapsendEtape = [
    { id: "Etape 1", Description: <span>Télecharges l’application Taptapsend sur <a href="https://taptapsend.page.link/MISSDIAMOND" target="_blanc"> <FaAppStoreIos /> l’App Store <BsBoxArrowInUpRight /></a>  et/ou <a href="https://taptapsend.page.link/MISSDIAMOND" target="_blanc"><FaGooglePlay /> Google Play <BsBoxArrowInUpRight /></a> </span> },
    { id: "Etape 2", Description: <span>Utilises le code Promo <b>MISSDIAMOND</b> lors de ron premier envoi pour profiter de l’offre de Bienvenue d’une valeur de <b>5€/ 10 Dollars</b> soit <b>3300 Franc CFA</b> offerts</span> },
    { id: "Etape 3", Description: <span>Fais une capture d’écran <FcCamera /> de ton Envoi</span> },
    { id: "Etape 4", Description: <span>Va sur le site : <b> <a href="https://missdiamondcameroon.com/gala/reservation" >missdiamondcameroon.com <BsBoxArrowInUpRight /></a></b>  pour la réservation du Ticket de la MDC Fashion and Glamour Gala Night <ImManWoman style={{ color: "#ffbf36" }} /></span> },
    { id: "Etape 5", Description: <span>Sélectionne ta <b>categorie</b>, puis applique la <b>réduction TaptapSend pour recevoir 15€ njooh <ImCool style={{ color: "#ffbf36" }}  /></b>. Taptapsend t’offre 15€ de réduction sur ta réservation de Ticket. N’est-ce pas génial ?</span> },
    { id: "Etape 6", Description: <span>Finaliste ta <a href="https://missdiamondcameroon.com/gala/reservation" >réservation <BsBoxArrowInUpRight /></a> et prépare toi pour l’évènement mode de l’année 🚀.</span> },
    { id: "Et voilà", Description: <span>Tap Tap Send t’a ainsi offert <b>20€ njorrr au total !!! <ImHappy style={{ color: "#ffbf36" }}/></b></span> },
]


class Accordion01 extends Component {
    render() {
        return (
            <Accordion className="accodion-style--1" preExpanded={'0'}>
                {taptapsendEtape.map((etape, index) => (
                    <AccordionItem key={index}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {etape.id} : <BsBoxArrowDownRight />
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>{etape.Description}.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        )
    }
}

export default Accordion01;





