import axios from "axios";
import React, { Component, useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { BsBoxArrowInUpRight, BsCollection } from 'react-icons/bs'
import { FaInfoCircle, FaLayerGroup, FaMoneyBillAlt, FaRegSmileBeam, FaWineBottle } from 'react-icons/fa'
import { ImManWoman } from 'react-icons/im'
import { couponList, Initialboissons, Initialcoktails, listCategoryReservation } from "./GalaData";
import UploadClient from "@uploadcare/upload-client";
import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import { emailValidator, nameValidator, telValidator } from "../../utils/validator";
import uniqid from 'uniqid';
import { PayPalButtons } from '@paypal/react-paypal-js';
import firebase from "../../config/fbConfig";
import { AiFillDelete, AiFillPlusCircle, AiOutlineAim } from "react-icons/ai";
import { FiTarget, FiUsers } from "react-icons/fi";
import { GiPayMoney, GiShatteredGlass, GiWineBottle } from "react-icons/gi";
import { Restaurant } from "@mui/icons-material";
import Summary from "../gala/Summary";
import useSWR from "swr";
import {axiosFetcher} from "../../utils/utills";

const Gala = (props) => {
    const [errForm, setErrForm] = useState([]);
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [tel, setTel] = useState(0);
    const [ville, setVille] = useState("");
    const [message, setMessage] = useState("");
    const [categorieReservation, setCategorieReservation] = useState(1);
    const [totalPrice, setTotalPrice] = useState(1);
    const [maxPerson, setMaxPerson] = useState(1);
    const [applyReducFor, setapplyReducFor] = useState(0);
    const [preuveImg, setPreuveImg] = useState([null, null, null, null, null, null, null, null]);
    const [idReservation, setIdReservation] = useState(uniqid('DIAMOND-'));
    const [showPayment, setShowPayment] = useState(false);
    const [acceptPrivacy, setAcceptPrivacy] = useState(false);
    const [showAddBoisson, setShowAddBoisson] = useState(false);
    const [listBoissons, setListBoissons] = useState([...Initialboissons]);
    const [addedBoissons, setAddedBoissons] = useState([])
    const [coupon, setCoupon] = useState(null)
    const [couponName, setCouponName] = useState("")
    const [CouponStatus, setCouponStatus] = useState("unChecked" || "checkedValid" || "checkedInvalid");
    const [categorieValue, setCategorieValue] = useState({ ...listCategoryReservation[0] });

    const [showAddCocktail, setShowAddCocktail] = useState(false);
    const [listCocktail, setListCoktail] = useState([...Initialcoktails]);
    const [addedCocktails, setAddedCoktails] = useState([]);
    const [addBuffet, setAddBuffet] = useState(0)
    const [multiPlicator, setMultiPlicator] = useState(1)
    const [showResume, setShowResume] = useState(false)

    const { data, isLoading, isError } = useSWR("/gala-info", axiosFetcher);
    console.log(data)

    async function getImageFileObject(imageFile, index) {
        const client = new UploadClient({ publicKey: '8431c83393453c254d9d' });
        if (imageFile.file) {
            let copyPreuve = [...preuveImg];
            copyPreuve[index] = imageFile.file;
            setPreuveImg(copyPreuve);
            let sentFile = await client.uploadFile(imageFile.file);
            copyPreuve = [...preuveImg];
            copyPreuve[index] = sentFile.originalUrl;
            setPreuveImg(copyPreuve);
        }
    }
    async function runAfterImageDelete(file, index) {
        let copyPreuve = [...preuveImg];
        copyPreuve[index] = null;
        setPreuveImg(copyPreuve);
    }
    
    useEffect(() => {
      //   to enable the page to rerender after changes
      if(multiPlicator == 1) setMultiPlicator(11);
      if(multiPlicator == 11) setMultiPlicator(1);
    }, [addedBoissons, categorieReservation, addedCocktails, addBuffet, totalPrice, Name, Email, tel, ville])
    

    useEffect(() => {
        let cat = listCategoryReservation.find(c => c.id_ === (categorieReservation - 1));
        setMaxPerson(cat.maxPerson);
        setCategorieValue(cat);
        if (applyReducFor > cat.maxPerson) setapplyReducFor(cat.maxPerson);
        if(addBuffet > cat.maxPerson) setAddBuffet(cat.maxPerson);
    }, [categorieReservation]);

    useEffect(() => {

    }, [applyReducFor])


    useEffect(() => {
        setTotalPrice(getTotalPrice());
    }, [addedBoissons, categorieReservation, addedCocktails, addBuffet])

    //console.log(totalPrice);
    const sendReservation = async () => {
        setErrForm([]);
        let totalErr = [];
        if (nameValidator(Name)) totalErr.push("Name Invalid");
        if (emailValidator(Email)) totalErr.push("Email Invalid");
        if (telValidator(tel)) totalErr.push("Tel Invalid");
        if (nameValidator(ville)) totalErr.push("City Invalid");
        if (!categorieReservation) totalErr.push("categorie Invalid");
        if (!acceptPrivacy) totalErr.push("Please a accept privacy policy");
        if (applyReducFor > preuveImg.filter(img => img != null).length) totalErr.push("Veuillez ajouter toutes les preuves de reduction!");



        if (totalErr.length > 0) {
            setErrForm(totalErr);
            setShowPayment(false);
            return 0;
        }
        setShowPayment(true);
        await sendformulaire("non payé");
        // const callableOrder = firebase.functions().httpsCallable('handleGalaOrder');
        // const info = getInfo("non payé");
        // const result = await callableOrder({
        //     ...info
        // });
    };

    const getCategorie = () => (listCategoryReservation.find(c => c.id_ === (categorieReservation - 1)))
    const getReduction = () => {
        let cat = getCategorie();
        // return coupon ? cat.coupon : 0;
        return coupon ? cat.Price * 0.2 : 0;
    }
    const getTotalPrice = () => {
        let cat = listCategoryReservation.find(c => c.id_ === (categorieReservation - 1));
        let total = 0;
        total += cat.Price;
        addedBoissons.map(b => total += (b.qty * b.prixUnitaire));
        addedCocktails.map(b => total += (b.qty * b.prixUnitaire));
        total += addBuffet * 12;
        return total;
    }

    const getTapTapReduct = () => (applyReducFor * 15)

    //paypal Payment
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    reference_id: idReservation,
                    description: `Reservation MDC Gala Ticket ${idReservation}`,
                    amount: {
                        value: totalPrice - (applyReducFor * 15) - getReduction(),
                    },
                    payee: {
                        email: Email
                    },
                },
            ]
        });
    }


    const onApprove = async (data, actions) => {
        return actions.order.capture().then(async (details) => {
            await sendformulaire("payé", "L4kvz9lx");
            toast.success(`${Name.split(" ")[0]} Merci pour votre reservation 😉 !`, {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            const callableOrder = firebase.functions().httpsCallable('handleGalaOrder');
            const info = getInfo("payé");
            const result = await callableOrder({
                ...info
            });
        });
    }

    const sendpayerplustard = async () => {
        await sendformulaire("payé plustard");
        toast.success(`${Name.split(" ")[0]} Merci pour votre reservation 😉 !`, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        const callableOrder = firebase.functions().httpsCallable('handleGalaOrder');
        const info = getInfo("payer plustard dans l‘intervalle de 14 jours");
        const result = await callableOrder({
            ...info
        });

        resetData();

    }

    const onCancel = () => {
        toast.warning(`${Name.split(" ")[0]} payement annulé!`, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }

    const onError = async () => {
        await sendformulaire("Erreur de payement");
        toast.error(`${Name.split(" ")[0]} une erreur c'est produite veuillez reessayer!`, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }
    //End Paypal Payment

    const sendformulaire = async (status, id = "W17OLNyi") => {
        const link = "https://submit-form.com/" + id;
        const info = getInfo(status);
        const sentForm = await axios.post(link,
            {
                type: "Reservation Gala",
                ...info
            }
        );

        if(coupon) await axios.post("https://submit-form.com/5H8l7aRK",
            {
                type: "Gala Coupons",
                couponName: coupon?.couponName,
                couponEmail: coupon["for"],
                ...info
            }
        );

        return sentForm;
    }

    const getInfo = (status) => ({
        idreservation: idReservation,
        status: status,
        Name,
        Email,
        tel,
        ville,
        message,
        CategorieReservation: listCategoryReservation.find(c => c.id_ === (categorieReservation - 1)),
        boissons: addedBoissons.filter(b => b.qty > 0),
        //cocktails: addedCocktails.filter(b => b.qty > 0),
        //nombreBuffet: addBuffet,
        totalPerson: maxPerson,
        //NombreDeReduction: applyReducFor,
        //preuveImgDeReduction: preuveImg.filter(img => img != null),
        prixTotal: totalPrice,
        Reduction: coupon ? listCategoryReservation.find(c => c.id_ === (categorieReservation - 1))?.coupon : 0,   //applyReducFor * 15,
        prixTotalAvecReduction: totalPrice - (coupon ? listCategoryReservation.find(c => c.id_ === (categorieReservation - 1))?.coupon : 0),//- (applyReducFor * 15),
        codePromo: coupon
    })

    const resetData = () => {
        setShowPayment(false);
        setIdReservation(uniqid('DIAMOND-'));
        setName("");
        setEmail("");
        setTel(0);

    }


    return (
        <div className="contact-form--1">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-12 order-2 order-lg-1 mb-3">
                        <div className="section-title text-center mb--20">
                            <h2 className="title"><ImManWoman /> {props.contactTitle}. </h2>
                            {/*<p className="description">{data?.Description}</p>*/}
                            <p className="description">Bénéficiez d'une <b>REMISE DE 20%</b> sur tout le site !  <b>PROMO-CODE: MDCTIKTAK</b></p>
                            <p className="description">Enjoy a <b>20% DISCOUNT!</b>! <b>PROMO-CODE: MDCTIKTAK</b></p>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4 mb-3">
                                <img src={data?.PhotoCouverture?.url} style={{ height: "100%", width: "100%" }} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img src={data?.Gallery && data?.Gallery[data.Gallery.length - 2].url} style={{ height: "100%", width: "100%" }} />
                            </div>
                            <div className="col-md-4 d-none d-md-block">
                                <img src={data?.Gallery && data?.Gallery[data.Gallery.length - 1].url} style={{ height: "100%", width: "100%" }} />
                            </div>
                            {/*<div className="col-12 text-center mt-3">
                                <p className="description"> <FaRegSmileBeam /> Obtenez <b>15€</b> de Réduction sur le prix total de reservation plus <b>5€</b> en tant que nouveau utilisateur taptapsend. <a href="/taptapsend" target="_blanc">Savoir plus <BsBoxArrowInUpRight /></a> </p>
                            </div>*/}
                        </div>
                        <div className="row ">
                            <div className="col-12">
                                <h3>Informations: </h3>
                            </div>
                            <div className="col-12">
                                <ul>
                                    <li><b>Date :</b> {new Date(data?.Jour).toLocaleDateString()}</li>
                                    <li><b>Place :</b> {data?.Lieu}</li>
                                    <li><b>Adress :</b> {data?.Adresse}</li>
                                    <li><b>Start :</b> {(data?.debut || "").substring(0,5)}</li>
                                </ul>
                                <p><a href="/gala" target="_blanc">More about MDC Gala <BsBoxArrowInUpRight /></a></p>
                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <h3>Reservation:</h3>

                                <p><b>NB:</b> 1 Whisky est servi avec 1 diluant<br />
                                    <b>NB:</b> 1 whisky is served with a mixer <br/>
                                    {/*<b>NB:</b> FREE BUFFET BETWEEN 7:00 p.m. and 10:00 p.m. <br/>
                                    <b>NB:</b> BUFFET GRATUIT ENTRE 19H00 et 22H00 <br/>*/}
                                    <b>Prevente</b> : 40€ <b>Guichet</b> : 45€</p>
                                <br />
                            </div>
                        </div>
                        <div className="form-wrapper">
                            <div>
                                
                                <p><b> <FiUsers /> General informations:</b><br /></p>
                                <label htmlFor="item01">Full Name *</label>
                                <input type="text" name="name" id="item01" value={Name} onChange={(e) => { setName(e.target.value); }} placeholder="Full Name *" />

                                <label htmlFor="item02">Telefon (Whatsapp) *</label>
                                <input type="number" name="tel" id="item02" value={tel} onChange={(e) => { setTel(e.target.value); }} placeholder="0491586567...." />

                                <label htmlFor="item02">Email *</label>
                                <input type="email" name="email" id="item02" value={Email} onChange={(e) => { setEmail(e.target.value); }} placeholder="youremail@mail.com" />

                                <label htmlFor="item03"> City, Country* </label>
                                <input type="text" name="ville_pays" id="item03" value={ville} onChange={(e) => { setVille(e.target.value); }} placeholder="Frankfurt, Germany" />
                                
                               
                                <div className="for-radio" onChange={(e) => setCategorieReservation(parseInt(e.target.value))}>
                                    
                                    <p><b><BsCollection /> Categorie Reservation:</b><br/>
                                        Get <b>20% DISCOUNT!</b> with <b>PROMO-CODE: MDCTIKTAK</b>!
                                    </p>
                                    <p><b>Table: You are a Star (Standard) :</b></p>
                                    <input type="radio" id="cat1" name="cat" value="1" checked={categorieReservation == 1} />
                                    <label for="cat1" ><b> Diamond Hope - 40€ </b></label>
                                    <ul>
                                        <li>1 individual ticket</li>
                                    </ul>
                                    <input type="radio" id="cat2" name="cat" value="2" checked={categorieReservation == 2} />
                                    <label for="age2"><b> Diamond Hope Plus - 55€ </b></label>
                                    <ul>
                                        <li>1 individual Prestige ticket</li>
                                        <li>a drink</li>
                                    </ul>
                                    <input type="radio" id="cat6" name="cat" value="6" checked={categorieReservation == 6} />
                                    <label for="cat6"><b>Girl Power (Promo) - 335€</b> </label> <br />
                                    <ul>
                                        <li>For women only</li>
                                        <li>5 female guests</li>
                                        <li>1 standard bottle</li>
                                    </ul>
                                    <input type="radio" id="cat7" name="cat" value="7" checked={categorieReservation == 7} />
                                    <label for="age6"><b>Diamond Student - 400€</b></label><br />
                                    <ul>
                                        <li>5 guests</li>
                                        <li>2 standard bottles</li>
                                    </ul>
                                    <input type="radio" id="cat8" name="cat" value="8" checked={categorieReservation == 8} />
                                    <label for="age7"><b> Miss/ Model Fan Club - 800€ </b></label> <br />
                                    <ul>
                                        <li>10 guests</li>
                                        <li>2 standard bottles</li>
                                        <li>1 prestige bottle</li>
                                    </ul>
                                    <p><b>Table : Milenium Luxury Dream:</b></p>
                                    <input type="radio" id="cat3" name="cat" value="9" checked={categorieReservation == 9} />
                                    <label for="cat3"> <b>Good Vibes - 1075€</b>  </label>
                                    <ul>
                                        <li>8 guests</li>
                                        <li>1 standard bottle</li>
                                        <li>2 prestige bottles</li>
                                        <li>1 Baileys</li>
                                    </ul>
                                    <p><b>Table Only One Life (VIP) :</b></p>
                                    <input type="radio" id="cat3" name="cat" value="3" checked={categorieReservation == 3} />
                                    <label for="cat3"> <b>Milenium Star - 1200€</b>  </label>
                                    <ul>
                                        <li>10 guests</li>
                                        <li>2 standard bottles</li>
                                        <li>2 prestige bottles</li>
                                    </ul>
                                    <input type="radio" id="cat4" name="cat" value="4" checked={categorieReservation == 4} />
                                    <label for="cat4"><b> Golden Jubilée  - 535€ </b></label><br />
                                    <ul>
                                        <li>5 guests</li>
                                        <li>1 standard bottle</li>
                                        <li>1 prestige bottle</li>
                                        <li>1 sekt</li>
                                    </ul>
                                    <input type="radio" id="cat5" name="cat" value="5" checked={categorieReservation == 5} />
                                    <label for="cat5"><b> Blue Moon  - 267€</b></label><br />
                                    <ul>
                                        <li>2 guests</li>
                                        <li>1 standard bottle</li>
                                        <li>1 sekt</li>
                                    </ul>
                                </div>
                                

                                <div className="for-radio mb-3">
                                    <p><b><GiWineBottle /> Drink:</b><br />Here you can select what you would like to drink.</p>
                                    <ul>
                                        {addedBoissons.map((boisson, index) => (
                                            <li key={boisson?.id} style={{ color: `${index % 2 == 0 ? "#19175d" : "black"}` }}>
                                                <label htmlFor={boisson?.id}>{boisson?.titre.toUpperCase()} - {boisson.prixUnitaire}€
                                                    <a className="text-danger" href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        let boissonToRemove = addedBoissons.find(b => b?.id == boisson.id);
                                                        console.log(boissonToRemove);
                                                        if (boissonToRemove) {
                                                            setAddedBoissons(addedBoissons.filter(b => b?.id != boisson.id));
                                                            setListBoissons([...listBoissons, boissonToRemove]);
                                                        }
                                                    }}>
                                                        <b><AiFillDelete /></b></a>
                                                </label>
                                                <select name={boisson?.id} id={boisson?.id} onChange={(e) => {
                                                    var newBoissons = addedBoissons.map(b => {
                                                        if (b?.id == boisson?.id) b.qty = parseInt(e.target.value);
                                                        return b;
                                                    });
                                                    setAddedBoissons(newBoissons);
                                                }}>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                </select> <br />
                                                <b>total: <b>{boisson?.prixUnitaire * boisson?.qty}</b> €</b> <br /><br />
                                            </li>
                                        ))}
                                    </ul>
                                    {showAddBoisson && <><label htmlFor="nouvelle-boisson">
                                        New Drink:
                                        <select onChange={(e) => {
                                            let boissonToAdd = listBoissons.find(b => b?.id == e.target.value);
                                            if (boissonToAdd) {
                                                setShowAddBoisson(false);
                                                setListBoissons(listBoissons.filter(b => b?.id != e.target.value));
                                                setAddedBoissons([...addedBoissons, boissonToAdd]);
                                            }
                                        }}>
                                            <option>--choose drink--</option>
                                            {listBoissons.map(boisson => (
                                                <option key={boisson?.id} value={boisson?.id}>{boisson?.titre.toUpperCase()} - {boisson.prixUnitaire}€</option>
                                            ))}
                                        </select>
                                    </label><br /><br /></>}
                                    <button className="btn btn-primary btn-lg" onClick={() => setShowAddBoisson(true)} ><AiFillPlusCircle /> Add Drink</button>

                                </div>
                                {/*<div className="for-radio mb-3">
                                    <p><b><GiShatteredGlass /> COCKTAILS:</b><br /> Ici vous pouvez sélectionner vos cocktails. </p>
                                    <ul>
                                        {addedCocktails.map((boisson, index) => (
                                            <li key={boisson?.id} style={{ color: `${index % 2 == 0 ? "#19175d" : "black"}` }}>
                                                <label htmlFor={boisson?.id}>{boisson?.titre} - {boisson.prixUnitaire}€
                                                    <a className="text-danger" href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        let boissonToRemove = addedCocktails.find(b => b?.id == boisson.id);
                                                        if (boissonToRemove) {
                                                            setAddedCoktails(addedCocktails.filter(b => b?.id != boisson.id));
                                                            setListCoktail([...listCocktail, boissonToRemove]);
                                                        }
                                                    }}>
                                                        <b><AiFillDelete /></b></a>
                                                </label>
                                                <select name={boisson?.id} id={boisson?.id} onChange={(e) => {
                                                    var newBoissons = addedCocktails.map(b => {
                                                        if (b?.id == boisson?.id) b.qty = parseInt(e.target.value);
                                                        return b;
                                                    });
                                                    setAddedCoktails(newBoissons);
                                                }}>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                </select> <br />
                                                <b>total: <b>{boisson?.prixUnitaire * boisson?.qty}</b>€</b> <br /><br />
                                            </li>
                                        ))}
                                    </ul>
                                    {showAddCocktail && <><label htmlFor="nouvelle-boisson">
                                        Cocktails Classique:
                                        <select onChange={(e) => {
                                            let boissonToAdd = listCocktail.find(b => b?.id == e.target.value);
                                            if (boissonToAdd) {
                                                setShowAddCocktail(false);
                                                setListCoktail(listCocktail.filter(b => b?.id != e.target.value));
                                                setAddedCoktails([...addedCocktails, boissonToAdd]);
                                            }
                                        }}>
                                            <option>--choisir le cocktail--</option>
                                            {listCocktail.filter(c => c.type == "classic").map(boisson => (
                                                <option key={boisson?.id} value={boisson?.id}>{boisson?.titre} - {boisson.prixUnitaire}€</option>
                                            ))}
                                        </select>
                                    </label></>}

                                    {showAddCocktail && <><label htmlFor="nouvelle-boisson">
                                        Cocktails sans alcool:
                                        <select onChange={(e) => {
                                            let boissonToAdd = listCocktail.find(b => b?.id == e.target.value);
                                            if (boissonToAdd) {
                                                setShowAddCocktail(false);
                                                setListCoktail(listCocktail.filter(b => b?.id != e.target.value));
                                                setAddedCoktails([...addedCocktails, boissonToAdd]);
                                            }
                                        }}>
                                            <option>--choisir le cocktail--</option>
                                            {listCocktail.filter(c => c.type != "classic").map(boisson => (
                                                <option key={boisson?.id} value={boisson?.id}>{boisson?.titre} - {boisson.prixUnitaire}€</option>
                                            ))}
                                        </select>
                                    </label><br /><br /></>}
                                    <button className="btn btn-success btn-lg" onClick={() => setShowAddCocktail(true)} ><AiFillPlusCircle /> Ajouter un Cocktail</button>

                                </div>*/}

                               {/* <div className="for-radio mb-3">
                                    <p><b><Restaurant /> Buffet:</b><br /> ajoutez le buffet aux saveurs variées à <b>12€ par personne</b>. </p>
                                    <label htmlFor="buffet">Ajouter le Buffet pour:</label>
                                    <select name="buffet" value={addBuffet} onChange={e => setAddBuffet(parseInt(e.target.value))}>
                                        {[...Array(maxPerson + 1)].map((x, i) => (
                                            <option key={i} value={i}>{i} Personne{i > 1 && 's'} ({i * 12}€)</option>
                                        ))}
                                    </select> <br />
                                    <b>Au menu:</b>
                                    <ul>
                                        <li>
                                            <span>PREMIÈRE PARTIE : <b>Delices KMER</b></span>
                                            <ul>
                                                <li>Brochettes miondo </li> <li>brochettes de plantain </li> <li>bar frit en Petit morceaux</li><li>Brochettes de crevettes</li><li>brochettes de gesier</li>
                                                <li>brochettes de porc </li><li> brochettes de boeuf</li><li>Nems à la viande hachee</li><li>Sauce Verte Piment</li> <li>porc braisé</li> <li>Et bien plus</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>DEUXIÈME PARTIE : <b>M'licious</b></span>
                                            <ul>
                                                <li>
                                                    Salés
                                                    <ul>
                                                        <li>Mini brochettes de plantain,boulettes et fromage </li> <li>Mini brochettes de manioc,boulettes et légumes </li> <li>Samousa au bœuf et au poulet </li><li>Verrines de crème fraîche au légumes et crevettes</li><li>Et bien plus</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                Sucrées
                                                    <ul>
                                                        <li>Dessertcups: Parfum Spéculoos et parfum Lemon curd </li> <li>Crêpes </li> <li>Et bien plus</li>
                                                    </ul>
                                                </li>

                                            </ul>
                                        </li>

                                    </ul>
                                    <b>Total Buffet: 12€ * <b>{addBuffet}</b> pers = <b>{addBuffet * 12}</b>€</b>
                                </div>*/}

                    


                                <div className="mb-3 for-radio">
                                    {/*<p><b><FaMoneyBillAlt /> CODE PROMO: MDCTIKTAK</b> <br />*/}
                                    <p>
                                        Apply a promo code to get <b>-<b>{categorieValue.coupon}</b>€</b> off your <b>{categorieValue.name}</b> reservation:
                                    </p>
                                    <div>
                                        {CouponStatus == "checkedValid" && <>
                                            <span><b>Code promo: </b>{couponName} (-{categorieValue.coupon}€)</span>
                                            <a href="#" className="text-danger" onClick={(e) => {
                                                e.preventDefault();
                                                setCoupon(null);
                                                setCouponStatus("unChecked");
                                             }}>
                                                <b><AiFillDelete /></b>
                                            </a><br />
                                        </>}

                                        {CouponStatus == "checkedInvalid" && <>
                                            <small className="text-danger">this coupon is invalid please try another coupon</small><br />
                                        </>}

                                        {CouponStatus !== "checkedValid" && <>
                                            <input type="text" name="coupon" value={couponName.toUpperCase()} onChange={(e) => { setCouponName(e.target.value.toUpperCase()); }} placeholder="Coupon" />
                                            <button className="btn btn-primary btn-lg"
                                                onClick={() => {
                                                    let findCoupon = couponList.find(c => c.couponName == couponName);
                                                    if (findCoupon) {
                                                        setCoupon(findCoupon);
                                                        setCouponStatus("checkedValid")
                                                    } else {
                                                        setCouponStatus("checkedInvalid")
                                                    }
                                                }} >
                                                <GiPayMoney /> Apply Reduction
                                            </button>
                                        </>}


                                    </div>
                                </div>

                                <label htmlFor="item04">
                                    Message (facultativ)
                                </label>
                                <textarea
                                    type="text"
                                    id="item04"
                                    name="message"
                                    value={message}
                                    onChange={(e) => { setMessage(e.target.value); }}
                                    placeholder="Is there anything you would like us to know ?"
                                />

                                {/*<div className="mb-3 for-radio">

                                    <h3><input type="checkbox" /> Appliquer la reduction Taptap Send (<a href="/taptapsend" target="_blanc">Savoir plus<BsBoxArrowInUpRight /></a>)</h3>
                                    <ul>
                                        <li>Appliquez la réduction <b>TapTap Send</b> pour avoir un Rabais exclusif sur votre réservation de Ticket / Nombre de places limité*</li>
                                        <li>Confirmer votre Reduction Taptapsend, en téléchargeant une capture d’écran de votre transfert d’argent avec le code <b>MISSDIAMOND</b> sur l’application Taptapsend *</li>
                                        <li>Uniquement valable pour tous les nouveaux utilisateurs taptapsend</li>
                                    </ul>
                                    <label htmlFor="taptapsendreduct">Appliquez la Reduction Taptap Send pour:</label>
                                    <select name="taptapsendreduct" value={applyReducFor} onChange={e => setapplyReducFor(parseInt(e.target.value))}>
                                        {[...Array(maxPerson + 1)].map((x, i) => (
                                            <option key={i} value={i}>{i} Personne{i > 1 && 's'} (-{i * 15}€)</option>
                                        ))}
                                    </select> <br />
                                    {applyReducFor > 0 && <div className="row">
                                        <div className="col-12">
                                            <p>Ajouter les preuves pour chaque personne *:<br></br> <small>NB: une preuve non valide ne sera pas considérée</small></p>
                                        </div>

                                        {[...Array(applyReducFor)].map((x, i) => (
                                            <div key={i} className="col-3 col-md-2 col-lg-1 mr-4">
                                                <small>Person {i + 1}</small>
                                                <ImageUploader
                                                    onFileAdded={(img) => getImageFileObject(img, i)}
                                                    onFileRemoved={(img) => runAfterImageDelete(img, i)}
                                                />
                                            </div>
                                        ))}
                                    </div>}



                                </div>*/}

                                {showResume && <button className="rn-button-style--2 btn-solid mr-2" onClick={() => setShowResume(!showResume)} id="mc-embedded-subscribe">Hide the summary</button>}
                                {!showResume && <button className="rn-button-style--2 btn-solid mr-2" onClick={() => setShowResume(!showResume)} id="mc-embedded-subscribe">Show the summary</button>}

                                {showResume && <>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Total:
                                            </td>
                                            <td>
                                                <p><span>{getTotalPrice()*(multiPlicator%10)}</span> €</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Code promo:
                                            </td>
                                            <td>
                                                -<span>{getReduction()}</span>€
                                            </td>
                                        </tr>
                                        {/*<tr>
                                            <td>
                                                Reduction Taptap Send:
                                            </td>
                                            <td>
                                                -<span>{getTapTapReduct()}</span>€
                                            </td>
                                        </tr>*/}
                                        <tr>
                                            <td>
                                                Total final:
                                            </td>
                                            <td>
                                                <p><b>{totalPrice - (applyReducFor * 15) - getReduction() + (multiPlicator%10)-1}</b><b>€</b></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <ul>
                                        {errForm.map((err, index) => (
                                            <li key={index} className="text-danger">{err}</li>
                                        ))}
                                    </ul>
                                </div>
                                {!showPayment && <p className="for-radio"><input type="checkbox" value={acceptPrivacy} onChange={e => setAcceptPrivacy(!acceptPrivacy)} /> I have read and I accept the <a href="/privacy">terms of use and privacy.</a></p>}
                                {showPayment && (
                                    <>
                                        <PayPalButtons
                                            createOrder={(data, actions) => createOrder(data, actions)}
                                            onApprove={(data, action) => onApprove(data, action)}
                                            onCancel={(data, action) => onCancel()}
                                            onError={(err) => onError()}
                                            forceReRender={[totalPrice, applyReducFor, Email, idReservation]}
                                        />
                                    </>
                                )}

                                {!showPayment && <button className="rn-button-style--2 btn-solid mr-2" onClick={() => sendReservation()} id="mc-embedded-subscribe">Purchase Reservation</button>}
                                </>}

                                <p> <FaInfoCircle /> Veux tu réserver et payer par Virement Bancaire , bien vouloir nous contacter au <a href="tel:+49 176 8759 1662">+49 176 8759 1662</a>  / <a href="tel:+49 176 607 283 68">+49 176 607 283 68</a> / <a href="tel:+49 1522 617 38 41">+49 1522 617 38 41</a> ou sur <a href="mailto:gala@missdiamond-africa.com">gala@missdiamond-africa.com</a>  ou sur Instagram <a href="https://www.instagram.com/miss_diamond_africa">@miss_diamond_africa</a>, Merci. </p>
                                <p> <FaInfoCircle /> Do you want to make a reservation and payment via bank transfer, please contact us at <a href="tel:+49 176 8759 1662">+49 176 8759 1662</a>  / <a href="tel:+49 176 607 283 68">+49 176 607 283 68</a> / <a href="tel:+49 1522 617 38 41">+49 1522 617 38 41</a> or via <a href="mailto:gala@missdiamond-africa.com">gala@missdiamond-africa.com</a>  or via Instagram <a href="https://www.instagram.com/miss_diamond_africa">@miss_diamond_africa</a>, Thank you. </p>

                            </div>

                        </div>
                    </div>
                    {/* <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail mb_md--30 mb_sm--30">
                            <img src={`${props.contactImages}`} alt="trydo" />
                        </div>
                    </div> */}
                </div>
            </div>
            <ToastContainer />
        </div>
    )

}
export default Gala;
