import React, { Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../../components/common/Helmet";
import Header from "../../components/header/header";
import About from "../../components/about/About";
import AboutContext from "../../components/general/AboutContext";
import ServiceTwo from "../../components/service/ServiceTwo";
import Portfolio from "../../components/portfolio/Portfolio";
import CounterOne from "../../components/counter/CounterOne";
import Testimonial from "../../components/testimonial/Testimonial";
import BrandTwo from "../../components/brand/BrandTwo";
import Footer from "../../components/footer/footer";
import { Parallax, Background } from "react-parallax";
import { VideoTag } from "react-video-tag";
import TopPartHome from "../../components/blocs/TopPartHome";
import { AiOutlineSketch } from "react-icons/ai";
import BlogHome from "../../components/blocs/BlogHome";
import { axiosFetcher } from "../../utils/utills";
import useSWR from "swr";


const MainDemo = (props) => {
    const { data, isLoading, isError } = useSWR("/homepage", axiosFetcher);

    return (
        <Fragment>
            <PageHelmet pageTitle="Home" />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Slider Area   */}
            <div className="slider-wrapper">
                {/* Start Single Slide */}
                <Parallax strength={700} className="home-paralax" >
                    <Background className="custom-paralax" >
                        {/* <div className="d-none d-sm-block">
                            <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`} src={`${"/assets/video/home/top_model.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} className="myVideo" />
                        </div> */}
                        <div>
                            <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`} src={`${data?.bgVideo?.url || "/assets/video/home/top_model.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} className="myVideo" />
                        </div>
                    </Background>
                    <TopPartHome />
                </Parallax>
            </div>
            {/* End Slider Area   */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--80 bg_color--1">
                    <AboutContext />
                </div>
                {/* End About Area  */}

            {/* Start About Area */}
            <div className="about-area about-position-top pb--120">
                <About />
            </div>
            {/* End About Area */}



            <div className="service-area ptb--80  bg_image bg_image--3">
                <div className="container">
                    <ServiceTwo />
                </div>
            </div>

            {/* Start Portfolio Area */}
            <div className="portfolio-area ptb--120 bg_color--1">
                <div className="portfolio-sacousel-inner mb--55">
                    <Portfolio />
                </div>
            </div>
            {/* End Portfolio Area */}


            {/* Start CounterUp Area */}
            <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h3 className="fontWeight500"><AiOutlineSketch style={{ color: "#ffbf36" }} /> Palmares <AiOutlineSketch style={{ color: "#ffbf36" }} /></h3>
                            </div>
                        </div>
                    </div>
                    <CounterOne />
                </div>
            </div>
            {/* End CounterUp Area */}

            {/* Start Testimonial Area */}
            <div className="rn-testimonial-area bg_color--5 ptb--120">
                <div className="container">
                    <Testimonial />
                </div>
            </div>
            {/* End Testimonial Area */}

            {/* Start Blog Area */}
            <BlogHome />
            {/* End Blog Area */}


            {/* Start Brand Area */}
            <BrandTwo />
            {/* End Brand Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
        </Fragment>
    );
}



export default MainDemo;