import React from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../../components/common/Helmet";
import Breadcrumb from "../../components/common/Breadcrumb";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import BLogList from "../../components/blog/BlogList";
import { FaHome, FaPaypal } from "react-icons/fa";
import InfoDonate from "../../components/about/InfoDonate";
import LottieAnimation from "../../components/common/Lottie";
import LottieThanks from "../../components/common/lottieJson/LottieThanks.json";
import { Link } from "react-router-dom";

const DonateComplete = () => {
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Thank you' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* Start Blog Area */}
            <div className="rn-blog-area pt--125 bg_color--1">
                <div className="container  text-center">
                 <p className="lead"> Thank you For your Donation 😊 we will do the right think with it! </p>
                 <LottieAnimation lotti={LottieThanks} width={300} height={300}  />
                 <br/>
                 <Link to="/"><button className="btn btn-donate rn-btn">Go back Home <FaHome className="lead"/></button></Link>
                </div>
            </div>
            {/* End Blog Area */}

            
            {/* Start Blog Area */}
            <div className="rn-blog-area ptb--120 bg_color--1">
                <div className="container">
                    <InfoDonate/>
                </div>
            </div>
            {/* End Blog Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}
export default DonateComplete;
