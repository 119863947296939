import React, { useState, } from 'react'
import ModalVideo from 'react-modal-video';
import { AiOutlineSketch } from "react-icons/ai";
import { gql, useQuery } from "@apollo/client";
import { Link } from 'react-router-dom';

const APROPOS = gql`
    query GetApropos {
        homepage {
            vidId
        }
    }
`

function TopPartHome() {
    const { loading, error, data } = useQuery(APROPOS);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="slide slide-style-2 slider-paralax slider-video-parallax d-flex align-items-center justify-content-center">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8">
                        <div className="inner text-left">
                            <h1 className="title">Miss Di<AiOutlineSketch style={{ color: "#ffbf36" }} />mond Africa</h1>
                            <br />
                            <h4 className="subtitle">"The Diamond is <strong>YOU</strong>... " </h4>
                            {/*<Link className="rn-button-style--2 btn-solid mr-3 mb-3" to="/inscription/candidate">*/}
                            {/*    <span>REGISTER TODAY ! </span>*/}
                            {/*</Link>*/}
                            
                            <Link className="rn-button-style--2 btn-solid mr-3 mb-3" to="/gala/reservation">
                                <span>Gala reservation </span>
                            </Link>
                            <Link className="rn-button-style--2 btn-solid mr-3 mb-3" to="/vote">
                                <span>Miss Vote</span>
                            </Link>
                            <Link className="rn-button-style--2 btn-solid mb-3" to="/awardvote">
                                <span>Award Vote</span>
                            </Link>
                            {/*<br />*/}
                            {/*<Link className="rn-button-style--2  mr-3 mb-3" to="/inscription/collaboration">*/}
                            {/*    <span>Become Sponsor/Partner </span>*/}
                            {/*</Link>*/}
                            
                            <br />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="video-inner">
                            <ModalVideo channel='youtube' isOpen={isOpen} videoId={data?.homepage?.vidId || 'LG1fVlTuzhU'} onClose={() => setIsOpen(false)} />
                            <button className="video-popup theme-color" onClick={() => setIsOpen(true)}><span className="play-icon"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopPartHome
