export const categorie = [
  {
    id:"artistemusicien",
    title: "BEST MUSICIAN ARTIST"
  },
  {
    id:"youtubeur",
    title:"BEST CONTENT CREATOR"
  },
  {
    id:"webemission",
    title:"BEST INSTAGRAM DIGITAL CREATOR"
  },
    {
        id:"webcomedien",
        title: "BEST TIKTOK ENTERTAINER"
    },
    {
        id: "blogvlog",
        title: "BEST FASHION BLOGGER"
    },
];

export const awardcandidats = [
  {
    "categorie": "artistemusicien",
    "_id": "6358e2f646647700181e9a2f",
    "name": "Bovann",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur Artiste Musicien est nominé @bovann1\n\nIn the category Best Artist musician is nominated @bovann1\n\n#missdiamondcameroon",
    "missId": 11,
    "published_at": "2022-10-26T07:34:18.366Z",
    "createdAt": "2022-10-26T07:34:14.697Z",
    "updatedAt": "2022-10-26T07:34:18.383Z",
    "__v": 0,
    "img": {
      "_id": "6358e2cf46647700181e9a2e",
      "name": "unknown_312800844_664014458420366_8169133430119891184_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312800844_664014458420366_8169133430119891184_n_a27875339f",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 134.12,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312800844_664014458420366_8169133430119891184_n_a27875339f.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312800844_664014458420366_8169133430119891184_n.jpg",
          "hash": "thumbnail_unknown_312800844_664014458420366_8169133430119891184_n_a27875339f",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 8.01,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312800844_664014458420366_8169133430119891184_n_a27875339f.jpg"
        },
        "large": {
          "name": "large_unknown_312800844_664014458420366_8169133430119891184_n.jpg",
          "hash": "large_unknown_312800844_664014458420366_8169133430119891184_n_a27875339f",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 120.07,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312800844_664014458420366_8169133430119891184_n_a27875339f.jpg"
        },
        "medium": {
          "name": "medium_unknown_312800844_664014458420366_8169133430119891184_n.jpg",
          "hash": "medium_unknown_312800844_664014458420366_8169133430119891184_n_a27875339f",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 78.3,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312800844_664014458420366_8169133430119891184_n_a27875339f.jpg"
        },
        "small": {
          "name": "small_unknown_312800844_664014458420366_8169133430119891184_n.jpg",
          "hash": "small_unknown_312800844_664014458420366_8169133430119891184_n_a27875339f",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 40.28,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312800844_664014458420366_8169133430119891184_n_a27875339f.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e2f646647700181e9a2f"
      ],
      "createdAt": "2022-10-26T07:33:35.659Z",
      "updatedAt": "2022-10-26T07:34:14.725Z",
      "__v": 0,
      "id": "6358e2cf46647700181e9a2e"
    },
    "id": "6358e2f646647700181e9a2f"
  },
  {
    "categorie": "artistemusicien",
    "_id": "6358e33846647700181e9a32",
    "name": "Sean Breezy",
    "missId": 12,
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur Artiste Musicien est nominé @seanb_official\n\nIn the category Best Artist musician is nominated @seanb_official\n\n#missdiamondcameroon",
    "published_at": "2022-10-26T07:35:32.134Z",
    "createdAt": "2022-10-26T07:35:20.100Z",
    "updatedAt": "2022-10-26T07:35:32.149Z",
    "__v": 0,
    "img": {
      "_id": "6358e30d46647700181e9a31",
      "name": "unknown_312688096_778094143288599_7010081814743819601_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312688096_778094143288599_7010081814743819601_n_3117a077d9",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 139.77,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312688096_778094143288599_7010081814743819601_n_3117a077d9.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312688096_778094143288599_7010081814743819601_n.jpg",
          "hash": "thumbnail_unknown_312688096_778094143288599_7010081814743819601_n_3117a077d9",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 8.12,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312688096_778094143288599_7010081814743819601_n_3117a077d9.jpg"
        },
        "large": {
          "name": "large_unknown_312688096_778094143288599_7010081814743819601_n.jpg",
          "hash": "large_unknown_312688096_778094143288599_7010081814743819601_n_3117a077d9",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 125,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312688096_778094143288599_7010081814743819601_n_3117a077d9.jpg"
        },
        "medium": {
          "name": "medium_unknown_312688096_778094143288599_7010081814743819601_n.jpg",
          "hash": "medium_unknown_312688096_778094143288599_7010081814743819601_n_3117a077d9",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 81.96,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312688096_778094143288599_7010081814743819601_n_3117a077d9.jpg"
        },
        "small": {
          "name": "small_unknown_312688096_778094143288599_7010081814743819601_n.jpg",
          "hash": "small_unknown_312688096_778094143288599_7010081814743819601_n_3117a077d9",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 41.86,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312688096_778094143288599_7010081814743819601_n_3117a077d9.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e33846647700181e9a32"
      ],
      "createdAt": "2022-10-26T07:34:37.520Z",
      "updatedAt": "2022-10-26T07:35:20.112Z",
      "__v": 0,
      "id": "6358e30d46647700181e9a31"
    },
    "id": "6358e33846647700181e9a32"
  },
  {
    "categorie": "artistemusicien",
    "_id": "6358e36946647700181e9a35",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur Artiste Musicien est nominé @diese_mbangue\n\nIn the category Best Artist musician is nominated @diese_mbangue\n\n#missdiamondcameroon",
    "name": "Diese Mbangue",
    "published_at": "2022-10-26T07:36:31.538Z",
    "createdAt": "2022-10-26T07:36:09.483Z",
    "updatedAt": "2022-10-26T07:56:12.519Z",
    "__v": 0,
    "img": {
      "_id": "6358e34f46647700181e9a34",
      "name": "unknown_312505352_1266421320821816_1936685830814697970_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312505352_1266421320821816_1936685830814697970_n_5d4322b3c7",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 144.98,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312505352_1266421320821816_1936685830814697970_n_5d4322b3c7.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312505352_1266421320821816_1936685830814697970_n.jpg",
          "hash": "thumbnail_unknown_312505352_1266421320821816_1936685830814697970_n_5d4322b3c7",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 7.99,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312505352_1266421320821816_1936685830814697970_n_5d4322b3c7.jpg"
        },
        "large": {
          "name": "large_unknown_312505352_1266421320821816_1936685830814697970_n.jpg",
          "hash": "large_unknown_312505352_1266421320821816_1936685830814697970_n_5d4322b3c7",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 129.1,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312505352_1266421320821816_1936685830814697970_n_5d4322b3c7.jpg"
        },
        "medium": {
          "name": "medium_unknown_312505352_1266421320821816_1936685830814697970_n.jpg",
          "hash": "medium_unknown_312505352_1266421320821816_1936685830814697970_n_5d4322b3c7",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 83.39,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312505352_1266421320821816_1936685830814697970_n_5d4322b3c7.jpg"
        },
        "small": {
          "name": "small_unknown_312505352_1266421320821816_1936685830814697970_n.jpg",
          "hash": "small_unknown_312505352_1266421320821816_1936685830814697970_n_5d4322b3c7",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 42.45,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312505352_1266421320821816_1936685830814697970_n_5d4322b3c7.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e36946647700181e9a35"
      ],
      "createdAt": "2022-10-26T07:35:43.767Z",
      "updatedAt": "2022-10-26T07:36:09.494Z",
      "__v": 0,
      "id": "6358e34f46647700181e9a34"
    },
    "missId": 13,
    "id": "6358e36946647700181e9a35"
  },
  {
    "categorie": "artistemusicien",
    "_id": "6358e39d46647700181e9a38",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur Artiste Musicien est nominé @austeenoff\n\nIn the category Best Artist musician is nominated @austeenoff\n\n#missdiamondcameroon",
    "name": "Austeen Off",
    "published_at": "2022-10-26T07:37:03.594Z",
    "createdAt": "2022-10-26T07:37:01.937Z",
    "updatedAt": "2022-10-26T07:56:38.661Z",
    "__v": 0,
    "img": {
      "_id": "6358e38846647700181e9a37",
      "name": "unknown_312466247_638124521052986_2946454819850811088_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312466247_638124521052986_2946454819850811088_n_ca517682f4",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 126.95,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312466247_638124521052986_2946454819850811088_n_ca517682f4.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312466247_638124521052986_2946454819850811088_n.jpg",
          "hash": "thumbnail_unknown_312466247_638124521052986_2946454819850811088_n_ca517682f4",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 7.8,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312466247_638124521052986_2946454819850811088_n_ca517682f4.jpg"
        },
        "large": {
          "name": "large_unknown_312466247_638124521052986_2946454819850811088_n.jpg",
          "hash": "large_unknown_312466247_638124521052986_2946454819850811088_n_ca517682f4",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 113.96,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312466247_638124521052986_2946454819850811088_n_ca517682f4.jpg"
        },
        "medium": {
          "name": "medium_unknown_312466247_638124521052986_2946454819850811088_n.jpg",
          "hash": "medium_unknown_312466247_638124521052986_2946454819850811088_n_ca517682f4",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 74.79,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312466247_638124521052986_2946454819850811088_n_ca517682f4.jpg"
        },
        "small": {
          "name": "small_unknown_312466247_638124521052986_2946454819850811088_n.jpg",
          "hash": "small_unknown_312466247_638124521052986_2946454819850811088_n_ca517682f4",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 39.14,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312466247_638124521052986_2946454819850811088_n_ca517682f4.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e39d46647700181e9a38"
      ],
      "createdAt": "2022-10-26T07:36:40.333Z",
      "updatedAt": "2022-10-26T07:37:01.953Z",
      "__v": 0,
      "id": "6358e38846647700181e9a37"
    },
    "missId": 14,
    "id": "6358e39d46647700181e9a38"
  },
  {
    "categorie": "artistemusicien",
    "_id": "6358e3d346647700181e9a3b",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur Artiste Musicien est nominé @koernoar\n\nIn the category Best Artist musician is nominated @koernoar\n\n#missdiamondcameroon",
    "name": "Körnoar",
    "published_at": "2022-10-26T07:38:18.726Z",
    "createdAt": "2022-10-26T07:37:55.256Z",
    "updatedAt": "2022-10-26T07:56:50.454Z",
    "__v": 0,
    "img": {
      "_id": "6358e3ba46647700181e9a3a",
      "name": "unknown_312742991_512268970496491_2271736990438176776_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312742991_512268970496491_2271736990438176776_n_818268346a",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 137.96,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312742991_512268970496491_2271736990438176776_n_818268346a.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312742991_512268970496491_2271736990438176776_n.jpg",
          "hash": "thumbnail_unknown_312742991_512268970496491_2271736990438176776_n_818268346a",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 8.1,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312742991_512268970496491_2271736990438176776_n_818268346a.jpg"
        },
        "large": {
          "name": "large_unknown_312742991_512268970496491_2271736990438176776_n.jpg",
          "hash": "large_unknown_312742991_512268970496491_2271736990438176776_n_818268346a",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 123.54,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312742991_512268970496491_2271736990438176776_n_818268346a.jpg"
        },
        "medium": {
          "name": "medium_unknown_312742991_512268970496491_2271736990438176776_n.jpg",
          "hash": "medium_unknown_312742991_512268970496491_2271736990438176776_n_818268346a",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 80.88,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312742991_512268970496491_2271736990438176776_n_818268346a.jpg"
        },
        "small": {
          "name": "small_unknown_312742991_512268970496491_2271736990438176776_n.jpg",
          "hash": "small_unknown_312742991_512268970496491_2271736990438176776_n_818268346a",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 41.66,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312742991_512268970496491_2271736990438176776_n_818268346a.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e3d346647700181e9a3b"
      ],
      "createdAt": "2022-10-26T07:37:30.247Z",
      "updatedAt": "2022-10-26T07:37:55.271Z",
      "__v": 0,
      "id": "6358e3ba46647700181e9a3a"
    },
    "missId": 15,
    "id": "6358e3d346647700181e9a3b"
  },
  {
    "categorie": "webcomedien",
    "_id": "6358e42046647700181e9a3e",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur créateur(trice) de contenu est nominé @ngoko_tbl\n\nIn the category Best content creator is nominated @ngoko_tbl\n\n#missdiamondcameroon",
    "name": "Ngoko Tbl",
    "missId": 21,
    "published_at": "2022-10-26T07:39:16.437Z",
    "createdAt": "2022-10-26T07:39:12.588Z",
    "updatedAt": "2022-10-26T07:39:16.466Z",
    "__v": 0,
    "img": {
      "_id": "6358e40046647700181e9a3d",
      "name": "unknown_313014289_1050900145592190_2245201946077600342_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_313014289_1050900145592190_2245201946077600342_n_af31b29938",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 126.07,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_313014289_1050900145592190_2245201946077600342_n_af31b29938.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_313014289_1050900145592190_2245201946077600342_n.jpg",
          "hash": "thumbnail_unknown_313014289_1050900145592190_2245201946077600342_n_af31b29938",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 7.93,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_313014289_1050900145592190_2245201946077600342_n_af31b29938.jpg"
        },
        "large": {
          "name": "large_unknown_313014289_1050900145592190_2245201946077600342_n.jpg",
          "hash": "large_unknown_313014289_1050900145592190_2245201946077600342_n_af31b29938",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 112.94,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_313014289_1050900145592190_2245201946077600342_n_af31b29938.jpg"
        },
        "medium": {
          "name": "medium_unknown_313014289_1050900145592190_2245201946077600342_n.jpg",
          "hash": "medium_unknown_313014289_1050900145592190_2245201946077600342_n_af31b29938",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 75.13,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_313014289_1050900145592190_2245201946077600342_n_af31b29938.jpg"
        },
        "small": {
          "name": "small_unknown_313014289_1050900145592190_2245201946077600342_n.jpg",
          "hash": "small_unknown_313014289_1050900145592190_2245201946077600342_n_af31b29938",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 39.15,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_313014289_1050900145592190_2245201946077600342_n_af31b29938.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e42046647700181e9a3e"
      ],
      "createdAt": "2022-10-26T07:38:40.691Z",
      "updatedAt": "2022-10-26T07:39:12.605Z",
      "__v": 0,
      "id": "6358e40046647700181e9a3d"
    },
    "id": "6358e42046647700181e9a3e"
  },
  {
    "categorie": "webcomedien",
    "_id": "6358e482247936001808a629",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur créateur(trice) de contenu est nominé @kamerlife__\n\nIn the category Best content creator is nominated @kamerlife__\n\n#missdiamondcameroon\n",
    "name": "Kamerlife",
    "published_at": "2022-10-26T07:42:40.985Z",
    "createdAt": "2022-10-26T07:40:50.515Z",
    "updatedAt": "2022-10-26T07:42:41.008Z",
    "__v": 0,
    "img": {
      "_id": "6358e468247936001808a628",
      "name": "unknown_312599041_3384130398574760_8742754197504499318_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312599041_3384130398574760_8742754197504499318_n_cbfd636e06",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 131.53,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312599041_3384130398574760_8742754197504499318_n_cbfd636e06.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312599041_3384130398574760_8742754197504499318_n.jpg",
          "hash": "thumbnail_unknown_312599041_3384130398574760_8742754197504499318_n_cbfd636e06",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 7.69,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312599041_3384130398574760_8742754197504499318_n_cbfd636e06.jpg"
        },
        "large": {
          "name": "large_unknown_312599041_3384130398574760_8742754197504499318_n.jpg",
          "hash": "large_unknown_312599041_3384130398574760_8742754197504499318_n_cbfd636e06",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 117.09,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312599041_3384130398574760_8742754197504499318_n_cbfd636e06.jpg"
        },
        "medium": {
          "name": "medium_unknown_312599041_3384130398574760_8742754197504499318_n.jpg",
          "hash": "medium_unknown_312599041_3384130398574760_8742754197504499318_n_cbfd636e06",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 75.93,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312599041_3384130398574760_8742754197504499318_n_cbfd636e06.jpg"
        },
        "small": {
          "name": "small_unknown_312599041_3384130398574760_8742754197504499318_n.jpg",
          "hash": "small_unknown_312599041_3384130398574760_8742754197504499318_n_cbfd636e06",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 38.86,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312599041_3384130398574760_8742754197504499318_n_cbfd636e06.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e482247936001808a629"
      ],
      "createdAt": "2022-10-26T07:40:24.957Z",
      "updatedAt": "2022-10-26T07:40:50.542Z",
      "__v": 0,
      "id": "6358e468247936001808a628"
    },
    "missId": 22,
    "id": "6358e482247936001808a629"
  },
  {
    "categorie": "webcomedien",
    "_id": "6358e4c7247936001808a62c",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur créateur(trice) de contenu est nominé @bovann1\n\nIn the category Best content creator is nominated @bovann1\n\n#missdiamondcameroon",
    "name": "Bovann",
    "instagramname": "",
    "published_at": "2022-10-26T07:42:36.548Z",
    "createdAt": "2022-10-26T07:41:59.080Z",
    "updatedAt": "2022-10-26T22:45:25.233Z",
    "__v": 0,
    "img": {
      "_id": "6358e4a8247936001808a62b",
      "name": "unknown_312898976_617108213486065_6413643549613996772_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312898976_617108213486065_6413643549613996772_n_51da582ca8",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 124.62,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312898976_617108213486065_6413643549613996772_n_51da582ca8.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312898976_617108213486065_6413643549613996772_n.jpg",
          "hash": "thumbnail_unknown_312898976_617108213486065_6413643549613996772_n_51da582ca8",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 7.68,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312898976_617108213486065_6413643549613996772_n_51da582ca8.jpg"
        },
        "large": {
          "name": "large_unknown_312898976_617108213486065_6413643549613996772_n.jpg",
          "hash": "large_unknown_312898976_617108213486065_6413643549613996772_n_51da582ca8",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 110.9,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312898976_617108213486065_6413643549613996772_n_51da582ca8.jpg"
        },
        "medium": {
          "name": "medium_unknown_312898976_617108213486065_6413643549613996772_n.jpg",
          "hash": "medium_unknown_312898976_617108213486065_6413643549613996772_n_51da582ca8",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 72.53,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312898976_617108213486065_6413643549613996772_n_51da582ca8.jpg"
        },
        "small": {
          "name": "small_unknown_312898976_617108213486065_6413643549613996772_n.jpg",
          "hash": "small_unknown_312898976_617108213486065_6413643549613996772_n_51da582ca8",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 37.5,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312898976_617108213486065_6413643549613996772_n_51da582ca8.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e4c7247936001808a62c"
      ],
      "createdAt": "2022-10-26T07:41:28.209Z",
      "updatedAt": "2022-10-26T07:41:59.100Z",
      "__v": 0,
      "id": "6358e4a8247936001808a62b"
    },
    "missId": 23,
    "id": "6358e4c7247936001808a62c"
  },
  {
    "categorie": "webcomedien",
    "_id": "6358e516247936001808a62f",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleure créatrice de contenu est nominée @manouchka237\n\nIn the category Best content creator is nominated @manouchka237 😫\n\n#missdiamondcameroon",
    "name": "Manouchka",
    "missId": 24,
    "published_at": "2022-10-26T07:43:22.541Z",
    "createdAt": "2022-10-26T07:43:18.917Z",
    "updatedAt": "2022-10-26T07:43:22.561Z",
    "__v": 0,
    "img": {
      "_id": "6358e4fb247936001808a62e",
      "name": "unknown_312897982_820416742437975_3924765972846287605_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312897982_820416742437975_3924765972846287605_n_f39b472db4",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 145.93,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312897982_820416742437975_3924765972846287605_n_f39b472db4.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312897982_820416742437975_3924765972846287605_n.jpg",
          "hash": "thumbnail_unknown_312897982_820416742437975_3924765972846287605_n_f39b472db4",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 8.33,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312897982_820416742437975_3924765972846287605_n_f39b472db4.jpg"
        },
        "large": {
          "name": "large_unknown_312897982_820416742437975_3924765972846287605_n.jpg",
          "hash": "large_unknown_312897982_820416742437975_3924765972846287605_n_f39b472db4",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 130.22,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312897982_820416742437975_3924765972846287605_n_f39b472db4.jpg"
        },
        "medium": {
          "name": "medium_unknown_312897982_820416742437975_3924765972846287605_n.jpg",
          "hash": "medium_unknown_312897982_820416742437975_3924765972846287605_n_f39b472db4",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 83.89,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312897982_820416742437975_3924765972846287605_n_f39b472db4.jpg"
        },
        "small": {
          "name": "small_unknown_312897982_820416742437975_3924765972846287605_n.jpg",
          "hash": "small_unknown_312897982_820416742437975_3924765972846287605_n_f39b472db4",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 42.85,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312897982_820416742437975_3924765972846287605_n_f39b472db4.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e516247936001808a62f"
      ],
      "createdAt": "2022-10-26T07:42:51.526Z",
      "updatedAt": "2022-10-26T07:43:18.931Z",
      "__v": 0,
      "id": "6358e4fb247936001808a62e"
    },
    "id": "6358e516247936001808a62f"
  },
  {
    "categorie": "webcomedien",
    "_id": "6358e55d247936001808a632",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur créateur(trice) de contenu est nominé @elysee_le_snieper\n\nIn the category Best content creator is nominated @elysee_le_snieper\n\n#missdiamondcameroon",
    "name": "Elysee le Snieper",
    "missId": 25,
    "published_at": "2022-10-26T07:44:32.617Z",
    "createdAt": "2022-10-26T07:44:29.514Z",
    "updatedAt": "2022-10-26T07:44:32.637Z",
    "__v": 0,
    "img": {
      "_id": "6358e530247936001808a631",
      "name": "unknown_312528791_135595582587847_7637190794392162885_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312528791_135595582587847_7637190794392162885_n_792038a196",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 148.86,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312528791_135595582587847_7637190794392162885_n_792038a196.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312528791_135595582587847_7637190794392162885_n.jpg",
          "hash": "thumbnail_unknown_312528791_135595582587847_7637190794392162885_n_792038a196",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 8.47,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312528791_135595582587847_7637190794392162885_n_792038a196.jpg"
        },
        "large": {
          "name": "large_unknown_312528791_135595582587847_7637190794392162885_n.jpg",
          "hash": "large_unknown_312528791_135595582587847_7637190794392162885_n_792038a196",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 133.78,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312528791_135595582587847_7637190794392162885_n_792038a196.jpg"
        },
        "medium": {
          "name": "medium_unknown_312528791_135595582587847_7637190794392162885_n.jpg",
          "hash": "medium_unknown_312528791_135595582587847_7637190794392162885_n_792038a196",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 87.61,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312528791_135595582587847_7637190794392162885_n_792038a196.jpg"
        },
        "small": {
          "name": "small_unknown_312528791_135595582587847_7637190794392162885_n.jpg",
          "hash": "small_unknown_312528791_135595582587847_7637190794392162885_n_792038a196",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 45.42,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312528791_135595582587847_7637190794392162885_n_792038a196.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e55d247936001808a632"
      ],
      "createdAt": "2022-10-26T07:43:44.074Z",
      "updatedAt": "2022-10-26T07:44:29.525Z",
      "__v": 0,
      "id": "6358e530247936001808a631"
    },
    "id": "6358e55d247936001808a632"
  },
  {
    "categorie": "webemission",
    "_id": "6358e5d7247936001808a635",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleure Émission Web est nominé « Parles nous de ton Business en 5 Minutes» présentée par @cbqueeens\n\nIn the category Best Web Show is nominated « Parles nous de ton Business en 5 Minutes» presented by @cbqueeens\n\n#missdiamondcameroon",
    "name": "Parles nous de ton Business en 5 Minutes",
    "missId": 31,
    "published_at": "2022-10-26T07:46:34.437Z",
    "createdAt": "2022-10-26T07:46:31.803Z",
    "updatedAt": "2022-10-26T07:48:32.357Z",
    "__v": 0,
    "img": {
      "_id": "6358e5b0247936001808a634",
      "name": "unknown_309461624_172175348806692_1262954608304286094_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_309461624_172175348806692_1262954608304286094_n_367353d4be",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 153.55,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_309461624_172175348806692_1262954608304286094_n_367353d4be.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_309461624_172175348806692_1262954608304286094_n.jpg",
          "hash": "thumbnail_unknown_309461624_172175348806692_1262954608304286094_n_367353d4be",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 8.57,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_309461624_172175348806692_1262954608304286094_n_367353d4be.jpg"
        },
        "large": {
          "name": "large_unknown_309461624_172175348806692_1262954608304286094_n.jpg",
          "hash": "large_unknown_309461624_172175348806692_1262954608304286094_n_367353d4be",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 137.28,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_309461624_172175348806692_1262954608304286094_n_367353d4be.jpg"
        },
        "medium": {
          "name": "medium_unknown_309461624_172175348806692_1262954608304286094_n.jpg",
          "hash": "medium_unknown_309461624_172175348806692_1262954608304286094_n_367353d4be",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 89.68,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_309461624_172175348806692_1262954608304286094_n_367353d4be.jpg"
        },
        "small": {
          "name": "small_unknown_309461624_172175348806692_1262954608304286094_n.jpg",
          "hash": "small_unknown_309461624_172175348806692_1262954608304286094_n_367353d4be",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 45.88,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_309461624_172175348806692_1262954608304286094_n_367353d4be.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e5d7247936001808a635"
      ],
      "createdAt": "2022-10-26T07:45:52.738Z",
      "updatedAt": "2022-10-26T07:46:31.816Z",
      "__v": 0,
      "id": "6358e5b0247936001808a634"
    },
    "id": "6358e5d7247936001808a635"
  },
  {
    "categorie": "webemission",
    "_id": "6358e632247936001808a638",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleure Émission Web est nominé @drey.side présentée par @audreysyntiche\n\nIn the category Best Web Show is nominated @drey.side presented by @audreysyntiche\n\n#missdiamondcameroon",
    "name": "Drey Side",
    "missId": 32,
    "published_at": "2022-10-26T07:48:05.417Z",
    "createdAt": "2022-10-26T07:48:02.070Z",
    "updatedAt": "2022-10-26T07:48:05.445Z",
    "__v": 0,
    "img": {
      "_id": "6358e603247936001808a637",
      "name": "unknown_312785035_134861879083662_8504958958051778039_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312785035_134861879083662_8504958958051778039_n_0569e5b668",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 139.93,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312785035_134861879083662_8504958958051778039_n_0569e5b668.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312785035_134861879083662_8504958958051778039_n.jpg",
          "hash": "thumbnail_unknown_312785035_134861879083662_8504958958051778039_n_0569e5b668",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 8.47,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312785035_134861879083662_8504958958051778039_n_0569e5b668.jpg"
        },
        "large": {
          "name": "large_unknown_312785035_134861879083662_8504958958051778039_n.jpg",
          "hash": "large_unknown_312785035_134861879083662_8504958958051778039_n_0569e5b668",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 125.45,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312785035_134861879083662_8504958958051778039_n_0569e5b668.jpg"
        },
        "medium": {
          "name": "medium_unknown_312785035_134861879083662_8504958958051778039_n.jpg",
          "hash": "medium_unknown_312785035_134861879083662_8504958958051778039_n_0569e5b668",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 83.16,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312785035_134861879083662_8504958958051778039_n_0569e5b668.jpg"
        },
        "small": {
          "name": "small_unknown_312785035_134861879083662_8504958958051778039_n.jpg",
          "hash": "small_unknown_312785035_134861879083662_8504958958051778039_n_0569e5b668",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 43.25,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312785035_134861879083662_8504958958051778039_n_0569e5b668.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e632247936001808a638"
      ],
      "createdAt": "2022-10-26T07:47:15.857Z",
      "updatedAt": "2022-10-26T07:48:02.084Z",
      "__v": 0,
      "id": "6358e603247936001808a637"
    },
    "id": "6358e632247936001808a638"
  },
  {
    "categorie": "webemission",
    "_id": "6358e69e247936001808a63b",
    "missId": 33,
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleure Émission Web est nominé « Comment je fais? » présentée par @kevino_le_sapologue sur la page @kamerdiaspo\n\nIn the category Best Web Show is nominated « Comment Je Fais? » presented by @kevino_le_sapologue on @kamerdiaspo\n\n#missdiamondcameroon",
    "name": "Comment je fais?",
    "published_at": "2022-10-26T07:49:52.626Z",
    "createdAt": "2022-10-26T07:49:50.722Z",
    "updatedAt": "2022-10-26T07:49:52.655Z",
    "__v": 0,
    "img": {
      "_id": "6358e664247936001808a63a",
      "name": "unknown_312559449_5637300952983495_8929292124724413462_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312559449_5637300952983495_8929292124724413462_n_252559d12a",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 146.16,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312559449_5637300952983495_8929292124724413462_n_252559d12a.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312559449_5637300952983495_8929292124724413462_n.jpg",
          "hash": "thumbnail_unknown_312559449_5637300952983495_8929292124724413462_n_252559d12a",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 8.62,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312559449_5637300952983495_8929292124724413462_n_252559d12a.jpg"
        },
        "large": {
          "name": "large_unknown_312559449_5637300952983495_8929292124724413462_n.jpg",
          "hash": "large_unknown_312559449_5637300952983495_8929292124724413462_n_252559d12a",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 131.3,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312559449_5637300952983495_8929292124724413462_n_252559d12a.jpg"
        },
        "medium": {
          "name": "medium_unknown_312559449_5637300952983495_8929292124724413462_n.jpg",
          "hash": "medium_unknown_312559449_5637300952983495_8929292124724413462_n_252559d12a",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 86.81,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312559449_5637300952983495_8929292124724413462_n_252559d12a.jpg"
        },
        "small": {
          "name": "small_unknown_312559449_5637300952983495_8929292124724413462_n.jpg",
          "hash": "small_unknown_312559449_5637300952983495_8929292124724413462_n_252559d12a",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 45.11,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312559449_5637300952983495_8929292124724413462_n_252559d12a.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e69e247936001808a63b"
      ],
      "createdAt": "2022-10-26T07:48:52.440Z",
      "updatedAt": "2022-10-26T07:49:50.733Z",
      "__v": 0,
      "id": "6358e664247936001808a63a"
    },
    "id": "6358e69e247936001808a63b"
  },
  {
    "categorie": "youtubeur",
    "_id": "6358e6f8247936001808a63e",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur(e) Youtubeur(euse) est nominé @loik_lunik avec sa chaîne YouTube “Médecine Simpliste”\n\nIn the category Best YouTuber is nominated @loik_lunik with his YouTube Chanel “Médecine Simpliste”\n\n#missdiamondcameroon",
    "name": "Médecine Simpliste",
    "missId": 41,
    "published_at": "2022-10-26T07:51:22.779Z",
    "createdAt": "2022-10-26T07:51:20.025Z",
    "updatedAt": "2022-10-26T07:51:22.819Z",
    "__v": 0,
    "img": {
      "_id": "6358e6d5247936001808a63d",
      "name": "unknown_312926948_3337854226533071_2329452190233262075_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312926948_3337854226533071_2329452190233262075_n_2b1e1ec13d",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 131.53,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312926948_3337854226533071_2329452190233262075_n_2b1e1ec13d.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312926948_3337854226533071_2329452190233262075_n.jpg",
          "hash": "thumbnail_unknown_312926948_3337854226533071_2329452190233262075_n_2b1e1ec13d",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 7.87,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312926948_3337854226533071_2329452190233262075_n_2b1e1ec13d.jpg"
        },
        "large": {
          "name": "large_unknown_312926948_3337854226533071_2329452190233262075_n.jpg",
          "hash": "large_unknown_312926948_3337854226533071_2329452190233262075_n_2b1e1ec13d",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 117.42,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312926948_3337854226533071_2329452190233262075_n_2b1e1ec13d.jpg"
        },
        "medium": {
          "name": "medium_unknown_312926948_3337854226533071_2329452190233262075_n.jpg",
          "hash": "medium_unknown_312926948_3337854226533071_2329452190233262075_n_2b1e1ec13d",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 77.44,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312926948_3337854226533071_2329452190233262075_n_2b1e1ec13d.jpg"
        },
        "small": {
          "name": "small_unknown_312926948_3337854226533071_2329452190233262075_n.jpg",
          "hash": "small_unknown_312926948_3337854226533071_2329452190233262075_n_2b1e1ec13d",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 40.91,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312926948_3337854226533071_2329452190233262075_n_2b1e1ec13d.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e6f8247936001808a63e"
      ],
      "createdAt": "2022-10-26T07:50:45.635Z",
      "updatedAt": "2022-10-26T07:51:20.039Z",
      "__v": 0,
      "id": "6358e6d5247936001808a63d"
    },
    "id": "6358e6f8247936001808a63e"
  },
  {
    "categorie": "youtubeur",
    "_id": "6358e735247936001808a641",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur(e) Youtubeur(euse) est nominé @reussirenallemagne\n\nIn the category Best YouTuber is nominated @reussirenallemagne\n\n#missdiamondcameroon",
    "name": "Réussir en Allemagne",
    "missId": 42,
    "published_at": "2022-10-26T07:52:29.629Z",
    "createdAt": "2022-10-26T07:52:21.636Z",
    "updatedAt": "2022-10-26T07:52:29.666Z",
    "__v": 0,
    "img": {
      "_id": "6358e71d247936001808a640",
      "name": "unknown_312504845_660268935674528_6316764717387169959_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312504845_660268935674528_6316764717387169959_n_dcb3a6ac7b",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 132.17,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312504845_660268935674528_6316764717387169959_n_dcb3a6ac7b.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312504845_660268935674528_6316764717387169959_n.jpg",
          "hash": "thumbnail_unknown_312504845_660268935674528_6316764717387169959_n_dcb3a6ac7b",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 8.07,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312504845_660268935674528_6316764717387169959_n_dcb3a6ac7b.jpg"
        },
        "large": {
          "name": "large_unknown_312504845_660268935674528_6316764717387169959_n.jpg",
          "hash": "large_unknown_312504845_660268935674528_6316764717387169959_n_dcb3a6ac7b",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 118,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312504845_660268935674528_6316764717387169959_n_dcb3a6ac7b.jpg"
        },
        "medium": {
          "name": "medium_unknown_312504845_660268935674528_6316764717387169959_n.jpg",
          "hash": "medium_unknown_312504845_660268935674528_6316764717387169959_n_dcb3a6ac7b",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 77.47,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312504845_660268935674528_6316764717387169959_n_dcb3a6ac7b.jpg"
        },
        "small": {
          "name": "small_unknown_312504845_660268935674528_6316764717387169959_n.jpg",
          "hash": "small_unknown_312504845_660268935674528_6316764717387169959_n_dcb3a6ac7b",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 40.88,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312504845_660268935674528_6316764717387169959_n_dcb3a6ac7b.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e735247936001808a641"
      ],
      "createdAt": "2022-10-26T07:51:57.326Z",
      "updatedAt": "2022-10-26T07:52:21.684Z",
      "__v": 0,
      "id": "6358e71d247936001808a640"
    },
    "id": "6358e735247936001808a641"
  },
  {
    "categorie": "youtubeur",
    "_id": "6358e7c4247936001808a644",
    "description": "Diamond Awards Acte 2 🏆💎\nDans la catégorie meilleur(e) Youtubeur(euse) est nominé @selena_dialo avec sa chaîne YouTube “Connaître l’Allemagne”\n\nIn the category Best YouTuber is nominated @selena_dialo with her YouTube Chanel “Connaître l’Allemagne”\n\n#missdiamondcameroon",
    "name": "Connaître l’Allemagne",
    "missId": 43,
    "published_at": "2022-10-26T07:54:46.217Z",
    "createdAt": "2022-10-26T07:54:44.237Z",
    "updatedAt": "2022-10-26T07:54:46.235Z",
    "__v": 0,
    "img": {
      "_id": "6358e7a7247936001808a643",
      "name": "unknown_312642061_637736641343869_87089867032712087_n.jpg",
      "alternativeText": "",
      "caption": "",
      "hash": "unknown_312642061_637736641343869_87089867032712087_n_13ba7bfc90",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 142.97,
      "width": 1080,
      "height": 977,
      "url": "https://storage.googleapis.com/mdc-beta.appspot.com/unknown_312642061_637736641343869_87089867032712087_n_13ba7bfc90.jpg",
      "formats": {
        "thumbnail": {
          "name": "thumbnail_unknown_312642061_637736641343869_87089867032712087_n.jpg",
          "hash": "thumbnail_unknown_312642061_637736641343869_87089867032712087_n_13ba7bfc90",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 172,
          "height": 156,
          "size": 8.21,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/thumbnail_unknown_312642061_637736641343869_87089867032712087_n_13ba7bfc90.jpg"
        },
        "large": {
          "name": "large_unknown_312642061_637736641343869_87089867032712087_n.jpg",
          "hash": "large_unknown_312642061_637736641343869_87089867032712087_n_13ba7bfc90",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 1000,
          "height": 905,
          "size": 127.66,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/large_unknown_312642061_637736641343869_87089867032712087_n_13ba7bfc90.jpg"
        },
        "medium": {
          "name": "medium_unknown_312642061_637736641343869_87089867032712087_n.jpg",
          "hash": "medium_unknown_312642061_637736641343869_87089867032712087_n_13ba7bfc90",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 750,
          "height": 678,
          "size": 83.07,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/medium_unknown_312642061_637736641343869_87089867032712087_n_13ba7bfc90.jpg"
        },
        "small": {
          "name": "small_unknown_312642061_637736641343869_87089867032712087_n.jpg",
          "hash": "small_unknown_312642061_637736641343869_87089867032712087_n_13ba7bfc90",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "width": 500,
          "height": 452,
          "size": 43.05,
          "path": null,
          "url": "https://storage.googleapis.com/mdc-beta.appspot.com/small_unknown_312642061_637736641343869_87089867032712087_n_13ba7bfc90.jpg"
        }
      },
      "provider": "to-firebase",
      "related": [
        "6358e7c4247936001808a644"
      ],
      "createdAt": "2022-10-26T07:54:15.421Z",
      "updatedAt": "2022-10-26T07:54:44.285Z",
      "__v": 0,
      "id": "6358e7a7247936001808a643"
    },
    "id": "6358e7c4247936001808a644"
  }
]