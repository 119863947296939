import React from "react";
import { IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { ImYahoo2 } from "react-icons/im";

const SignIn = ({firebase, setloggin}) => {
  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().currentUser.linkWithPopup(provider);
    setloggin(true);
  }
  const signInWithYahoo = async () => {
    const provider = new firebase.auth.OAuthProvider('yahoo.com');
    await firebase.auth().currentUser.linkWithPopup(provider);
    setloggin(true);
  }

  return (
    <>
    <Typography align="center">Connect With your
        <IconButton className="sign-in" onClick={signInWithGoogle}> <FcGoogle />oogle</IconButton> or
        <IconButton className="sign-in" onClick={signInWithYahoo}> <ImYahoo2 color='blue' />ahoo</IconButton>Account to start chating !
    </Typography>
      
      <Typography align="center"><small><i>Do not violate the community guidelines or you will be banned for life!</i></small></Typography>
    </>
  )

}

export default SignIn
