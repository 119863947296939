import React, { Component, useState } from "react";
import UploadClient from "@uploadcare/upload-client";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegistrationAsInvestor = (props) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [instagram, setInstagram] = useState("");
    const [tel, setTel] = useState("");
    const [comment , setComment] = useState("");
    const [amount, setAmount] = useState(null);
    const [profil, setProfil] = useState();
    const [typeCollabo, setTypeCollabo] = useState("sponsor");

    const onFileChange = async (e) => {
        setProfil(e.target.files[0])
    }

    const send = async () => {
        const link = props.type === "sponsor" ? "https://submit-form.com/B7JFIqUj" : "https://submit-form.com/E6vUy0Iw";

        if (name.trim() === "") {
            toast.error('Please enter "Name" 😕!', {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        if (email.trim() === "" && tel.trim() === "" && instagram.trim() === "") {
            toast.error('Please enter Email or Insta or Tel 😕!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        if (!profil && props.type !== "sponsor") {
            toast.error('Image is missing 😕!', {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }


        toast.info('Sending Inscription!', {
            position: "top-right",
            autoClose: 2300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        var sentFile;

        if (!profil) {
            sentFile = { originalUrl: "" }
        }
        else {
            const client = new UploadClient({ publicKey: '8431c83393453c254d9d' });
            sentFile = await client.uploadFile(profil);
        }

        let dataToSend = {
            Categorie: props.contactTitle,
            Nom: name,
            Email: email,
            instagram: instagram,
            tel: tel,
            profil: sentFile?.originalUrl
        }

        if(props.type === "sponsor") dataToSend.typeCollaboration = typeCollabo;

        const sentForm = await axios.post(link, dataToSend);

        if (sentForm.status === 200) {
            toast.success(`Thank you ${name.split(" ")[0]} for your subscribtion 😉 !`, {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            setEmail("");
            setInstagram("");
            setName("");
            setTel("");

        } else {
            toast.success(`An error occured 😯 please try again or contact us. `, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }


    return (
        <div className="contact-form--1">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="section-title text-left mb--50">
                            <h2 className="title">{props.contactTitle}</h2>
                            <p className="description">Be part of the adventure MDC 2022.</p>
                        </div>
                        <div className="form-wrapper">
                            <div>
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={name}
                                        onChange={(e) => { setName(e.target.value) }}
                                        placeholder={`Nom(s) et Prenom(s)*`}
                                    />
                                </label>

                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item02"
                                        value={email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        placeholder="email: you@gmail.com *"
                                    />
                                </label>

                                <label htmlFor="item05">
                                    <input
                                        type="text"
                                        name="insta"
                                        id="item05"
                                        value={instagram}
                                        onChange={(e) => { setInstagram(e.target.value) }}
                                        placeholder="instagram: @instaname"
                                    />
                                </label>

                                <label htmlFor="item03">
                                    <input
                                        type="tel"
                                        name="tel"
                                        id="item03"
                                        value={tel}
                                        onChange={(e) => { setTel(e.target.value); }}
                                        placeholder="tel: 015788390"
                                    />
                                </label>

                                {(props.type === "investor") && <><label htmlFor="investissement">Type d'investissement:</label>
                                <select name="investissement" id="investissement" onChange={(e) => setTypeCollabo(e.target.value)}>
                                    <option value=">Investisseur Simple">Investisseur Simple</option>
                                    <option value="Investisseur et Partenaire">Investisseur et Partenaire</option>
                                    <option value="Partenaire">Partenaire</option>
                                </select> <br /></>}<br />

                                <label htmlFor="item07">
                                    <input
                                        type="number"
                                        name="amount"
                                        id="item07"
                                        value={amount}
                                        onChange={(e) => { setAmount(e.target.value); }}
                                        placeholder="Montant d'investissement en Euro"
                                    />
                                </label>
                                <label htmlFor="item09">
                                    <textarea
                                        type="text"
                                        name="comment"
                                        id="item09"
                                        value={comment}
                                        onChange={(e) => { setComment(e.target.value); }}
                                        placeholder="Ecrivez un commentaire à propos de votre investissement"
                                    ></textarea>
                                </label>

{/* 
                                <label htmlFor="item04" className="mb-1">
                                    {props.type === "sponsor" ? "Logo Image :" : "profil picture :"}
                                </label>
                                {profil && <img width="120" src={profil ? URL.createObjectURL(profil) : ""} />}
                                <input
                                    type="file"
                                    name="profil"
                                    id="item04"
                                    onChange={onFileChange}
                                    className="p-0"
                                    accept="image/png, image/jpeg, image/jpg"
                                /> */}

                                <button onClick={() => send()} className="rn-button-style--2 btn-solid" id="mc-embedded-subscribe">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail mb_md--30 mb_sm--30">
                            <img src={`${props.contactImages}`} alt="Miss Diamond Contact" />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
export default RegistrationAsInvestor;