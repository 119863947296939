import React from 'react'
import StatisticsAward from './statisticsAward'

const  StatAward = props => {
    return (
        <div>
                    { props.awardCategorie.map((cat, key)=> (
                        <StatisticsAward key={key} categorie={cat} allCandidate={props.allCandidate} votes={props.voteawards}/>
                    ))
                        
                    }
        </div>
    )
}

export default StatAward
