import * as React from 'react';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { connect } from 'react-redux';
import { addChoice, addPaidChoice } from '../../actions/voteAction';
import { compose } from 'redux';
import Login from '../login';
import PayChoice from './pay/PayChoice';
import { Link } from 'react-router-dom';
import GalleryDynamic from '../gallery/GalleryDynamic';
import { FiExternalLink } from 'react-icons/fi';
import CardWrapper from "./CardWrapper";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const MissCard = (props) => {

  const [vote, setVote] = React.useState(0);
  const [paidVote, setPaidVote] = React.useState(0);
  const [userChoice, setUserchoice] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { miss, votes, authReducer, paidChoices } = props;


  React.useEffect(() => {

    if (paidChoices) {
      let missPaidVote = paidChoices
                          ?.filter(vote => vote.missId === miss.missId)
                          ?.reduce((accumulator, currentValue) => accumulator + currentValue.votes, 0)
      setPaidVote(missPaidVote);
    }

    if (votes) {
      let missVote = votes.filter(vote => vote.missId === miss.missId).length;

      //let missVote = props.voteReducer.find(vote => vote.missId == miss.missId).vote;
      setVote(missVote);
      ////console.log(props?.firebase);
      if (!authReducer.auth.isEmpty) {
        ////console.log(authReducer)
        let chosenMiss = votes.find(vote => vote.id === authReducer.auth.uid)?.missId || 0;
        setUserchoice(chosenMiss)
      }
    }
    return () => { }
  }, [paidChoices, votes, authReducer])

  return (
        <CardWrapper img={miss.img.url} name={miss.name} id={miss.missId} instagramname={miss.instagramname} facebooklink={miss.facebooklink} >
            <CardContent>
              <Typography variant="body1" color="text.secondary">
                {vote + paidVote} Votes
              </Typography>
              <br/>
              {
                  !authReducer.auth.isEmpty &&
                  <>
                    <IconButton color='secondary' onClick={() => props.addChoice({ missId: miss.missId })}>
                      vote {userChoice == miss.missId ? <AiFillHeart /> : <AiOutlineHeart />}
                    </IconButton> <br/>
                  </>
              }

              {authReducer.auth.isEmpty || userChoice == 0 ? '' :
                  <PayChoice missId={miss.missId} addPaidChoice={(paidChoice) => props.addPaidChoice(paidChoice)} />
              }


              {
                  authReducer.auth.isEmpty &&
                  <div onClick={()=>{window.scrollTo(0,0)}}><Typography variant="subtitle1" color="secondary"><Login /> To Vote </Typography></div>
              }
            </CardContent>
            <CardActions >
              <GalleryDynamic items = {miss.gallery.map(img => ({id:img._id, src:img.url, thumb:img.formats.thumbnail.url || img.url}))} />
              <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
              >
                About Me <ExpandMoreIcon />
              </ExpandMore>

            </CardActions>

          {/*<Typography variant="h5" sx={{px: 2}}>
            <Link to="/gala/reservation" > 💎 More about Me </Link>
          </Typography>*/}
          <Typography variant="h5" sx={{p: 2}}>
            <Link to="/gala/reservation" > ❤️ My fan CLub Gala </Link>
          </Typography>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>{miss.name}:</Typography>
                <Typography paragraph>
                  {miss.description}
                </Typography>
              </CardContent>
            </Collapse>
        </CardWrapper>
  );
}

const mapStateToProps = (state) => {
  ////console.log(state);
  return {
    authReducer: state.firebaseReducer
  }
}

export default compose(
  connect(mapStateToProps, { addChoice, addPaidChoice })
)(MissCard)
