import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { Link, useParams } from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import PageHelmet from "../../components/common/Helmet";
import { GiQueenCrown } from "react-icons/gi";
import { gql, useQuery } from '@apollo/client';



const MISS = gql`
query getMiss($annee:String!) {
    misses(where:{annee:{annee:$annee}}){
      nom,
      biographie,
      sideImage {
          url
      }, 
      profilImage {
          url
      }
      annee {
        annee
      }
    }
  }
`
const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Miss Diamond Africa',
        title: 'Kitio',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
const MissPortfolio = () => {
    const { year } = useParams();
    const { loading, error, data } = useQuery(MISS, {
        variables: { annee: year }
    });

    let miss = data?.misses[0];

    miss = { category: 'Miss Diamond Africa', title: miss?.nom || 'Kitio', year: year, description: miss?.biographie || "", profilImage: miss?.profilImage?.url || "", sideImage: miss?.sideImage?.url || "" }

   ////console.log(miss);


    let title = 'About Me',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered <a href="#">alteration</a> in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,';
    return (
        <Fragment>
            <PageHelmet pageTitle="Personal Portfolio" />

            <Header logo="symbol-dark" color="color-black" />

            {/* Start Slider Area   */}
            <div className="slider-wrapper">
                {/* Start Single Slide */}

                <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image" style={{backgroundImage:`url("${miss.sideImage}")`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={`inner text-left`}>
                                    <span className="text-white	">{miss.category}</span>
                                    <h1 className="title text-white">Hello, I’m <span>{miss.title}<br /><GiQueenCrown /></span> Miss Diamond Africa <span>{miss.year}.</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Single Slide */}
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div className="about-area about-position-top pb--120  bg_color--1">
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src={miss?.profilImage} alt="About Images" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-inner inner pt--100">
                                    <div className="section-title">
                                        <Link className="title">{miss.description}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area */}



            <Footer />

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </Fragment>
    )

}

export default MissPortfolio;