import { Box, Grid, Link, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect, withFirestore } from 'react-redux-firebase'
import { compose } from 'redux'
import MissCard from './missCard'
import ApexChart from './statistics2'
import TabPanel from './TabPanel'
import Vote from './Vote'
import ChatRoom from './chat/chat'
import { FiExternalLink } from 'react-icons/fi'
import { GiPerson } from 'react-icons/gi'
import { AiFillHeart, AiOutlineHeart, AiOutlineUser } from 'react-icons/ai'
import { FaUser } from 'react-icons/fa'
import VoteAward from './VoteAward'
import StatAward from './StatAward'
import {loadAward} from "../../actions/awardAction";


const MainVote2 = (props) => {
    const [allMisses, setAllMisses] = useState([])
    const [allCandidate, setallCandidate] = useState([]);
    const [awardCategorie, setAwardCategorie] = useState([])
    const [screen, setValue] = React.useState(0);

    const status = props.status || [] ;

    const handleChange = (event, newScreen) => {
        setValue(newScreen);
    };

    useEffect(() => {
        //console.log(props)
        //setAllMisses(props.missReducer);
        setAwardCategorie(props.categorieAwardReducer);
        setallCandidate(props.awardReducer);
        return () => {
        }
    });

    useEffect(() => {
        props.loadAward();
    }, [])

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            {status[0]?.vor === true ? <Typography className=' pt--50' variant='h4' align='center'>Les Votes commencent bientôt! <br/><br/> Votes are starting soon! <br/><br/></Typography>:''}
            <Typography color='secondary' align='center'>One Person<AiOutlineUser/> equal one Free vote<AiOutlineHeart/>.</Typography>
            <Typography color='primary' align='center'>Do you like the project ? <Link href='https://www.paypal.com/donate?hosted_button_id=QGTARQXYEPYWE'>Donate <FiExternalLink/></Link></Typography>
            {status[0]?.during === true || status[0]?.after === true  || status[0]?.pause === true ? 
            <>
            <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
            >
                <Grid item >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={screen} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Vote" {...a11yProps(0)} />
                            <Tab label="Statistics" {...a11yProps(1)} />
                            <Tab label="Chat" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                </Grid>
            </Grid>
            <TabPanel value={screen} index={0}>
                {
                    status[0]?.pause === true ?
                    <Typography className=' pt--50' variant='h4' align='center'>
                        Les Votes continuent dans un Instant !  <br/> Votes will continue soon !
                    </Typography> : ''
                }
                {
                    status[0]?.after === true ?
                    <Typography className=' pt--50' variant='h4' align='center'>
                        Fin! Merci pour Votre Participation. <br/> End! Thanks for your participation.
                    </Typography> : ''
                }
                {
                    status[0]?.during === true ?
                    <VoteAward allCandidate={allCandidate} awardCategorie={awardCategorie} voteawards={props.voteawards} />
                    : ''
                }
                
            </TabPanel>
            <TabPanel value={screen} index={1}>
                {
                    status[0]?.pause === true && status?.block == true ?
                    <Typography className=' pt--50' variant='h4' align='center'>
                        Les Votes continuent dans un Instant !  <br/> Votes will continue soon !
                    </Typography> : ''
                }
                {
                    status[0]?.after === true &&  status?.block == true?
                    <Typography className=' pt--50' variant='h4' align='center'>
                        Fin! Merci pour Votre Participation ! <br/> End! Thanks for your participation!
                    </Typography> : ''
                }
                {
                    status[0]?.block === false ?

                    <StatAward allCandidate={allCandidate} awardCategorie={awardCategorie} voteawards={props.voteawards} />
                    : ''
                }
            </TabPanel>
            <TabPanel value={screen} index={2}>
                <ChatRoom />
            </TabPanel>
            </> : ''
            }
        </>
    )
}

const mapStateToProps = (state) => {

    return {
        voteawards: state.firestoreReducer.ordered.userawardchoice,
        status: state.firestoreReducer.ordered.statusaward,
        //vor, during, pause, after, block
        awardReducer: state.awardReducer,
        categorieAwardReducer: state.categorieAwardReducer,
    };
}

export default compose(
    connect(mapStateToProps, {loadAward}),
    firestoreConnect([{collection:'userawardchoice'}, { collection: 'statusaward' } ]),
)(MainVote2)
