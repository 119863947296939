import React, { Component } from "react";
import Slider from "react-slick";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { portfolioSlick2 } from "../../utils/script";
import ReactMarkdown from "react-markdown";

const TITRE = gql`
    query GetApropos {
        homepage {
            precedenteparticipantes,
        }
    }
`
const PARTICIPANTES = gql`
    query getParticipantes {
        participantes {
            Nom,
            profil {
                url
            }
        }
    }
`

const Portfolio = () => {
    const { loading, error, data } = useQuery(TITRE);
    const { loading:pLoading, error:pError, data:pData } = useQuery(PARTICIPANTES);
    if (loading || pLoading) return "";
    if (error || pError) return "";

    const portofolio = [];

    if (pData.participantes.length < 5 ) {
        while (portofolio.length < 6){
            portofolio.push(...pData.participantes)
        }
    }
    else {
        portofolio.push(...pData.participantes);
    }

    return (
        <React.Fragment>
            <div className="portfolio-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-title">
                                <ReactMarkdown children={data.homepage && data.homepage.precedenteparticipantes} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="portfolio-slick-activation mt--70 mt_sm--40">
                    <Slider {...portfolioSlick2}>
                        {portofolio.map((value, index) => (
                            <div className="portfolio" key={index}>
                                <div className="thumbnail-inner">
                                    <div className={`thumbnail`} style={{backgroundImage: `url('${value.profil[0]?.url}')`}}></div>
                                    <div className={`bg-blr-image ${value.image}`}></div>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <p>{value.category}</p>
                                        <h4 className="title"><Link to="/participantes/2020">{value.Nom}</Link></h4>
                                        <div className="portfolio-button">
                                            <Link className="rn-btn" to="/participantes/2020">See More</Link>
                                        </div>
                                    </div>
                                </div>
                                <Link className="link-overlay" to="/participantes/2020"></Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </React.Fragment>
    )

}
export default Portfolio;