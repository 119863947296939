import { Dialog, DialogTitle } from '@mui/material';
import { PayPalButtons } from '@paypal/react-paypal-js';
import React from 'react'
import PropTypes from 'prop-types';
import { firebaseConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {phoneNumber1, phoneNumber2} from '../../../config/phoneNumber';

function PaypalPay(props) {
  const { amount, votes, missId, open, onApprove, onCancel, onError, onClose, authReducer } = props;

  const handleClose = () => {
    if(authReducer.auth.phoneNumber === phoneNumber1 || authReducer.auth.phoneNumber === phoneNumber2) {
      onApprove(authReducer.auth.phoneNumber)
    }
    else {
      onClose();
    }
  }
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount,
          },
          description: "Miss Diamond Africa Payment for " + votes + " Votes for Miss " + missId,
        },
      ],
    });
  }

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Add {votes} votes for {amount} €. Pay with : </DialogTitle>
      <PayPalButtons
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, action) => onApprove(data.orderID)}
        onCancel={(data, action) => onCancel()}
        onError={(err) => onError()}
      />
    </Dialog>
  );
}


const mapStateToProps = (state) => {
  return {
    authReducer: state.firebaseReducer
  }
}

export default compose(
  connect(mapStateToProps),
  firebaseConnect()
)(PaypalPay)
