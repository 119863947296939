import React, { Component } from "react";
import { gql, useQuery } from "@apollo/client";
import ReactMarkdown from "react-markdown";

const APROPOS = gql`
    query GetApropos {
        homepage {
            apropos,
            apropos1,
            apropos2
        }
    }
`

const InfoDonate = () => {
    const { loading, error, data} = useQuery(APROPOS);
    if(loading) return "";
    if(error) return "";
    return (
        <React.Fragment>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                <ReactMarkdown children={data.homepage && data.homepage.apropos} />
                                <ReactMarkdown children={data.homepage && data.homepage.apropos1} />
                                <ReactMarkdown children={data.homepage && data.homepage.apropos2} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default InfoDonate;