import React from 'react'
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';
import { gql, useQuery } from "@apollo/client";

const CONTACT = gql`
    query GetApropos {
        homepage {
            email1,
            email2,
            tel1,
            tel2
        }
    }
`

function ContactInfo() {
    const { loading, error, data} = useQuery(CONTACT);

    return (
        <div className="rn-contact-top-area ptb--120 bg_color--5">
        <div className="container">
           
            <div className="row">
                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="rn-address">
                        <div className="icon">
                            <FiHeadphones />
                        </div>
                        <div className="inner">
                            <h4 className="title">Contact With Phone Number</h4>
                            <p><a href={`tel:${data?.homepage?.tel1}`}>{data?.homepage?.tel1}</a></p>
                            <p><a href={`tel:${data?.homepage?.tel2}`}>{data?.homepage?.tel2}</a></p>
                        </div>
                    </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                    <div className="rn-address">
                        <div className="icon">
                            <FiMail />
                        </div>
                        <div className="inner">
                            <h4 className="title">Email Address</h4>
                            <p><a href={`mailto:${data?.homepage?.email1}`}>{data?.homepage?.email1}</a></p>
                            <p><a href={`mailto:${data?.homepage?.email2}`}>{data?.homepage?.email2}</a></p>
                        </div>
                    </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                    <div className="rn-address">
                        <div className="icon">
                            <FiMapPin />
                        </div>
                        <div className="inner">
                            <h4 className="title">Location</h4>
                            <p>Germany</p>
                        </div>
                    </div>
                </div>
                {/* End Single Address  */}

            </div>
        </div>
    </div>
    )
}

export default ContactInfo;
