import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";
import { GiReceiveMoney } from "react-icons/gi";
import GTranslator from "../GTranslator";


const MDC = ({ year }) => (
    <li className="has-droupdown"><a href="#" >MDC {year}</a>
        <ul className="submenu">
            {year < 2024 && <li><Link to={`/gallery/${year}`}>Gallery Photo</Link></li>}
            {year < 2024 && <li><Link to={`/participantes/${year}`}>Participantes</Link></li>}
            {year < 2024 && <li><Link to={`/miss/${year}`}>Miss</Link></li>}
            <li><Link to={`/sponsors/${year}`}>Sponsors</Link></li>
            {year < 2024 && <li><Link to={`/jurys/${year}`}>Jurys</Link></li>}
        </ul>
    </li>
);

class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            // //console.log('All assets are loaded')
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    componentDidMount() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
    }

    render() {

        const { color = 'default-color' } = this.props;
        let logoUrl = <img src="/assets/images/logo/mdc.png" width="50" height="50" alt="Miss Diamond Africa Logo" />;

        return (
            <header className={`header-area formobile-menu header--transparent header--fixed ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl} <span className="logo-text">MDC</span>
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li className="has-droupdown"><Link to="/">Home</Link>
                                    <ul className="submenu">
                                        {/*<li><Link to="/inscription/candidate">Register TODAY !</Link></li>*/}
                                        <li><Link to="/inscription/collaboration">Become Sponsor/Partner</Link></li>
                                        <li><Link to="/vote" >Miss Vote</Link></li>
                                        <li><Link to="/awardvote" >Award Vote</Link></li>
                                        <li><Link to="/gala/reservation">Reservation Gala</Link></li>
                                        <li><Link to="/contact" >S'enregistrer Createur Mode</Link></li>
                                        {/* <li><Link to="/taptapsend" >Reduction TaptapSend</Link></li> */}
                                        <li><Link to="/team">Team</Link></li>
                                    </ul>
                                </li>
                                 <li className="has-droupdown"><Link to="#" >Vote</Link>
                                    <ul className="submenu">
                                        <li><Link to="/vote" >Miss Vote</Link></li>
                                        <li><Link to="/awardvote" >Award Vote</Link></li>
                                    </ul>
                                </li>
                                
                                {/* <li className="has-droupdown"><a href="#" >Edition</a>
                                    <ul className="submenu">
                                        <MDC year={2023} />
                                        <MDC year={2022} />
                                        <MDC year={2021} />
                                        <MDC year={2020} />
                                    </ul>
                                </li> */}
                                <li><Link to="/gala" >Gala</Link></li>
                                <li><Link to="/actualites" >News</Link></li>
                                <li className="has-droupdown"><Link to="#pages" >More</Link>
                                    <ul className="submenu">
                                    <li><Link to="/inscription/candidate">Register TODAY !</Link></li>
                                    <li><Link to="/inscription/collaboration">Become Sponsor/Partner</Link></li>
                                        {/* <li><Link to="/gala/reservation">Reservation Gala</Link></li>
                                        <li><Link to="/inscription/collaboration">Devenir Sponsor/ collaborateur / partenaire</Link></li>
                                        <li><Link to="/inscription/investor">Devenir Investisseur</Link></li>
                                        <li><Link to="/inscription/candidate">S'enregister candidate</Link></li>
                                        <li><Link to="/contact" >S'enregistrer Createur Mode</Link></li> */}
                                        {/* <li><Link to="/taptapsend" >Reduction TaptapSend</Link></li> */}
                                        <li><Link to="/donate">Make a Donation</Link></li>
                                        {/* <li><Link to="/brochure">Download Brochure</Link></li> */}
                                        <li><Link to="/contact" >Contact us</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="#"><GTranslator /></Link>
                                </li>
                                
                            </ul>
                        </nav>
                        <div className="header-btn">
                            <Link to="/donate" className="rn-btn">
                                <span>Make a Donation <GiReceiveMoney className="lead" /></span>
                            </Link>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;