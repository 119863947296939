export const couponList = [

    {
        id:"defaultmdc",
        couponName: "MDCTIKTAK",
        for: "gala@missdiamond-africa.com"
    },
    {
        id:"BELBY1",
        couponName: "BELBY1",
        for: "audreyvak@gmail.com"
    },
    {
        id:"MCPADRE",
        couponName: "MCPADRE",
        for: "marckamdoum@yahoo.fr"
    },
    {
        id:"QUEENFAB",
        couponName: "QUEENFAB",
        for: "fabiollekwanou@outlook.com"
    },
    {
        id:"MELMDA",
        couponName: "MELMDA",
        for: "ngongangmelisa@gmail.com"
    },
    {
        id:"DIAMOND",
        couponName: "DIAMOND",
        for: "contact@missdiamond-africa.com"
    },
    {
        id:"CANDIDATE",
        couponName: "CANDIDATE",
        for: "candidate@missdiamond-africa.com"
    },
    {
        id:"EDENA18",
        couponName: "EDENA18",
        for: "hmoguem@gmail.com"
    },
    {
        id:"MELARTMDA",
        couponName: "MELARTMDA",
        for: "melissatezanou@yahoo.de"
    },
    {
        id:"NAURAMDA",
        couponName: "NAURAMDA",
        for: "mbighanaura1@gmail.com"
    }
]
export const listCategoryReservation = [
    {
        id_:0,
        id: "diamond_hope",
        name:"Diamond Hope",
        Price: 40,
        maxPerson: 1,
        coupon: 40*0.2,
        buffet: 12, 
    },
    {
        id_:1,
        id: "diamond_hope_plus",
        name:"Diamond Hope Plus",
        Price: 55,
        maxPerson: 1,
        coupon: 55*0.2,
        buffet: 12,
    },
    {
        id_:2,
        id: "milenium_star",
        name:"Milenium Star (VIP)",
        Price: 1200,
        maxPerson: 10,
        coupon: 1200*0.2,
        buffet: 12*6,
    },
    {
        id_:3,
        id: "golden_jubilee",
        name:"Golden Jubilée (Platinum)",
        Price: 535,
        maxPerson: 5,
        coupon: 535*0.2,
        buffet: 12*4,
    },
    {
        id_:4,
        id: "blue_moon",
        name:"Blue Moon (platinum)",
        Price: 267,
        maxPerson: 2,
        coupon: 267*0.2,
        buffet: 12*2,
    },
    {
        id_:5,
        id: "girl_power",
        name:"Girl Power",
        Price: 335,
        maxPerson: 5,
        coupon: 335*0.2,
        buffet: 12*6,
    },
    {
        id_:6,
        id: "diamond_student",
        name:"Diamond Student",
        Price: 400,
        maxPerson: 5,
        coupon: 400*0.2,
        buffet: 12*6,
    },
    {
        id_:7,
        id: "miss_model_fan_club",
        name: "Miss/ Model Fan Club",
        Price: 800,
        maxPerson: 10,
        coupon: 800*0.2,
        buffet: 12*8,
    },
    {
        id_:8,
        id: "good_vibes",
        name: "Good Vibes",
        Price: 1075,
        maxPerson: 8,
        coupon: 1075*0.2,
        buffet: 12*8,
    },

]

export const Initialboissons = [
    {
        id: 1,
        titre: "Moët & Chandom (standard) -0,7L",
        prixUnitaire: 120,
        qty: 0,
    },
    {
        id: 2,
        titre: "Chivas 12 Ans (Standard) -0,7L",
        prixUnitaire: 100,
        qty: 0,
    },
    {
        id: 3,
        titre: "Jack Daniel (standard) -0,7L ",
        prixUnitaire: 100,
        qty: 0,
    },
/*    {
        id: 4,
        titre: "Inconscients (standard)  -0,7L",
        prixUnitaire: 80,
        qty: 0,
    },*/
    {
        id: 5,
        titre: "Baileys (standard) -0,7L",
        prixUnitaire: 50,
        qty: 0,
    },
    // {
    //     id: 6,
    //     titre: "Black label (standard) -0,7L",
    //     prixUnitaire: 100,
    //     qty: 0,
    // },
    // {
    //     id: 7,
    //     titre: "Gold Label -0,7L",
    //     prixUnitaire: 110,
    //     qty: 0,
    // },
    {
        id: 8,
        titre: "Chivas 18 ans (prestige) -0,7L",
        prixUnitaire: 140,
        qty: 0,
    },
    {
        id: 9,
        titre: "Ruinard Blanc (prestige) -0,75L",
        prixUnitaire: 140,
        qty: 0,
    },
    {
        id: 10,
        titre: "Belverdere/Ciroc (prestige) -0,7L",
        prixUnitaire: 120,
        qty: 0,
    },
    {
        id: 11,
        titre: "Dom Pérignon (Exclusive) -0,75L",
        prixUnitaire: 300,
        qty: 0,
    },
    {
        id: 11,
        titre: "Armand de Brignac (Exclusive) -0,75L",
        prixUnitaire: 500,
        qty: 0,
    },
    {
        id: 11,
        titre: "Louis roederer (Exclusive) -0,75L",
        prixUnitaire: 400,
        qty: 0,
    },
    {
        id: 0,
        titre: "Hennessy -0,7L",
        prixUnitaire: 120,
        qty: 0,
    },
    {
        id: 12,
        titre: "Sek - Vin – Asti -0,75L ",
        prixUnitaire: 30,
        qty: 0,
    },
    {
        id: 13,
        titre: "Beer -0,33L ",
        prixUnitaire: 5,
        qty: 0,
    },
    {
        id: 14,
        titre: "Redbull -0,25L ",
        prixUnitaire: 5,
        qty: 0,
    },
    {
        id: 15,
        titre: "Desperado -0,33L",
        prixUnitaire: 5,
        qty: 0,
    },
    {
        id: 16,
        titre: "Guinness -0,33L",
        prixUnitaire: 5,
        qty: 0,
    },
    {
        id: 17,
        titre: "Hugo -0,75L",
        prixUnitaire: 30,
        qty: 0,
    },
    {
        id: 18,
        titre: "Fanta - 0,33L",
        prixUnitaire: 5,
        qty: 0,
    },
    {
        id: 19,
        titre: "Sprite - 0,33L",
        prixUnitaire: 5,
        qty: 0,
    },
    {
        id: 20,
        titre: "Coca cola - 0,33L",
        prixUnitaire: 5,
        qty: 0,
    },
    {
        id: 21,
        titre: "Jus Naturel- 0,5L",
        prixUnitaire: 5,
        qty: 0,
    },
    {
        id: 22,
        titre: "Eau minérale 1L",
        prixUnitaire: 5,
        qty: 0,
    }
];

export const Initialcoktails = [
    {
        id: 0,
        titre: "PINA COLADA",
        prixUnitaire: 10,
        qty: 0,
        type: "classic"
    }, 
    {
        id: 1,
        titre: "MOJITO",
        prixUnitaire: 10,
        qty: 0,
        type: "classic",
    }, 
    {
        id: 2,
        titre: "TEQUILA SUNSET",
        prixUnitaire: 10,
        qty: 0,
        type: "classic"
    }, 
    {
        id: 3,
        titre: "BAILYS COLADA",
        prixUnitaire: 10,
        qty: 0,
        type: "classic"
    }, 
    {
        id: 4,
        titre: "COSMOPOLITAN",
        prixUnitaire: 10,
        qty: 0,
        type: "classic"
    }, 
    {
        id: 5,
        titre: "HUGO",
        prixUnitaire: 10,
        qty: 0,
        type: "classic"
    }, 
    {
        id: 6,
        titre: "VIRGIN PINA COLADA",
        prixUnitaire: 8,
        qty: 0,
        type: "sans alcool"
    }, 
    {
        id: 7,
        titre: "VIRGIN MOJITO",
        prixUnitaire: 8,
        qty: 0,
        type: "sans alcool"
    }, 
    {
        id: 8,
        titre: "EXOCTIC FRUIT",
        prixUnitaire: 8,
        qty: 0,
        type: "sans alcool"
    },
    {
        id: 9,
        titre: "EXOCTIC FRUIT",
        prixUnitaire: 8,
        qty: 0,
        type: "sans alcool"
    }, 
    {
        id: 10,
        titre: "JUS DE BISSAP (FOLÉRÉ)",
        prixUnitaire: 5,
        qty: 0,
        type: "sans alcool"
    }, 
    
]
