import axios from "axios";
import React, { Component, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';

const ContactThree = (props) => {

    const [rnName, setRnName] = useState("");
    const [rnEmail, setRnEmail] = useState("");
    const [rnSubject, setRnSubject] = useState("");
    const [rnMessage, setRnMessage] = useState("")

    const sendMessage = async () => {
        const link = "https://submit-form.com/sxfUms3t";

        if (rnEmail.trim() === "" || rnMessage.trim() === "" || rnName.trim() === "" ) {
            toast.error('Please all Fields are required 😕!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }
        toast.info('Sending Inscription!', {
            position: "top-right",
            autoClose: 1300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        const sentForm = await axios.post(link,
            {
                Categorie: "Contact",
                Nom: rnName,
                Email: rnEmail,
                subject: rnSubject,
                Message: rnMessage
            }
        );

        if(sentForm.status === 200) {
            toast.success(`Thank you ${rnName.split(" ")[0]} for your Message 😉 !`, {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            toast.success(`An error occured 😯 please try again or contact us. `, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }
    return (
        <div className="contact-form--1">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="section-title text-left mb--50">
                            <h2 className="title">{props.contactTitle}</h2>
                            <p className="description">Do you have any questions, wishes or requirements ? Contact us.</p>
                        </div>
                        <div className="form-wrapper">
                            <div>
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={rnName}
                                        onChange={(e) => { setRnName(e.target.value); }}
                                        placeholder="Your Name *"
                                    />
                                </label>

                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item02"
                                        value={rnEmail}
                                        onChange={(e) => { setRnEmail(e.target.value); }}
                                        placeholder="Your email *"
                                    />
                                </label>

                                <label htmlFor="item03">
                                    <input
                                        type="text"
                                        name="subject"
                                        id="item03"
                                        value={rnSubject}
                                        onChange={(e) => { setRnSubject(e.target.value); }}
                                        placeholder="Write a Subject"
                                    />
                                </label>
                                <label htmlFor="item04">
                                    <textarea
                                        type="text"
                                        id="item04"
                                        name="message"
                                        value={rnMessage}
                                        onChange={(e) => { setRnMessage(e.target.value); }}
                                        placeholder="Your Message"
                                    />
                                </label>
                                <button className="rn-button-style--2 btn-solid" onClick={() => sendMessage()} id="mc-embedded-subscribe">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail mb_md--30 mb_sm--30">
                            <img src={`${props.contactImages}`} alt="trydo" />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )

}
export default ContactThree;