import {Button, Select, MenuItem, InputLabel, FormControl} from '@mui/material';
import React, { Fragment, useState } from 'react';
import PaypalPay from './PaypalPay';

function PayChoice({ missId, addPaidChoice }) {
    const voteOptions = [
        { votes: 5, amount: 5 },
        { votes: 10, amount: 9 },
        { votes: 60, amount: 49 },
        { votes: 120, amount: 99 }
    ];

    const [open, setOpen] = useState(false);
    const [selectedVotes, setSelectedVotes] = useState(voteOptions[1].votes);
    const [selectedAmount, setSelectedAmount] = useState(voteOptions[1].amount);


    const handleSelectChange = (event) => {
        const selectedOption = voteOptions.find(option => option.votes === event.target.value);
        setSelectedVotes(selectedOption.votes);
        setSelectedAmount(selectedOption.amount);
    };

    const onOpen = () => {
        setOpen(true);
    };

    const onApprove = (orderId) => {
        addPaidChoice({ missId, orderId, votes: selectedVotes, amount: selectedAmount });
        setOpen(false);
    };

    const onError = () => {
        // Handle error if needed
    };

    const onCancel = () => {
        // Handle cancel if needed
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Select
                value={selectedVotes}
                onChange={handleSelectChange}
                variant="standard"
                displayEmpty
            >
                <MenuItem disabled value={0}>Select Vote Package</MenuItem>
                {voteOptions.map(option => (
                    <MenuItem key={option.votes} value={option.votes}>
                        {option.amount}€ add {option.votes} Votes
                    </MenuItem>
                ))}
            </Select>
            <Button
                variant="outlined"
                disabled={selectedVotes === 0} // disable button if no package is selected
                onClick={onOpen}
            >
                Pay {selectedAmount}€
            </Button>

            <PaypalPay
                open={open}
                amount={selectedAmount}
                votes={selectedVotes}
                missId={missId}
                onClose={onClose}
                onApprove={onApprove}
                onCancel={onCancel}
                onError={onError}
            />
        </Fragment>
    )
}

export default PayChoice;


// import { Button } from '@mui/material';
// import React, { Fragment, useState } from 'react'
// import { firebaseConnect } from 'react-redux-firebase';
// import { compose } from 'redux';
// import PaypalPay from './PaypalPay'
// import { connect } from 'react-redux';
//
// function PayChoice({missId, addPaidChoice}) {
//     const [open, setOpen] = useState(false);
//     const onOpen = () => {
//         setOpen(true);
//     }
//
//     const onApprove = (orderId) => {
//         addPaidChoice({ missId, orderId });
//         setOpen(false);
//     }
//
//     const onError = () => {
//         //setOpen(false);
//     }
//
//     const onCancel = () => {
//         //setOpen(false);
//     }
//
//     const onClose = () => {
//         setOpen(false);
//     }
//
//     return (
//         <Fragment>
//             {false ? '' :
//             <>
//             <Button variant="outlined" onClick={() => onOpen()}>
//                 5€ Add 5 Votes
//             </Button>
//             <PaypalPay
//                 open={open}
//                 onClose={() => onClose()}
//                 onApprove={(orderId) => onApprove(orderId)}
//                 onCancel={() => onCancel()}
//                 onError={() => onError()}
//                 />
//                 </>
//                 }
//
//         </Fragment>
//     )
// }
//
//
// export default  PayChoice
