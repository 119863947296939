import React from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../../components/common/Helmet";
import Breadcrumb from "../../components/common/Breadcrumb";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import BLogList from "../../components/blog/BlogList";
import { FaHome, FaPaypal } from "react-icons/fa";
import InfoDonate from "../../components/about/InfoDonate";
import LottieAnimation from "../../components/common/Lottie";
import LottieError from "../../components/common/lottieJson/LottieError.json";
import { Link } from "react-router-dom";

const DonateCancel = () => {
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Blog' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Blog Area */}
            <div className="rn-blog-area pt--125 bg_color--1">
                <div className="container  text-center">
                <p className="lead">Oups an error occured and the Donation could not be Completed 😕 ! <a href= "https://www.paypal.com/donate?hosted_button_id=QGTARQXYEPYWE">please try again</a></p>
                 <LottieAnimation lotti={LottieError} width={300} height={300}  />
                 <br/>
                 <p><a href= "https://www.paypal.com/donate?hosted_button_id=QGTARQXYEPYWE"><button className="btn btn-donate rn-btn" style={{padding:`0 7px`}}><span className="lead">Donate With PayPal <FaPaypal/></span></button></a></p>
                </div>
            </div>
            {/* End Blog Area */}

            
            {/* Start Blog Area */}
            <div className="rn-blog-area ptb--120 bg_color--1">
                <div className="container">
                    <InfoDonate/>
                </div>
            </div>
            {/* End Blog Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}
export default DonateCancel;
