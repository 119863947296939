const initState = {}

export const authReducer = (state = initState, action) => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            //console.log(action.payload);
            return state;
        case "LOGIN_ERROR":
            //console.log(action.payload);
            return state;
        default:
            return state;
    }
}