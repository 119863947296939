import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { useQuery, gql } from "@apollo/client";
import { local_api_url, remote_api_url } from "../../data/variables";

const REVIEWS = gql`
    query GetReviews {
        reviews {
            name,
            status,
            comment,
            id,
            img {
                url
            }
        }
    }
`
const Testimonial = () => {
    const { loading, error, data } = useQuery(REVIEWS);
    if (loading) return <p>loading...</p>;
    if (error) return <p>Error</p>;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <Tabs>
                        {data.reviews.map(review => (
                            <TabPanel key={review.id}>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>{review.comment}</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>{review.name} </span> - {review.status}</h6>
                                    </div>
                                </div>
                            </TabPanel>
                        ))}

                        <TabList className="testimonial-thumb-wrapper">
                            {data.reviews.map(review => (
                                <Tab key={review.id}>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">

                                            <img src={review?.img?.url} alt="Testimonial Images" />
                                        </div>
                                    </div>
                                </Tab>
                            ))}

                        </TabList>
                    </Tabs>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Testimonial;