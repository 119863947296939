import React, { useState } from 'react'
import { FiCheck, FiChevronUp } from "react-icons/fi";
import ModalVideo from 'react-modal-video';
import { BsBoxArrowInUpRight } from 'react-icons/bs'

function About({data}) {
    const [isOpen, setIsOpen] = useState(false)
    var namesItemOne = [
        {id: "Date", res: new Date(data?.Jour).toLocaleDateString()},
        {id:"Place", res:data?.Lieu},
        {id:"Adress", res:data?.Adresse},
        {id:"Start", res:(data?.debut || "").substring(0,5)}
    ];
  return (
    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{data?.titel || "Gala Night"}:</h2>
                                        <p className="description">{data?.Description}</p>
                                    </div>
                                    <div className="mt--30">
                                        <h4>Informations:</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> <b>{name.id} :</b> {name.res}</li>;
                                            })}
                                        </ul>
                                        <div className="about-button mt--30">
                                        <a className="rn-button-style--2 btn-solid" href="/gala/reservation">Reservation Ticket</a>
                                    </div>
                                    {/*<div className="about-button mt--20">
                                        <a className="rn-button-style--2 btn-solid" href="/taptapsend">Reduction TaptapSend</a>
                                    </div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src={data?.PhotoCouverture?.url || ""} alt="About Images"/>
                                    <ModalVideo channel='youtube' isOpen={isOpen} videoId='ZOoVOfieAF8' onClose={() => setIsOpen(false)} />
                                    {/* <button className="video-popup position-top-center theme-color" onClick={() => setIsOpen(true)}><span className="play-icon"></span></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
  )
}

export default About