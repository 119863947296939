export const loadAward = () => {
    return async (dispatch, getstate, { getFirebase, getFirestore }) => {
        //console.log(getFirebase())

        //httpcallable
        const response = await fetch('https://admin-mdc-home-fnneu.ondigitalocean.app/awardcandidats');
        const awardList = await response.json();

        // order award by their missId
        const award = awardList.sort((a, b) => { return a.missId - b.missId })

        //console.log(award)

        dispatch({
            type: 'GET_AWARD',
            payload: award
        })
    }
}