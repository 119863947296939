import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyC8HGtbrwXUDRaZW1-R8tBtGA8YCVE6uks",
    authDomain: "mdc-soon.firebaseapp.com",
    projectId: "mdc-soon",
    storageBucket: "mdc-soon.appspot.com",
    messagingSenderId: "449686717487",
    appId: "1:449686717487:web:39a97b1f22fc2895c4b2da",
    measurementId: "G-B5L8LFXKXN"
  };

const firebaseConfigBeta = {
  apiKey: "AIzaSyAnPnbf6XTPRs3rwEPN4dlepbnmr9uCEJA",
  authDomain: "missdiamondcameroon.com",
  databaseURL: "https://admintext-ad72c.firebaseio.com",
  projectId: "admintext-ad72c",
  storageBucket: "admintext-ad72c.appspot.com",
  messagingSenderId: "432295015402",
  appId: "1:432295015402:web:f1aabce6dea4366c333893",
  measurementId: "G-HD8ZQ1JLZW"
}

  firebase.initializeApp(firebaseConfigBeta);
  firebase.firestore().settings({timestampsInSnapshots: true})
  
  var auth = firebase.auth()
  var db = firebase.firestore();
  var func = firebase.functions();
  var analytics = firebase.analytics();


 if (window.location.hostname === "localhost") {
    auth.useEmulator("http://localhost:9099")
    db.useEmulator("localhost", 8082);
    func.useEmulator("localhost", 5001)  
  } 

 /* if ( window.location.hostname === "192.168.137.1") {
    auth.useEmulator("http://192.168.137.1:9099")
    db.useEmulator("192.168.137.1", 8080);
    func.useEmulator("192.168.137.1", 5001)  
  } */

  export default firebase