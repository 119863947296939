import React from 'react'
import PageHelmet from '../../components/common/Helmet';
import Breadcrumb from '../../components/common/Breadcrumb';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import ContactInfo from '../../components/contact/ContactInfo';
import Gala from '../../components/contact/Gala';

const GalaForm = () => {
    return (
        <>
            <PageHelmet pageTitle='MDC Gala Reservation' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'MDC GALA Reservation of tickets and tables'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Contact Form  */}
                <div className="rn-contact-form-area ptb--120 bg_color--1 p-5">
                     <Gala contactTitle="MDC Fashion and Glamour Gala Night" contactImages="/assets/images/logo/250.jpeg" />
                    {/*<h2>Les Réservastion pour le Gala commencent bientôt: Date du Gala: 28.10.2023 Borsigstraße 2 , 41541 Dormagen Germany</h2>
                    <h2>Online Réservation are starting soon: Gala Date: 28.10.2023 Borsigstraße 2 , 41541 Dormagen Germany</h2>*/}
                </div>
                {/* Start Contact Form  */}

                <ContactInfo />

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default GalaForm;