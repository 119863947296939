import React, { Component } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";

const SPONSOR = gql`
    query getSponsors($annee:String) {
        sponsors(where:{annees:{annee:$annee}}) {
            id,
            name,
            logo {
                url
            }
        }
    }
`


const BrandTwo = () => {
    const {year} = useParams();
    const { loading, error, data } = useQuery(SPONSOR, {
        variables: {annee: year || "2021"}
    });
    if (loading) return "";
    if (error) return "";
    return (
        <React.Fragment>
            <div className="rn-brand-area  bg_color--5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="brand-style-2">
                                {data.sponsors.map((value, key) => (
                                    <li key={key}>
                                        <Link to={`/sponsor/${value.id}`}> <img src={value.logo[0].url} alt={`Sponsor Miss Diamond Africa - ${value.name} `} /> </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default BrandTwo;