import React from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../../components/common/Helmet";
import Breadcrumb from "../../components/common/Breadcrumb";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import BLogList from "../../components/blog/BlogList";
import { FaPaypal } from "react-icons/fa";
import InfoDonate from "../../components/about/InfoDonate";
import LottieAnimation from "../../components/common/Lottie";
import LottieDonate from "../../components/common/lottieJson/LottieDonate.json";
import { Link } from "react-router-dom";

const Donate = () => {
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Donate' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* Start Breadcrump Area */}
            <div className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1" data-black-overlay="6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner pt--100">
                                <h3 className="title">thanks in advance! Merci D'avance! Danke im voraus!</h3>
                                <p><a href= "https://www.paypal.com/donate?hosted_button_id=QGTARQXYEPYWE"><button className="btn btn-donate rn-btn" style={{padding:`0 7px`}}><span className="lead">Donate With PayPal <FaPaypal/></span></button></a></p>
                                
                                <ul className="page-list">
                                    <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                    <li className="breadcrumb-item active">Donation</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}


            {/* Start Blog Area */}
            <div className="rn-blog-area pt--50 bg_color--1">
                <div className="container  text-center">
                <p className="lead"> Help us to promote and maintain the competition 😊! </p>
                <a href= "https://www.paypal.com/donate?hosted_button_id=QGTARQXYEPYWE"><LottieAnimation lotti={LottieDonate} width={300} height={300}  /></a>
                 <br/>
                 <p><a href= "https://www.paypal.com/donate?hosted_button_id=QGTARQXYEPYWE"><button className="btn btn-donate rn-btn" style={{padding:`0 7px`}}><span className="lead">Donate With PayPal <FaPaypal/></span></button></a></p>
                </div>
            </div>
            {/* End Blog Area */}

            
            {/* Start Blog Area */}
            <div className="rn-blog-area ptb--120 bg_color--1">
                <div className="container">
                    <InfoDonate/>
                </div>
            </div>
            {/* End Blog Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}
export default Donate;
