import React from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../../components/common/Helmet";
import Breadcrumb from "../../components/common/Breadcrumb";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import BLogList from "../../components/blog/BlogList";

const Actualites = () => {
    return (
        <React.Fragment>
            <PageHelmet pageTitle='News' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'News'} />
            {/* End Breadcrump Area */}


            {/* Start Blog Area */}
            <div className="rn-blog-area ptb--120 bg_color--1">
                <div className="container">
                    <BLogList />
                    <div className="row mt--20">
                        <div className="col-lg-12">
                            {/* Start Pagination Area */}
                    
                            {/* End Pagination Area */}
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}
export default Actualites;
