import React from 'react';
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import {red} from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Link} from "@mui/material";
import {FaInstagram} from "react-icons/fa";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";

function CardWrapper(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Card sx={{ maxWidth: 400 }}>
            <CardHeader
                avatar={
                    <Avatar src={props.img} sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {props.name}
                    </Avatar>
                }
                action={
                    <div>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls="long-menu"
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem onClick={handleClose}>
                                <Link target="_blank" href={props?.instagramname}><FaInstagram /> Instagram Post</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link target="_blank" href={props?.facebooklink}><FaInstagram /> Facebook Post</Link>
                            </MenuItem>
                        </Menu>
                    </div>
                }
                title={<b>{props.name}</b>}
                subheader={"Miss " + props.id}
            />
            <CardMedia
                component="img"
                height="350"
                image={ props.img}
                alt="Miss Diamond Africa"
            />

            {props.children}

        </Card>
    );
}

export default CardWrapper;