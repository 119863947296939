// React Required
import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
// Create Import File
import './index.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Home from './pages/home/HomePortal';
import PageScrollTop from './utils/PageScrollTop';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { local_api_url_gql, passE, remote_api_url_gql } from './data/variables';
import { AiOutlineCheckCircle, AiOutlineCloseCircle, } from "react-icons/ai";
import Actualites from './pages/actualite/Actualite';
import ActualiteDetail from './pages/actualite/ActualiteDetail';
import Participantes from './pages/participantes/Participantes';
import MissPortfolio from './pages/participantes/MissPortfolio';
import Brand from './pages/participantes/Brand';
import Jurys from './pages/participantes/Jurys';
import ContactForm from './pages/form/ContactForm';
import RegistrationMiss from './pages/form/RegistrationMiss';
import RegistrationSponsor from './pages/form/RegistrationSponsor';
import RegistrationInvestor from './pages/form/RegistrationInvestor';
import Team from './pages/participantes/Team';
import Donate from './pages/home/Donate';
import DonateComplete from './pages/home/DonateComplete';
import DonateCancel from './pages/home/DonateCancel';
import BrandDetails from './pages/participantes/BrandDetails';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';
import Vote from './pages/vote';
import thunk from 'redux-thunk';
import { reduxFirestore, getFirestore } from 'redux-firestore'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import fbConfig from './config/fbConfig'
import Privacy from './pages/home/Privacy';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import initiaPaypalOptions from './config/payProvider';
import VoteAward from './pages/votesAward';

import withClearCache from "./ClearCache";
import Gallery from './pages/home/Gallery';
import Ebook from './pages/home/Ebook';
import GalaForm from './pages/form/GalaForm';
import Business from './pages/home/Gala';
import Taptapsend from './pages/home/Taptapsend';
import SmartBanner from 'react-smartbanner';
import '../node_modules/react-smartbanner/dist/main.css';
import VoteMiss from "./pages/vote/miss";

const client = new ApolloClient({
    uri: remote_api_url_gql,
    cache: new InMemoryCache()
});


const Root = () => {
    const [key, setKey] = useState("");
    const [enter, setEnter] = useState(true)
    if (!enter) {
        return (
            <div className="container">
                <div className="col-sm-4 col-md-3 mt-4">
                    <img src="/assets/images/logo/mdc.png" width="80" height="80" alt="Miss Diamond Africa Logo" />
                    <div className="form-group mt-2">
                        <label >Access Key : {key} {key == "" ? "" : key == passE ? <AiOutlineCheckCircle className="text-success" /> : <AiOutlineCloseCircle className="text-danger" />}</label>
                        <input type="text" value={key} onChange={(event) => setKey(event.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter key" />
                        <small id="emailHelp" className="form-text text-muted">Contact the team to get the Key</small>
                    </div>
                    <button className="btn btn-primary" onClick={() => setEnter(key == passE)} >Access</button>
                </div>
            </div>
        );
    }
    return (
        <BrowserRouter basename={'/'}>
            <ApolloProvider client={client}>
                <PayPalScriptProvider options={initiaPaypalOptions} >
                    <PageScrollTop>
                        <SmartBanner title={'Miss Diamond Africa'} daysHidden={1} daysReminder={1}  />
                        <Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/taptapsend`} component={Taptapsend} /> */}
                            <Route exact path={`${process.env.PUBLIC_URL}/gala`} component={Business} />
                            <Route exact path={`${process.env.PUBLIC_URL}/gala/reservation`} component={GalaForm} />
                            <Route exact path={`${process.env.PUBLIC_URL}/brochure`} component={Ebook} />
                            <Route exact path={`${process.env.PUBLIC_URL}/actualites`} component={Actualites} />
                            <Route exact path={`${process.env.PUBLIC_URL}/actualites/:id`} component={ActualiteDetail} />
                            <Route exact path={`${process.env.PUBLIC_URL}/participantes/:year`} component={Participantes} />
                            <Route exact path={`${process.env.PUBLIC_URL}/miss/:year`} component={MissPortfolio} />
                            <Route exact path={`${process.env.PUBLIC_URL}/sponsors/:year`} component={Brand} />
                            <Route exact path={`${process.env.PUBLIC_URL}/sponsor/:id`} component={BrandDetails} />
                            <Route exact path={`${process.env.PUBLIC_URL}/jurys/:year`} component={Jurys} />
                            <Route exact path={`${process.env.PUBLIC_URL}/gallery/:year`} component={Gallery} />
                            <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={ContactForm} />
                            <Route exact path={`${process.env.PUBLIC_URL}/inscription/candidate`} component={RegistrationMiss} />
                            <Route exact path={`${process.env.PUBLIC_URL}/inscription/collaboration`} component={RegistrationSponsor} />
                            <Route exact path={`${process.env.PUBLIC_URL}/inscription/investor`} component={RegistrationInvestor} />
                            <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team} />
                            <Route exact path={`${process.env.PUBLIC_URL}/donate`} component={Donate} />
                            <Route exact path={`${process.env.PUBLIC_URL}/donate/cancel`} component={DonateCancel} />
                            <Route exact path={`${process.env.PUBLIC_URL}/donate/completed`} component={DonateComplete} />
                            <Route exact path={`${process.env.PUBLIC_URL}/vote`} component={Vote} />
                            <Route exact path={`${process.env.PUBLIC_URL}/vote/miss`} component={Vote} />
                            <Route exact path={`${process.env.PUBLIC_URL}/vote/miss/:id`} component={VoteMiss} />
                            <Route exact path={`${process.env.PUBLIC_URL}/awardvote`} component={VoteAward} />
                            <Route exact path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy} />
                            <Route component={Home} />
                        </Switch>
                    </PageScrollTop>
                </PayPalScriptProvider>
            </ApolloProvider>
        </BrowserRouter>
    )
}

const ClearCacheComponent = withClearCache(Root);

function App() {
  return <ClearCacheComponent />;
}


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const storeEnhancer = composeEnhancers(
    applyMiddleware(thunk.withExtraArgument({
        getFirebase,
        getFirestore
    })),
    reduxFirestore(fbConfig),
    reactReduxFirebase(fbConfig)
)
const store = createStore(reducers, storeEnhancer);


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));
serviceWorker.register();

