import { firebaseReducer } from "react-redux-firebase";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { authReducer } from "./authReducer";
import { missReducer } from "./missReducer";
import { voteReducer } from "./voteReducer";
import { awardReducer, categorieAwardReducer } from "./awardReducers";

const reducers =  combineReducers({
    missReducer,
    voteReducer,
    authReducer,
    awardReducer,
    categorieAwardReducer,
    firestoreReducer,
    firebaseReducer
});
export default reducers;