import React, { useState } from 'react'
import { FiCheck, FiChevronUp } from "react-icons/fi";
import ModalVideo from 'react-modal-video';
import { BsBoxArrowInUpRight } from 'react-icons/bs'

function Fashion({data}) {
    const [isOpen, setIsOpen] = useState(false)
    var namesItemFashion = (data?.Objectifs || []).map((obj) => obj.titre);

  return (
    <div className="container">
                        <div className="row row--35 align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src={data?.photofashion?.url || ""} alt="About Images"/>
                                    <ModalVideo channel='youtube' isOpen={isOpen} videoId='ZOoVOfieAF8' onClose={() => setIsOpen(false)} />
                                    {/* <button className="video-popup position-top-center theme-color" onClick={() => setIsOpen(true)}><span className="play-icon"></span></button> */}
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{ data?.Titelfashion || "MDC Fashion"}:</h2>
                                        <p className="description">{data?.DescriptionFashion}</p>
                                    </div>
                                    <div className="mt--30">
                                        <h4>Purposes:</h4>
                                        <ul className="list-style--1">
                                            {namesItemFashion.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                        <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/contact">Register as a Partner</a>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
  )
}

export default Fashion