import React from 'react'
import PageHelmet from '../../components/common/Helmet';
import Breadcrumb from '../../components/common/Breadcrumb';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import BrandOne from '../../components/brand/BrandOne';
import BrandTwo from '../../components/brand/BrandTwo';
import { Link } from 'react-router-dom';


const Brand = () => {
    return (
        <>
            <PageHelmet pageTitle='Sponsors' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Nos Sponsors Edition 2020'} />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Brand Area  */}
                <div className="rn-brand-area brand-separation bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                                <Link className="btn-transparent rn-btn-dark" to="/inscription/sponsor"><span className="text">Devenir sponsor</span></Link> <br/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>

    )
}

export default Brand;